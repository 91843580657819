export class TokenDetails {
  token = '';
  paymentType: 'CARD' | 'echeck' = 'CARD';
  debitLast4 = '';
  expirationDate = '';
  cardType = '';
  cardBrand = '';
  accountType = '';
  routingNumber = '';
}
