import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { MFAService } from '@patient-ui/patient-web/store';
import {
  FactorsState,
  MFAActions,
  getMFASelectionStatus,
  mfaTechDifficultiesStatus,
  selectMFALoading,
} from '@patient-ui/patient-web/store';
import { EventCode } from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { EnrollMfaComponent } from '../../portal-account/containers/portal-account-profile/enroll-mfa/enroll-mfa.component';

@Component({
  selector: 'patient-ui-mfa-setup-confirmation',
  templateUrl: './mfa-setup-confirmation.component.html',
  styleUrls: ['./mfa-setup-confirmation.component.scss'],
})
export class MfaSetupConfirmationComponent implements OnInit, OnDestroy {
  showConfirmationScren = false;
  helpCenterUrl = '';
  mfaStatus$ = this.mfaStore.select(getMFASelectionStatus);
  mfaLoading$ = this.mfaStore.select(selectMFALoading);
  isLoading = false;
  mfaTechDifficulties$: Observable<boolean> = this.mfaStore.select(
    mfaTechDifficultiesStatus
  );
  mfaTechDifficultiesStatus = false;
  destroyed = new Subject();

  constructor(
    private mfaService: MFAService,
    private router: Router,
    public activeModal: NgbActiveModal,
    private mfaStore: Store<FactorsState>,
    private modalService: NgbModal,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.helpCenterUrl = `${this.envSvc.patientUrl}/help-center/`;

    this.mfaStatus$.pipe(takeUntil(this.destroyed)).subscribe((data) => {
      if (data === false) {
        this.activeModal.dismiss('cancel');
      }
    });

    this.mfaTechDifficulties$.subscribe(
      (value) => (this.mfaTechDifficultiesStatus = value)
    );

    combineLatest([this.mfaLoading$])
      .pipe(
        tap(([response]) => {
          this.isLoading = response;
        })
      )
      .subscribe();
  }

  confirmScreen() {
    this.mfaService.logMFAMetric(
      'Do it later initial selection in MFA setup confirmation page.',
      EventCode.mfaLater
    );
    this.showConfirmationScren = true;
  }

  redirectToProfile() {
    this.mfaService.logMFAMetric(
      'Turn on MFA in MFA setup confirmation page.',
      EventCode.mfaTurnOn
    );
    this.activeModal.dismiss('cancel');
    this.modalService.open(EnrollMfaComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    this.router.navigate(['/portal/account/profile']);
  }

  declineMFA() {
    this.mfaStore.dispatch(MFAActions.setMFAPreference({ preference: false }));
    this.mfaService.logMFAMetric(
      'Decline MFA in MFA setup confirmation page.',
      EventCode.mfaDecline
    );
  }

  ngOnDestroy(): void {
    sessionStorage.setItem('MFAModalDismissed', 'true');
    this.destroyed.next();
    this.destroyed.complete();
  }
}
