import { Component, Input, OnInit } from '@angular/core';

import { MFAService } from '@patient-ui/patient-web/store';
import { EventCode } from '@patient-ui/shared/models';

import { OktaModalService } from '../../okta-modal.service';

@Component({
  selector: 'patient-ui-confirmation-screen',
  templateUrl: './confirmation-screen.component.html',
  styleUrls: ['./confirmation-screen.component.scss'],
})
export class ConfirmationScreenComponent implements OnInit {
  @Input()
  factorType!: string;

  constructor(
    private oktaModalService: OktaModalService,
    private mfaService: MFAService
  ) {}

  ngOnInit(): void {
    console.log('Confirmation Screen');
  }

  cancel() {
    this.mfaService.logMFAMetric(
      ' Successfully Enrolled MFA in Confirmation page.',
      EventCode.mfaDoneModal
    );
    this.oktaModalService.close();
  }
}
