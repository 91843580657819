import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  IActivateAccount,
  IActivateAccountVerificationResponse,
  IDupicateRegistrationResponse,
  IDuplicateRegistrationData,
  IRegistrationFormData,
  IToken,
} from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class AccountRegistrationService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  registerPatient(
    registrationFormData: IRegistrationFormData
  ): Observable<IActivateAccount> {
    const postUrl = encodeURI(
      `${this.envService.baseUrl}/guest/patients/register/lite/v2`
    );
    const headers = registrationFormData.onDemandRegistration
      ? {
          headers: {
            'Content-Type': 'application/json',
            SOURCE: 'ondemand',
          },
          withCredentials: true,
        }
      : {};
    delete registrationFormData.onDemandRegistration;
    return this.http.post<IActivateAccount>(
      postUrl,
      registrationFormData,
      headers
    );
  }

  duplicateRegistration(
    duplicateRegistration: IDuplicateRegistrationData
  ): Observable<IDupicateRegistrationResponse> {
    const postUrl = encodeURI(
      `${this.envService.baseUrl}/guest/patients/dupRegister`
    );

    return this.http.post<IDupicateRegistrationResponse>(
      postUrl,
      duplicateRegistration
    );
  }

  patientVerifyEmail(
    code: string,
    token: string,
    campaign?: string
  ): Observable<void> {
    const patientActivation = {
      code: code,
      token: token,
      campaign: campaign,
    };
    const postUrl = encodeURI(
      `${this.envService.baseUrl}/guest/patients/register/verifyemail`
    );
    return this.http.post<void>(postUrl, patientActivation);
  }

  currentPatientVerifyEmail(
    verificationCode: string,
    campaign?: string
  ): Observable<void> {
    const patientActivation = {
      verificationCode: verificationCode,
      campaign: campaign,
    };

    const postUrl = encodeURI(
      `${this.envService.baseUrl}/protected/patients/current/verifyemail`
    );
    return this.http.post<void>(postUrl, patientActivation);
  }

  accountResendCode(token: IToken): Observable<IActivateAccount> {
    const postUrl = encodeURI(
      `${this.envService.baseUrl}/guest/accounts/resendCode`
    );
    return this.http.post<IActivateAccount>(postUrl, token);
  }

  currentPatientResendCode(): Observable<void> {
    const postUrl = encodeURI(
      `${this.envService.baseUrl}/protected/patients/current/resendverifyemail`
    );
    return this.http.post<void>(postUrl, null);
  }

  activateAccount(
    code: string,
    acttoken: string
  ): Observable<IActivateAccountVerificationResponse> {
    const patientActivation = {
      code: code,
      token: acttoken,
    };
    const postUrl = encodeURI(
      `${this.envService.baseUrl}/guest/patients/activateAccount`
    );
    return this.http.post<IActivateAccountVerificationResponse>(
      postUrl,
      patientActivation
    );
  }
}
