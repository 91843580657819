import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthState, PendoActions } from '@patient-ui/patient-web/store';
import * as authActions from '@patient-ui/patient-web/store';
import {
  FORM_ERROR_MESSAGES,
  regexPatterns,
} from '@patient-ui/shared/constants';
import { RequestStatus } from '@patient-ui/shared/enums';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'patient-ui-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnDestroy, OnInit {
  readonly validationMessages = {
    emailAddress: {
      required: FORM_ERROR_MESSAGES.common.required,
      pattern: `Must be a valid email with at least 7 characters.`,
      incorrectLogin: `Sorry, incorrect email or password.`,
    },
    emailPassword: {
      required: FORM_ERROR_MESSAGES.common.required,
    },
  };

  loginFailed = false;

  patientUrl = '';
  patientRegistrationUrl = '';
  patientResetPasswordUrl = '';

  destroyed = new Subject();

  proccessing$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  showAlert = false;
  signInForm!: FormGroup;
  readonly returnUrl: string;

  constructor(
    private envService: EnvironmentService,
    private authStore: Store<AuthState>,
    private builder: FormBuilder,
    private _route: ActivatedRoute
  ) {
    this.patientUrl = this.envService.patientUrl;
    this.returnUrl =
      this._route.snapshot.queryParams['returnUrl'] || '/portal/dashboard';
  }

  ngOnInit(): void {
    this.initForm();
    this.patientRegistrationUrl = `${this.patientUrl}/registration`;
    this.patientResetPasswordUrl = `${this.patientUrl}/account/password/reset`;
    this.authStore.dispatch(PendoActions.pendoSetUnknownUser());
    this.authStore
      .select(authActions.selectAuthState)
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (state) => {
          switch (state.authenticateRequestStatus) {
            case RequestStatus.Success: {
              this.proccessing$.next(true);
              this.loginFailed = false;
              this.showAlert = false;
              break;
            }
            case RequestStatus.Failure:
              this.proccessing$.next(false);
              this.loginFailed = true;
              this.showAlert = true;
              this.signInForm.controls.emailAddress.setErrors({
                incorrectLogin: true,
              });
              break;
            case RequestStatus.Pending:
              this.proccessing$.next(true);
              this.loginFailed = false;
              this.showAlert = false;
              break;
            case RequestStatus.NotSent:
              this.loginFailed = false;
              this.showAlert = false;
              break;
            default:
              this.proccessing$.next(false);
              this.loginFailed = false;
              this.showAlert = false;
              break;
          }
        },
        () => {
          this.proccessing$.next(false);
          this.loginFailed = true;
          this.showAlert = true;
        }
      );
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  initForm() {
    this.signInForm = this.builder.group({
      emailAddress: [
        '',
        [Validators.required, Validators.pattern(regexPatterns.email)],
      ],
      emailPassword: ['', [Validators.required]],
    });
  }

  onSubmit(): void {
    this.signInForm.controls.emailAddress.markAsDirty();
    this.signInForm.controls.emailPassword.markAsDirty();
    this.loginFailed = false;

    if (
      this.signInForm.controls.emailAddress.value === '' ||
      this.signInForm.controls.emailPassword.value === ''
    ) {
      this.proccessing$.next(false);
      this.showAlert = true;
    } else if (this.signInForm.valid) {
      this.proccessing$.next(true);
      const emailControl = this.signInForm.controls['emailAddress'];
      const emailPasswordControl = this.signInForm.controls['emailPassword'];
      this.authStore.dispatch(
        authActions.authenticate({
          email: emailControl.value,
          password: emailPasswordControl.value,
        })
      );
    } else {
      this.proccessing$.next(false);
      if (this.signInForm.controls.emailAddress.status === 'VALID') {
        this.signInForm.controls.emailAddress.setErrors({
          incorrectLogin: true,
        });
        this.loginFailed = true;
      }
      this.showAlert = true;
    }
  }
}
