import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ui-new-modal',
  templateUrl: './new-modal.component.html',
  styleUrls: ['./new-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewModalComponent {
  @Input() header: 'simple' | 'custom' = 'simple';
  @Input() headerText = 'This is the Heading';
  @Input() closeIcon = true;
  @Input() footer: 'none' | 'simple' | 'custom' = 'simple';
  @Input() footerDenyText = 'Cancel';
  @Input() footerAffirmativeText = 'Submit';
  @Input() footerDenyFlag: boolean | string = false;
  @Input() footerAffirmativeFlag: boolean | string = false;
  @Input() footerAffirmativeInProgressSpinner = false;
  @Input() justifyFooterContent: 'between' | 'end' = 'end';
  @Output() modalAffirmativeButtonClicked = new EventEmitter<void>();

  constructor(public activeModal: NgbActiveModal) {}

  onAffirmativeButtonClick() {
    this.modalAffirmativeButtonClicked.emit();
  }
}
