import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';

import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export type AuthState = {
  loggedIn: boolean;
  sessionToken: string;
  user: {
    id: string | null;
    login: string | null;
    firstName: string | null;
    lastName: string | null;
  };
  authenticateRequestStatus: RequestStatus;
};

export const AuthInitialState: AuthState = {
  loggedIn: false,
  sessionToken: '',
  user: {
    id: null,
    login: null,
    firstName: null,
    lastName: null,
  },
  authenticateRequestStatus: RequestStatus.NotSent,
};

export const authReducer = createReducer(
  AuthInitialState,
  on(AuthActions.authenticate, (state) => ({
    ...state,
    authenticateRequestStatus: RequestStatus.Pending,
  })),
  on(AuthActions.authenticateSuccess, (state, { response }) => ({
    ...state,
    loggedIn: true,
    user: {
      id: response?.sub || null,
      login: response?.email || null,
      firstName: response?.given_name || null,
      lastName: response?.family_name || null,
    },
    authenticateRequestStatus: RequestStatus.Success,
  })),

  on(AuthActions.authenticateFailure, (state) => ({
    ...state,
    loggedIn: false,
    sessionToken: '',
    id: null,
    login: null,
    firstName: null,
    lastName: null,
    authenticateRequestStatus: RequestStatus.Failure,
  })),

  on(AuthActions.logOut, (state) => ({
    ...state,
    loggedIn: false,
    sessionToken: '',
    user: {
      id: null,
      login: null,
      firstName: null,
      lastName: null,
    },
    authenticateRequestStatus: RequestStatus.NotSent,
  })),
  on(AuthActions.updateLoginInfoSuccess, (state) => ({
    ...state,
    loggedIn: true,
    authenticateRequestStatus: RequestStatus.Success,
  })),
  on(AuthActions.updateLoginInfoFailure, (state, action) => ({
    ...state,
    loggedIn: !!action.payload,
    user: {
      ...state.user,
      login:
        action.payload?.emailAddresses.find((email) => !!email.isPrimary)
          ?.address || null,
      firstName: action.payload?.firstName || null,
      lastName: action.payload?.lastName || null,
    },
    authenticateRequestStatus: action.payload
      ? RequestStatus.Success
      : RequestStatus.Failure,
  })),
  on(AuthActions.saveSession, (state, { token, userId }) => ({
    ...state,
    sessionToken: token,
    user: {
      ...state.user,
      id: userId,
    },
  }))
);
