import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  IMicrobiomeKitSearch,
  MicrobiomeSurvey,
  MicrobiomeSurveyResponse,
} from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class MicrobiomeService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getMicrobiomeSurvey(
    microbiomeKitSearch: IMicrobiomeKitSearch
  ): Observable<MicrobiomeSurvey> {
    let microbiomeSurveySearchUrl: string;

    if (microbiomeKitSearch.acctno) {
      microbiomeSurveySearchUrl = `${this.envService.baseUrl}/guest/lpk/order/questionnaire/acc/${microbiomeKitSearch.acctno}/${microbiomeKitSearch.reqno}/${microbiomeKitSearch.dateOfBirth}`;
    } else {
      microbiomeSurveySearchUrl = `${this.envService.baseUrl}/guest/lpk/order/questionnaire/id/${microbiomeKitSearch.labcorpid}/${microbiomeKitSearch.dateOfBirth}`;
    }

    return this.http.get<MicrobiomeSurvey>(microbiomeSurveySearchUrl);
  }

  postMicrobiomeSurveyResponse(
    surveyResponse: MicrobiomeSurveyResponse
  ): Observable<unknown> {
    return this.http.post<unknown>(
      `${this.envService.baseUrl}/guest/lpk/order/questionnaire`,
      JSON.stringify(surveyResponse).replace(/[^\x20-\x7E]/g, ''),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
