import { Pipe, PipeTransform } from '@angular/core';

interface ObjWithNameDemographics {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
}

@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {
  constructor() {}

  transform(value: Partial<ObjWithNameDemographics>): string {
    if (!Object.keys(value).length) return '';
    const firstName = value.firstName ?? '';
    const middleName = value.middleName ?? '';
    const lastName = value.lastName ?? '';

    const fullName = `${firstName} ${middleName} ${lastName}`
      .trim()
      .replace(/\s+/g, ' ');

    return fullName;
  }
}
