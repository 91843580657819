import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import {
  ISplunkMetric,
  MetricKey,
  PaymentPlanMetricKey,
  ResultMetricKey,
} from '@patient-ui/shared/models';

import { MetricActions } from './metric.actions';
import { MetricService } from './metric.service';

@Injectable()
export class MetricEffects {
  logBadDebtMetric = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActions.logBadDebtMetric),
      concatMap((action) => {
        const splunkMetric: ISplunkMetric[] = [];
        splunkMetric.push({
          metricKey: MetricKey.dashboardId,
          metricValue: action.metrics.dashboardId
            ? action.metrics.dashboardId
            : 'BadDebt',
        });
        splunkMetric.push({
          metricKey: MetricKey.eventCode,
          metricValue: action.metrics.eventCode,
        });
        splunkMetric.push({
          metricKey: MetricKey.templateId,
          metricValue: action.metrics.templateId,
        });
        if (action.metrics.invoiceNumber)
          splunkMetric.push({
            metricKey: MetricKey.invoiceNumber,
            metricValue: action.metrics.invoiceNumber,
          });
        if (action.metrics.specimenNumber)
          splunkMetric.push({
            metricKey: MetricKey.specimenNumber,
            metricValue: action.metrics.specimenNumber,
          });
        if (action.metrics.lpId)
          splunkMetric.push({
            metricKey: MetricKey.lpId,
            metricValue: action.metrics.lpId,
          });
        if (action.metrics.mobileNumber)
          splunkMetric.push({
            metricKey: MetricKey.mobileNumber,
            metricValue: action.metrics.mobileNumber,
          });
        if (action.metrics.userType)
          splunkMetric.push({
            metricKey: MetricKey.userType,
            metricValue: action.metrics.userType,
          });
        if (action.metrics.alertMsg)
          splunkMetric.push({
            metricKey: MetricKey.alertMsg,
            metricValue: action.metrics.alertMsg,
          });
        if (action.metrics.reasonCode)
          splunkMetric.push({
            metricKey: MetricKey.reasonCode,
            metricValue: action.metrics.reasonCode,
          });
        if (action.metrics.sessionID)
          splunkMetric.push({
            metricKey: MetricKey.sessionID,
            metricValue: action.metrics.sessionID,
          });
        if (action.metrics.showErrorFlag)
          splunkMetric.push({
            metricKey: MetricKey.showErrorFlag,
            metricValue: action.metrics.showErrorFlag,
          });
        if (action.metrics.patientId)
          splunkMetric.push({
            metricKey: MetricKey.patientId,
            metricValue: action.metrics.patientId,
          });

        return this.metricService.logSplunkMetric(splunkMetric).pipe(
          map(() => MetricActions.logBadDebtMetricSuccess()),
          catchError((_error) => of(MetricActions.logBadDebtMetricFailure()))
        );
      })
    )
  );

  logResultsMetric = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActions.logResultMetric),
      concatMap((action) => {
        const splunkMetric: ISplunkMetric[] = [];
        splunkMetric.push({
          metricKey: ResultMetricKey.dashboardId,
          metricValue: action.metrics.dashboardId
            ? action.metrics.dashboardId
            : 'ResultsPageMetric',
        });
        splunkMetric.push({
          metricKey: ResultMetricKey.eventCode,
          metricValue: action.metrics.eventCode,
        });
        splunkMetric.push({
          metricKey: ResultMetricKey.userType,
          metricValue: action.metrics.userType,
        });
        if (action.metrics.userId)
          splunkMetric.push({
            metricKey: ResultMetricKey.userId,
            metricValue: action.metrics.userId,
          });
        if (action.metrics.patientMasterId)
          splunkMetric.push({
            metricKey: ResultMetricKey.patientMasterId,
            metricValue: action.metrics.patientMasterId,
          });
        if (action.metrics.statusCode)
          splunkMetric.push({
            metricKey: ResultMetricKey.statusCode,
            metricValue: action.metrics.statusCode,
          });
        if (action.metrics.alertMsg)
          splunkMetric.push({
            metricKey: ResultMetricKey.alertMsg,
            metricValue: action.metrics.alertMsg,
          });
        if (action.metrics.resultId)
          splunkMetric.push({
            metricKey: ResultMetricKey.resultId,
            metricValue: action.metrics.resultId,
          });

        return this.metricService.logSplunkMetric(splunkMetric).pipe(
          map(() => MetricActions.logBadDebtMetricSuccess()),
          catchError((_error) => of(MetricActions.logBadDebtMetricFailure()))
        );
      })
    )
  );

  logPatientMFAMetric = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActions.logMfaMetric),
      concatMap((action) => {
        const splunkMetric: ISplunkMetric[] = [];
        splunkMetric.push({
          metricKey: MetricKey.dashboardId,
          metricValue: action.metrics.dashboardId
            ? action.metrics.dashboardId
            : 'MfaMetric',
        });
        splunkMetric.push({
          metricKey: MetricKey.eventCode,
          metricValue: action.metrics.eventCode,
        });
        splunkMetric.push({
          metricKey: MetricKey.templateId,
          metricValue: action.metrics.templateId,
        });

        if (action.metrics.mobileNumber)
          splunkMetric.push({
            metricKey: MetricKey.mobileNumber,
            metricValue: action.metrics.mobileNumber,
          });
        if (action.metrics.userType)
          splunkMetric.push({
            metricKey: MetricKey.userType,
            metricValue: action.metrics.userType,
          });
        if (action.metrics.alertMsg)
          splunkMetric.push({
            metricKey: MetricKey.alertMsg,
            metricValue: action.metrics.alertMsg,
          });
        if (action.metrics.reasonCode)
          splunkMetric.push({
            metricKey: MetricKey.reasonCode,
            metricValue: action.metrics.reasonCode,
          });
        if (action.metrics.sessionID)
          splunkMetric.push({
            metricKey: MetricKey.sessionID,
            metricValue: action.metrics.sessionID,
          });
        return this.metricService.logSplunkMetric(splunkMetric).pipe(
          map(() => MetricActions.logMfaMetricSuccess()),
          catchError((_error) => of(MetricActions.logMfaMetricFailure()))
        );
      })
    )
  );

  logPaymentPlanMetric = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActions.logPaymentPlanMetric),
      concatMap((action) => {
        const splunkMetric: ISplunkMetric[] = [];
        splunkMetric.push({
          metricKey: PaymentPlanMetricKey.dashboardId,
          metricValue: action.metrics.dashboardId
            ? action.metrics.dashboardId
            : 'PaymentPlanMetric',
        });

        splunkMetric.push({
          metricKey: PaymentPlanMetricKey.eventCode,
          metricValue: action.metrics.eventCode,
        });
        splunkMetric.push({
          metricKey: PaymentPlanMetricKey.invoiceNumber,
          metricValue: action.metrics.invoiceNumber,
        });
        splunkMetric.push({
          metricKey: PaymentPlanMetricKey.userType,
          metricValue: action.metrics.userType,
        });
        if (action.metrics.reasonCode)
          splunkMetric.push({
            metricKey: PaymentPlanMetricKey.reasonCode,
            metricValue: action.metrics.reasonCode,
          });
        if (action.metrics.allInvoices)
          splunkMetric.push({
            metricKey: PaymentPlanMetricKey.allInvoices,
            metricValue: action.metrics.allInvoices,
          });
        if (action.metrics.templateId)
          splunkMetric.push({
            metricKey: PaymentPlanMetricKey.templateId,
            metricValue: action.metrics.templateId,
          });
        if (action.metrics.alertMsg)
          splunkMetric.push({
            metricKey: PaymentPlanMetricKey.alertMsg,
            metricValue: action.metrics.alertMsg,
          });
        if (action.metrics.showErrorFlag)
          splunkMetric.push({
            metricKey: PaymentPlanMetricKey.showErrorFlag,
            metricValue: action.metrics.showErrorFlag,
          });

        return this.metricService.logSplunkMetric(splunkMetric).pipe(
          map(() => MetricActions.logBadDebtMetricSuccess()),
          catchError((_error) => of(MetricActions.logBadDebtMetricFailure()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private metricService: MetricService
  ) {}
}
