import { createAction, props } from '@ngrx/store';
import { UserClaims } from '@okta/okta-auth-js';

import { Patient } from '@patient-ui/shared/models';

export const authenticate = createAction(
  '[Auth] authenticate',
  props<{
    email: string;
    password: string;
    redirect?: string;
    context?: string;
  }>()
);

export const authenticateSuccess = createAction(
  '[Auth] authenticate Success',
  props<{ response?: UserClaims }>()
);

export const updateLoginInfoSuccess = createAction(
  '[Auth] Update LoginInfo Success',
  props<{ payload?: Patient }>()
);

export const updateLoginInfoFailure = createAction(
  '[Auth] Update LoginInfo Failure',
  props<{ payload?: Patient }>()
);

export const authenticateFailure = createAction('[Auth] authenticate Failure');

export const getLoginInfo = createAction('[Auth] Get Login Info');

export const logOut = createAction(
  '[Auth] Logout',
  props<{ redirect: string }>()
);

export const signOutOkta = createAction(
  '[Auth] Logout Okta',
  props<{ redirect: string }>()
);

export const logOutSuccess = createAction(
  '[Auth] Logout Success',
  props<{ redirect: string }>()
);
export const logOutFailure = createAction(
  '[Auth] Logout Failure',
  props<{ redirect: string }>()
);

export const saveSession = createAction(
  '[Auth] Save Session',
  props<{ token: string; userId: string }>()
);

export const refreshSession = createAction('[Auth] Refresh Session');

export const refreshSessionSuccess = createAction(
  '[Auth] Refresh Session Success'
);
export const refreshSessionFailure = createAction(
  '[Auth] Refresh Session Failure'
);
