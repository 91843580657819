/* eslint-disable no-useless-escape */
export const ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
export const regexPatterns = {
  email: new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ),
  invoiceNumber: new RegExp(/^[0-9]{8}$/),
  middleInitial: new RegExp(/^([a-z])?([A-Z])?$/),
  name: new RegExp(/^[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*$/),
  password: new RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/
  ),
  suffix: new RegExp(/[a-zA-Z\\.]+/),
  phone: new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/),
  additionalInvoices: new RegExp(/^[ 0-9, ]*$/),
  pin: new RegExp(/^[0-9]{4}$/),
  ssn4: new RegExp(/^(?!0000)[0-9]{4}$/),
  address: new RegExp(/^[ a-zA-Z0-9-\:,\'.#; ]*$/),
  city: new RegExp(/^[A-Za-z]+((\s)?((\-)?([A-Za-z])+))*$/),
  zipPlusFour: new RegExp(/^\d{5}$|^\d{5}-\d{4}$/),
  insuranceName: new RegExp(
    /^[A-Za-z0-9&:,-]+((\s)?((\'|\-|\.)?([A-Za-z0-9&:,-])+))*$/
  ),
  groupNumber: new RegExp(/^[ a-zA-Z0-9-]*$/),
  alphaNumbericOnly: new RegExp(/^[a-zA-Z0-9]+$/),
  numericOnly: new RegExp(/^[0-9]+$/),
  alphaOnly: new RegExp(/^[a-zA-Z]+$/),
};
export const Phone_Regex = new RegExp(
  /^\(([2-9]{1}1[02-9]{1}|[2-9]{1}[02-8]{1}1|[2-9]{1}[02-8]{1}[02-9]{1})\)\s{1}[0-9]{3}\-[0-9]{4}$/
);
