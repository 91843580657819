export class InvoiceDetails {
  balance?: number;
  lcaInvoiceNumber?: string;
  specimenNumber!: string;
}

export class PaymentPlanOptions {
  duration?: number;
  amountPerPayment?: number;
  nextInstallmentDate?: string;
  lastInstallmentDate?: string;
}

export interface PaymentPlanRequest {
  accountBalance?: number;
  paymentSource?: string;
  invoiceDetails?: InvoiceDetails[];
}

export interface PaymentPlanResponse {
  balance?: number;
  options?: PaymentPlanOptions[];
  reasonCode?: string;
  reasonDescription?: string;
  successful?: boolean | null;
}
