import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Environment } from '@patient-ui/shared-ui/utils';

import { AppModule } from './app/app.module';
import { OktaAuthConfig } from './app/auth-config';
const APP_ENV_PATH = 'assets/environment.json';
const APP_ENV_VERSION_PATH = 'assets/version.json';

async function loadEnvironmentOkta() {
  const [appEnv, appEnvVer] = await Promise.all([
    fetch(APP_ENV_PATH).then((response) => response.json()),
    fetch(APP_ENV_VERSION_PATH).then((response) => response.json()),
  ]);

  return { ...appEnv, ...appEnvVer } as Environment;
}

loadEnvironmentOkta().then(async (environment) => {
  const authConfig = new OktaAuthConfig({
    clientId: environment.okta.clientId,
    issuer: environment.okta.issuer,
    redirectUri: environment.okta.redirectUri,
  });

  platformBrowserDynamic([
    {
      provide: OktaAuthConfig,
      useValue: authConfig,
    },
  ])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
