import { Injectable } from '@angular/core';

import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({ providedIn: 'root' })
export class RecaptchaService {
  constructor(private envSvc: EnvironmentService) {}

  /**
   * Waits for environment-specific variables to be defined and resolves the
   * recaptcha site key
   * @returns a promise that returns environment-specific recaptcha site key
   */
  ensureRecaptchaIsSet() {
    return new Promise<string>((resolve) => {
      (() => this.waitForIt(resolve))();
    });
  }

  private waitForIt(resolveFn: (value: string | PromiseLike<string>) => void) {
    if (this.envSvc) {
      return resolveFn(this.envSvc.recaptchaKey);
    } else {
      setTimeout(() => this.waitForIt(resolveFn), 10);
    }
  }
}
