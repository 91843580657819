import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import * as AccountVerificationActions from './account-verification.actions';
import { AccountVerificationLexisService } from './account-verification.service';

@Injectable()
export class AccountVerificationEffects {
  getLNIVQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountVerificationActions.getLNIVQuestions),
      exhaustMap((action) =>
        this.accountVerificationService
          .getQuestions(
            action.patientToken,
            action.activePatientIsDependent,
            action.patientId
          )
          .pipe(
            map((lnivGetQuestionsResponsePayload) =>
              AccountVerificationActions.getLNIVQuestionsSuccess({
                lexisNexisQuizResponse: lnivGetQuestionsResponsePayload,
              })
            ),
            catchError((_error) =>
              of(
                AccountVerificationActions.getLNIVQuestionsFailure({
                  lexisNexisErrorResponse: _error,
                })
              )
            )
          )
      )
    )
  );

  gradeLNIVQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountVerificationActions.gradeLNIVQuestions),
      exhaustMap((action) =>
        this.accountVerificationService
          .gradeQuiz(
            action.quiz,
            action.activePatientIsDependent,
            action.patientId
          )
          .pipe(
            map((gradeQuizResponse) =>
              AccountVerificationActions.gradeLNIVQuestionsSuccess({
                gradeQuizResponse,
              })
            ),
            catchError((_error) =>
              of(
                AccountVerificationActions.gradeLNIVQuestionsFailure({
                  lexisNexisErrorResponse: _error,
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountVerificationService: AccountVerificationLexisService
  ) {}
}
