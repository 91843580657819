export const MESSAGES_TEXT = {
  success: {
    addLinkedAccountAdultSuccess: 'Linked Account message sent successfully',
    addLinkedAccountMinorSuccess: 'account added successfully',
    linkRequestActionSuccess: {
      accept: (fullName: string) => `${fullName}'s account linked successfully`,
      decline: 'Account link declined',
    },
    removeLinkedAccountSuccess: 'Access removed successfully',
    removeLinkedAccountMinorSuccess: 'Access removed successfully',
  },
  error: {
    loadLinkedAccountsFailure: `We're not able to load your Linked Accounts at this time. Please try again later.`,
    linkAlreadyExists: `We are unable to complete your request. These accounts are already linked.`,
    addLinkedAccountAdultFailure: `We can't find the person you requested based on the information you provided.`,
    addLinkedAccountAdultExists: (fullName: string) =>
      `Your Linked Account message was not sent. You've already sent a request to ${fullName}. You can click <b>Resend Linking Request</b> on ${
        fullName.split(' ')[0]
      }'s card to remind them to respond.`,
    addLinkedAccountMinorFailure: `could not be added at this time. Please check your entry and try again. Ensure your personal information, such as home address, is entered accurately and exactly matches the information in your account.`,
    addLinkedAccountMinorExists: `is on someone else's Labcorp Patient account. Contact the adult account holder to request access to their information.`,
    linkRequestActionFailure: {
      accept:
        'We are unable to accept access at this time. Please try again later.',
      decline:
        'We are unable to decline access at this time. Please try again later.',
    },
    removeLinkedAccountFailure:
      'We are unable to remove access at this time. Please try again later.',
    removeLinkedAccountMinorFailure:
      'We are unable to remove access at this time. Please try again later.',
  },
};
