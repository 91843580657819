import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  GradedQuizResponse,
  LexisNexisErrorResponse,
  LexisNexisQuiz,
} from '@patient-ui/shared/models';

import * as AccountVerificationActions from './account-verification.actions';

export const accountVerificationFeatureKey = 'accountVerification';

export interface AccountVerificationState {
  lexisNexisQuiz: LexisNexisQuiz;
  gradedQuizResponse: GradedQuizResponse;
  getLexisNexisQuizRequestStatus: RequestStatus;
  getGradedQuizResultRequestStatus: RequestStatus;
  quizErrorResponse: LexisNexisErrorResponse;
  gradedQuizErrorResponse: LexisNexisErrorResponse;
}
export const AccountVerificationInitalState: AccountVerificationState = {
  lexisNexisQuiz: {
    id: '',
    transactionId: '',
    questions: [],
    authenticationQuizStatus: '',
  },
  gradedQuizResponse: {},
  getLexisNexisQuizRequestStatus: RequestStatus.NotSent,
  getGradedQuizResultRequestStatus: RequestStatus.NotSent,
  quizErrorResponse: {
    code: '',
    description: '',
  },
  gradedQuizErrorResponse: {
    code: '',
    description: '',
  },
};

export const accountVerificationReducer = createReducer(
  AccountVerificationInitalState,
  on(
    AccountVerificationActions.getLNIVQuestionsSuccess,
    (state, { lexisNexisQuizResponse }) => ({
      ...state,
      lexisNexisQuiz: {
        id: lexisNexisQuizResponse.id,
        transactionId: lexisNexisQuizResponse.transactionId,
        questions: lexisNexisQuizResponse.questions,
        authenticationQuizStatus:
          lexisNexisQuizResponse.authenticationQuizStatus,
      },
      getLexisNexisQuizRequestStatus: RequestStatus.Success,
    })
  ),
  on(
    AccountVerificationActions.getLNIVQuestionsFailure,
    (state, { lexisNexisErrorResponse }) => ({
      ...state,
      lexisNexisQuiz: {
        id: '',
        transactionId: '',
        questions: [],
        authenticationQuizStatus: '',
      },
      getLexisNexisQuizRequestStatus: RequestStatus.Failure,
      quizErrorResponse: lexisNexisErrorResponse,
    })
  ),
  on(
    AccountVerificationActions.gradeLNIVQuestionsSuccess,
    (state, { gradeQuizResponse }) => ({
      ...state,
      lexisNexisQuiz: {
        id: gradeQuizResponse.id ? gradeQuizResponse.id : '',
        transactionId: gradeQuizResponse.transactionId
          ? gradeQuizResponse.transactionId
          : '',
        questions: gradeQuizResponse.questions
          ? gradeQuizResponse.questions
          : [],
        authenticationQuizStatus: gradeQuizResponse.authenticationQuizStatus
          ? gradeQuizResponse.authenticationQuizStatus
          : '',
      },
      gradedQuizResponse: gradeQuizResponse,
      getGradedQuizResultRequestStatus: RequestStatus.Success,
    })
  ),
  on(
    AccountVerificationActions.gradeLNIVQuestionsFailure,
    (state, { lexisNexisErrorResponse }) => ({
      ...state,
      gradedQuizResponse: {},
      lexisNexisQuiz: {
        id: '',
        transactionId: '',
        questions: [],
        authenticationQuizStatus: '',
      },
      gradedQuizErrorResponse: lexisNexisErrorResponse,
      getGradedQuizResultRequestStatus: RequestStatus.Failure,
    })
  ),
  on(AccountVerificationActions.clearAccountVerificationState, (state) => ({
    ...state,
    lexisNexisQuiz: { ...AccountVerificationInitalState.lexisNexisQuiz },
    gradedQuizResponse: {
      ...AccountVerificationInitalState.gradedQuizResponse,
    },
    getLexisNexisQuizRequestStatus: RequestStatus.NotSent,
    getGradedQuizResultRequestStatus: RequestStatus.NotSent,
    quizErrorResponse: { ...AccountVerificationInitalState.quizErrorResponse },
    gradedQuizErrorResponse: {
      ...AccountVerificationInitalState.gradedQuizErrorResponse,
    },
  })),
  on(AccountVerificationActions.clearAuthQuizResponse, (state) => ({
    ...state,
    gradedQuizResponse: {},
    quizErrorResponse: { ...AccountVerificationInitalState.quizErrorResponse },
    gradedQuizErrorResponse: {
      ...AccountVerificationInitalState.gradedQuizErrorResponse,
    },
  }))
);
