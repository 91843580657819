import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';

import { MetricActions } from './metric.actions';

export const metricFeatureKey = 'metric';

export interface MetricState {
  campaignTemplateId: string | null;
  logBadDebtStatus: RequestStatus;
  logResultStatus: RequestStatus;
  logMfaStatus: RequestStatus;
  logPaymentPlanMetric: RequestStatus;
}

export const MetricInitialState: MetricState = {
  campaignTemplateId: null,
  logBadDebtStatus: RequestStatus.NotSent,
  logResultStatus: RequestStatus.NotSent,
  logMfaStatus: RequestStatus.NotSent,
  logPaymentPlanMetric: RequestStatus.NotSent,
};

export const metricReducer = createReducer(
  MetricInitialState,
  on(MetricActions.logBadDebtMetric, (state, { metrics }) => ({
    ...state,
    campaignTemplateId: metrics.templateId,
    logBadDebtStatus: RequestStatus.Pending,
  })),
  on(MetricActions.logBadDebtMetricSuccess, (state) => ({
    ...state,
    logBadDebtStatus: RequestStatus.Success,
  })),
  on(MetricActions.logBadDebtMetricFailure, (state) => ({
    ...state,
    logBadDebtStatus: RequestStatus.Failure,
  })),
  on(MetricActions.logResultMetric, (state) => ({
    ...state,
    logResultStatus: RequestStatus.Pending,
  })),
  on(MetricActions.logResultMetricSuccess, (state) => ({
    ...state,
    logResultStatus: RequestStatus.Success,
  })),
  on(MetricActions.logResultMetricFailure, (state) => ({
    ...state,
    logResultStatus: RequestStatus.Failure,
  })),
  on(MetricActions.logMfaMetric, (state, { metrics }) => ({
    ...state,
    campaignTemplateId: metrics.templateId,
    logMfaStatus: RequestStatus.Pending,
  })),
  on(MetricActions.logMfaMetricSuccess, (state) => ({
    ...state,
    logMfaStatus: RequestStatus.Success,
  })),
  on(MetricActions.logMfaMetricFailure, (state) => ({
    ...state,
    logMfaStatus: RequestStatus.Failure,
  })),
  on(MetricActions.logPaymentPlanMetric, (state) => ({
    ...state,
    logPaymentPlanMetric: RequestStatus.Pending,
  })),
  on(MetricActions.logBadDebtMetricSuccess, (state) => ({
    ...state,
    logPaymentPlanMetric: RequestStatus.Success,
  })),
  on(MetricActions.logBadDebtMetricFailure, (state) => ({
    ...state,
    logPaymentPlanMetric: RequestStatus.Failure,
  }))
);
