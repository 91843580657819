export class CardDetails {
  type!: string;
  isSuccessful!: boolean;
  reasonCode!: string;
  reasonDescription!: string;
  analysis!: string;
  analysisResult!: string;
  cardBrand!: string;
  cardLastFourDigits!: string;
  currencyCode!: string;
  customerReferenceNumber!: string;
  expirationMonth!: string;
  expirationYear!: string;
  mitMsgType!: string;
  uID!: string;
}
