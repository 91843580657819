import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, Subscription, combineLatest, throwError, timer } from 'rxjs';
import {
  catchError,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { MFAService } from '@patient-ui/patient-web/store';
import {
  FactorsState,
  MFAActions,
  MFAEnrollResponse,
  selectMFALoading,
} from '@patient-ui/patient-web/store';
import { EventCode, OktaEnrolledFactor } from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { OktaModalService } from '../../okta-modal.service';
@Component({
  selector: 'patient-ui-okta-enrollment',
  templateUrl: './okta-enrollment.component.html',
  styleUrls: ['./okta-enrollment.component.scss'],
})
export class OktaEnrollmentComponent implements OnInit, OnDestroy {
  @Output() showEnroll: EventEmitter<boolean> = new EventEmitter<boolean>();
  showInstruction = true;
  showQRCode = false;
  showConfirmationScreen = false;
  mfaEnrollResponse$ = this.mfaStore.select(MFAEnrollResponse);
  mfaLoading$ = this.mfaStore.select(selectMFALoading);
  isLoading = false;
  oktaQRCodeURL = '';
  factorId = '';
  timerValue = 90;

  private unsubscribe$ = new Subject<void>();
  private sourceSubscription: Subscription | undefined;
  private countdownTimerSubscription: Subscription | undefined;
  private modalRef: NgbModalRef | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private mfaStore: Store<FactorsState>,
    private http: HttpClient,
    private oktaModalService: OktaModalService,
    private envService: EnvironmentService,
    private mfaService: MFAService
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.mfaEnrollResponse$.subscribe((data: any) => {
      if (data) {
        this.factorId = data?.id;
        this.oktaQRCodeURL = data?._embedded?.activation?._links?.qrcode?.href;
        this.showInstruction = false;
        this.showQRCode = true;
      }
    });
    combineLatest([this.mfaLoading$])
      .pipe(
        tap(([response]) => {
          this.isLoading = response;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.modalRef) {
      this.modalRef.dismiss('cancel');
    }
    if (this.sourceSubscription) {
      this.sourceSubscription.unsubscribe();
    }
    if (this.countdownTimerSubscription) {
      this.countdownTimerSubscription.unsubscribe();
    }
    this.mfaStore.dispatch(MFAActions.clearEnrollFactorResponse());
  }

  startTimer() {
    const countdownTimer$ = timer(0, 1000);
    this.countdownTimerSubscription = countdownTimer$.subscribe(() => {
      if (this.timerValue > 0) {
        this.timerValue--;
        if (this.timerValue === 0) {
          this.showEnroll.emit();
          if (this.modalRef) {
            this.modalRef.dismiss('timerFinished');
          }
        }
      }
    });
    const source$ = countdownTimer$.pipe(
      takeUntil(this.unsubscribe$),
      takeWhile(() => this.timerValue > 0),
      switchMap(() => {
        if (this.timerValue % 4 === 0 && this.factorId) {
          return this.oktaActivatePoll();
        }
        return timer(0);
      }),
      catchError((error) => throwError(error))
    );
    this.sourceSubscription = source$.subscribe(() => {});
  }

  oktaActivatePoll() {
    return this.http
      .get<OktaEnrolledFactor>(
        `${this.envService.baseUrl}/protected/patients/current/mfa/factors/enrolled/` +
          this.factorId
      )
      .pipe(
        tap((response) => {
          if (response.status === 'ACTIVE') {
            this.onNext('CONFIRM');
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
          }
        })
      );
  }

  onNext(step: string) {
    switch (step) {
      case 'QRC':
        this.mfaStore.dispatch(
          MFAActions.enrollFactor({ factorType: 'okta:totp' })
        );
        this.startTimer();
        break;
      case 'CONFIRM':
        this.showQRCode = false;
        this.showConfirmationScreen = true;
        this.mfaStore.dispatch(MFAActions.retriveMFAStatus());
        break;
    }
    this.mfaService.logMFAMetric(
      'Enroll okta factor in okta enrollment page.',
      EventCode.mfaOktaEnrollment
    );
  }

  cancel() {
    this.mfaService.logMFAMetric(
      'Cancel Okta factor in okta enrollment page.',
      EventCode.mfaOktaCancel
    );
    this.oktaModalService.close();
  }
}
