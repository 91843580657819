<ng-container>
  <div
    *ngFor="let title of pageTitles; index as i; first as isFirst; last as isLast"
    class="col p-0 d-flex flex-column justify-content-between"
  >
    <h4 class="d-none d-md-block text-black px-2">{{ title }}</h4>
    <h6 class="d-md-none text-black px-2">{{ title }}</h6>
    <div class="w-100 position-relative">
      <span class="position-relative" *ngIf="isFirst" class="rectangle d-none d-xl-inline-block position-absolute bg-success"></span>
      <hr class="position-absolute m-0" [class.w-50]="isLast" [class.w-100]="!isLast" />
      <hr
        class="position-absolute m-0 border-success"
        [class.w-50]="i == completedSteps || (i < completedSteps && isLast)"
        [class.w-100]="i < completedSteps && !isLast"
      />
      <ui-circle
        [completed]="i < completedSteps"
        [bgColor]="i == completedSteps ? 'white' : 'gray'"
        [borderColor]="i == completedSteps ? 'dark-green' : 'dk-gray'"
      ></ui-circle>
    </div>
  </div>
</ng-container>
