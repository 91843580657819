import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';

import { IPortalAddress } from '@patient-ui/shared/models';

import { SmartyStreetsActions } from './smartystreets.actions';
import { SmartyStreetsService } from './smartystreets.service';

@Injectable()
export class SmartyStreetsEffects {
  smartyStreetSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SmartyStreetsActions.smartyStreetsSearch),
      fetch({
        run: (action) =>
          this.smartyStreetsService.searchAddress(action.payload).pipe(
            map((smartyStreetsResult) => {
              if (smartyStreetsResult && smartyStreetsResult.suggestions) {
                return SmartyStreetsActions.smartyStreetsAddZipCode({
                  payload: smartyStreetsResult,
                });
              } else {
                return SmartyStreetsActions.smartyStreetsSuggestResults({
                  payload: [],
                });
              }
            })
          ),
        onError: () =>
          SmartyStreetsActions.smartyStreetsSuggestResults({ payload: [] }),
      })
    )
  );

  smartyStreetsAddZipCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SmartyStreetsActions.smartyStreetsAddZipCode),
      fetch({
        id: (action) => `${JSON.stringify(action.payload)}`,
        run: (action) => {
          const portalAddressList: IPortalAddress[] = [];
          action.payload.suggestions.forEach((suggestion) => {
            portalAddressList.push({
              street: suggestion.street_line,
              city: suggestion.city,
              state: suggestion.state,
              zipCode: suggestion.zipcode,
            });
          });
          return this.smartyStreetsService.addZipCode(portalAddressList).pipe(
            map((res) =>
              SmartyStreetsActions.smartyStreetsSuggestResults({
                payload: res,
              })
            )
          );
        },
        onError: () =>
          SmartyStreetsActions.smartyStreetsSuggestResults({ payload: [] }),
      })
    )
  );

  constructor(
    private actions$: Actions,
    private smartyStreetsService: SmartyStreetsService
  ) {}
}
