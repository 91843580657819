import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';

import { RequestStatus } from '@patient-ui/shared/enums';

import { MicrobiomeActions } from './microbiome.actions';
import { MicrobiomeService } from './microbiome.service';

@Injectable()
export class MicrobiomeEffects {
  getMicrobiomeSurvey = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrobiomeActions.getMicrobiomeSurvey),
      fetch({
        id: (action, _state) => `${JSON.stringify(action.payload)}`,
        run: (action, _state) =>
          this.microbiomeService
            .getMicrobiomeSurvey(action.payload)
            .pipe(
              map((res) =>
                MicrobiomeActions.getMicrobiomeSurveySuccess({ payload: res })
              )
            ),
        onError: (_action, error) => {
          const requestStatus = RequestStatus.Failure;
          let errorCode = '';
          if (error.status === 400) {
            errorCode = error.error.errorMessages[0].code;
          }
          return MicrobiomeActions.getMicrobiomeSurveyFailure({
            payload: {
              status: requestStatus,
              error: errorCode,
            },
          });
        },
      })
    )
  );

  postMicrobiomeSurveyResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrobiomeActions.postMicrobiomeSurveyResponse),
      fetch({
        id: (action, _state) => `${JSON.stringify(action.payload)}`,
        run: (action, _state) =>
          this.microbiomeService
            .postMicrobiomeSurveyResponse(action.payload)
            .pipe(
              map(() =>
                MicrobiomeActions.postMicrobiomeSurveyResponseSuccess({
                  payload: RequestStatus.Success,
                })
              )
            ),
        onError: (_action, _error) => {
          const requestStatus = RequestStatus.Failure;
          let errorCode = '';
          if (_error.status === 400) {
            errorCode = _error.error.errorMessages[0].code;
          }
          return MicrobiomeActions.postMicrobiomeSurveyResponseFailure({
            payload: {
              status: requestStatus,
              error: errorCode,
            },
          });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private microbiomeService: MicrobiomeService
  ) {}
}
