import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AccountEffects } from './account/account.effects';
import * as fromAccount from './account/account.reducer';
import { AccountVerificationEffects } from './account-verification/account-verification.effects';
import * as fromAccountVerification from './account-verification/account-verification.reducer';
import { AuthEffects } from './auth/auth.effects';
import * as fromAuthState from './auth/auth.reducer';
import { FastRegistrationEffects } from './fast-registration/fast-registration.effects';
import * as fromFastRegistration from './fast-registration/fast-registration.reducer';
import { InvoiceEffects } from './invoice/invoice.effects';
import * as fromInvoice from './invoice/invoice.reducer';
import { LinkedAccountsEffects } from './linked-accounts/linked-accounts.effects';
import * as fromLinkedAccounts from './linked-accounts/linked-accounts.reducer';
import { MetricEffects } from './metric/metric.effects';
import * as fromMetrics from './metric/metric.reducer';
import { MFAEffects } from './mfa/mfa.effects';
import * as fromMFA from './mfa/mfa.reducer';
import { MicrobiomeEffects } from './microbiome/microbiome.effects';
import * as fromMicrobiome from './microbiome/microbiome.reducer';
import { PatientEffects } from './patient/patient.effects';
import * as fromPatient from './patient/patient.reducer';
import { PendoEffects } from './pendo/pendo.effects';
import * as fromPendo from './pendo/pendo.reducer';
import { PhoneEffects } from './phone/phone.effects';
import * as fromPhone from './phone/phone.reducer';
import { ProfileEffects } from './profile/profile.effects';
import * as fromProfile from './profile/profile.reducer';
import { ResultsEffects } from './results/results.effects';
import * as fromResults from './results/results.reducer';
import { SelfOrdersEffects } from './self-orders/self-orders.effects';
import * as fromOrders from './self-orders/self-orders.reducer';
import { SmartyStreetsEffects } from './smartystreets/smartystreets.effects';
import * as fromSmartyStreets from './smartystreets/smartystreets.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromMicrobiome.microbiomeFeatureKey,
      fromMicrobiome.microbiomeReducer
    ),
    StoreModule.forFeature(
      fromPatient.patientFeatureKey,
      fromPatient.patientReducer
    ),
    StoreModule.forFeature(
      fromInvoice.invoiceFeatureKey,
      fromInvoice.invoiceReducer
    ),
    StoreModule.forFeature(fromPendo.pendoFeatureKey, fromPendo.pendoReducer),
    StoreModule.forFeature(
      fromSmartyStreets.smartyStreetsFeatureKey,
      fromSmartyStreets.smartyStreetsReducer
    ),
    StoreModule.forFeature(
      fromAuthState.authFeatureKey,
      fromAuthState.authReducer
    ),
    StoreModule.forFeature(
      fromFastRegistration.fastRegistrationFeatureKey,
      fromFastRegistration.fastRegReducer
    ),
    StoreModule.forFeature(
      fromAccount.accountFeatureKey,
      fromAccount.accountReducer
    ),
    StoreModule.forFeature(
      fromAccountVerification.accountVerificationFeatureKey,
      fromAccountVerification.accountVerificationReducer
    ),
    StoreModule.forFeature(fromPhone.phoneFeatureKey, fromPhone.phoneReducer),
    StoreModule.forFeature(
      fromProfile.profileFeatureKey,
      fromProfile.ProfileReducer
    ),
    StoreModule.forFeature(
      fromResults.resultsFeatureKey,
      fromResults.resultsReducer
    ),
    StoreModule.forFeature(fromMFA.MFAFeatureKey, fromMFA.mfaReducer),
    StoreModule.forFeature(
      fromOrders.selfOrdersFeatureKey,
      fromOrders.selfOrdersReducer
    ),
    StoreModule.forFeature(
      fromLinkedAccounts.linkedAccountsFeatureKey,
      fromLinkedAccounts.linkedAccountsReducer
    ),
    StoreModule.forFeature(
      fromMetrics.metricFeatureKey,
      fromMetrics.metricReducer
    ),

    EffectsModule.forFeature([
      PatientEffects,
      InvoiceEffects,
      MicrobiomeEffects,
      PendoEffects,
      SmartyStreetsEffects,
      AuthEffects,
      AccountEffects,
      AccountVerificationEffects,
      ResultsEffects,
      MFAEffects,
      ProfileEffects,
      SelfOrdersEffects,
      FastRegistrationEffects,
      LinkedAccountsEffects,
      PhoneEffects,
      MetricEffects,
    ]),
  ],
})
export class PatientWebStoreModule {}
