/**
 * Types of pdf available to patients
 */
export enum PdfDocType {
  /**
   *  `Invoice` represents the invoice detail pdf
   */
  Invoice = 'invoice',

  /**
   *  `Result` represents the result detail pdf
   */
  Result = 'result',
}
