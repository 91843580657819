import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  FactorsState,
  MFAActions,
  MFAEnrollResponse,
  selectActivatingFactor,
  selectActivationError,
  selectMFALoading,
} from '@patient-ui/patient-web/store';
import { MFAService } from '@patient-ui/patient-web/store';
import { EventCode } from '@patient-ui/shared/models';

import { OktaModalService } from '../../okta-modal.service';

@Component({
  selector: 'patient-ui-google-enrollment',
  templateUrl: './google-enrollment.component.html',
  styleUrls: ['./google-enrollment.component.scss'],
})
export class GoogleEnrollmentComponent implements OnInit {
  @Input()
  userEmail!: string;
  data = '';
  invalidInput = false;
  showInstruction = true;
  showQRCode = false;
  showConfirmationScreen = false;
  showManualEnrollInstruction = false;
  mfaEnrollResponse$ = this.mfaStore.select(MFAEnrollResponse);
  oktaQRCodeURL = '';
  factorId = '';
  sharedSecret = '';
  showOTPForm = false;
  activationErr$ = this.mfaStore.select(selectActivationError);
  activatingFactor$ = this.mfaStore.select(selectActivatingFactor);
  mfaLoading$ = this.mfaStore.select(selectMFALoading);
  isLoading = false;

  constructor(
    private mfaStore: Store<FactorsState>,
    private oktaModalService: OktaModalService,
    private mfaService: MFAService
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.mfaEnrollResponse$.subscribe((data: any) => {
      if (data) {
        this.factorId = data?.id;
        this.oktaQRCodeURL = data?._embedded?.activation?._links?.qrcode?.href;
        this.sharedSecret = data?._embedded?.activation?.sharedSecret;
        this.showInstruction = false;
        this.showQRCode = true;
      }
    });
    combineLatest([this.mfaLoading$])
      .pipe(
        tap(([response]) => {
          this.isLoading = response;
        })
      )
      .subscribe();
  }

  back(step: string) {
    switch (step) {
      case 'QRC':
        this.mfaStore.dispatch(
          MFAActions.enrollFactor({ factorType: 'google:totp' })
        );
        this.showQRCode = true;
        this.showManualEnrollInstruction = false;
        break;
      case 'INSTRUCTION':
        this.showInstruction = true;
        this.showQRCode = false;
        break;
    }
  }

  async onNext(step: string) {
    switch (step) {
      case 'QRC':
        await this.mfaStore.dispatch(
          MFAActions.enrollFactor({ factorType: 'google:totp' })
        );
        break;
      case 'OTP':
        this.showQRCode = false;
        this.showManualEnrollInstruction = false;
        this.showOTPForm = true;
        break;
      case 'CONFIRM':
        this.showOTPForm = false;
        this.showConfirmationScreen = true;
        this.mfaStore.dispatch(MFAActions.retriveMFAStatus());
        break;
    }
  }

  showManualEnrollScreen() {
    this.oktaQRCodeURL = '';
    this.showQRCode = false;
    this.showManualEnrollInstruction = true;
  }

  activateGoogleFactor(passCode: string) {
    interface FactorState {
      status: number;
    }
    this.mfaStore.dispatch(
      MFAActions.activateFactor({ factorId: this.factorId, passCode: passCode })
    );
    combineLatest([this.activatingFactor$, this.activationErr$])
      .pipe(
        tap(([activatingFactor, activationErr]) => {
          if (
            (activationErr as FactorState)?.status === 500 &&
            activatingFactor
          ) {
            this.invalidInput = true;
          } else if (!activatingFactor) {
            this.invalidInput = false;
            this.onNext('CONFIRM');
          }
        })
      )
      .subscribe();
    this.mfaService.logMFAMetric(
      'Enroll google factor in google enrollment page.',
      EventCode.mfaGoogleEnrollment
    );
  }

  cancel() {
    this.mfaService.logMFAMetric(
      'Cancel google factor in google enrollment page.',
      EventCode.mfaGoogleCancel
    );
    this.oktaModalService.close();
  }
}
