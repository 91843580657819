import { createAction, props } from '@ngrx/store';

import {
  IAddLinkedAccountRequest,
  ILinkAccountRequestData,
  ILinkedAccount,
  IRemoveLinkedAccountRequest,
  IRequestError,
  ISendMinorAccessLinkRequest,
  PortalUser,
} from '@patient-ui/shared/models';

export const loadLinkedAccounts = createAction(
  '[LinkedAccounts] Load LinkedAccounts'
);

export const loadLinkedAccountsSuccess = createAction(
  '[LinkedAccounts] Load LinkedAccounts Success',
  props<{ accountList: ILinkedAccount[] }>()
);

export const loadLinkedAccountsFailure = createAction(
  '[LinkedAccounts] Load LinkedAccounts Failure'
);

export const loadSharedAccesPendingRequest = createAction(
  '[LinkedAccounts] Load Shared Acces Pending Requests'
);

export const loadSharedAccesPendingRequestSuccess = createAction(
  '[LinkedAccounts] Load Shared Acces Pending Requests Success',
  props<{ pendingAccounttList: ILinkedAccount[] }>()
);

export const loadSharedAccesPendingRequestFailure = createAction(
  '[LinkedAccounts] Load Shared Acces Pending Requests Failure'
);

export const updateLinkedPortalUserListWithPAH = createAction(
  '[LinkedAccounts] Update Linked Account User List with PAH'
);

export const updateLinkedPortalUserListWithPAHSuccess = createAction(
  '[LinkedAccounts] Update Linked Account User List with PAH Success',
  props<{ updatedLinkedAccountList: PortalUser[] }>()
);

export const addLinkedAccount = createAction(
  '[LinkedAccounts] Add Linked Account',
  props<{ addLinkedAccountRequest: IAddLinkedAccountRequest }>()
);

export const addLinkedAccountSuccess = createAction(
  '[LinkedAccounts] Add Linked Account Success'
);

export const addLinkedAccountFailure = createAction(
  '[LinkedAccounts] Add Linked Account Failure',
  props<{ errorResponse: IRequestError }>()
);

/**
 * User from another account is requesting to link accounts - minor or adult
 */
export const linkRequestAction = createAction(
  '[LinkedAccounts] Link Request Accept or Decline',
  props<{ linkAccountRequestData: ILinkAccountRequestData }>()
);

export const linkRequestActionSuccess = createAction(
  '[LinkedAccounts] Link Request Accept or Decline Success'
);

export const linkRequestActionFailure = createAction(
  '[LinkedAccounts] Link Request Accept or Decline Failure',
  props<{ errorResponse: IRequestError }>()
);

export const removeLinkedAccount = createAction(
  '[LinkedAccounts] Remove Linked Account',
  props<{
    removeLinkedAccountRequest: IRemoveLinkedAccountRequest;
  }>()
);

export const removeLinkedAccountSuccess = createAction(
  '[LinkedAccounts] Remove Linked Account Success',
  props<{ linkedIds: string[] }>()
);

export const removeLinkedAccountFailure = createAction(
  '[LinkedAccounts] Remove Linked Account Failure',
  props<{ errorResponse: IRequestError }>()
);

export const sendMinorAccessLinkRequest = createAction(
  '[LinkedAccounts] Send Minor Access Link Request',
  props<{ sendMinorAccessLinkRequest: ISendMinorAccessLinkRequest }>()
);

export const sendMinorAccessLinkRequestSuccess = createAction(
  '[LinkedAccounts] Send Minor Access Link Request Success'
);

export const sendMinorAccessLinkRequestFailure = createAction(
  '[LinkedAccounts] Send Minor Access Link Request Failure',
  props<{ errorResponse: IRequestError }>()
);

export const sendMinorAccessLinkRequests = createAction(
  '[LinkedAccounts] Send Minor Access Link Requests',
  props<{ sendMinorAccessLinkRequest: ISendMinorAccessLinkRequest[] }>()
);

export const sendMinorAccessLinkRequestsSuccess = createAction(
  '[LinkedAccounts] Send Minor Access Link Requests Success'
);

export const sendMinorAccessLinkRequestsFailure = createAction(
  '[LinkedAccounts] Send Minor Access Link Requests Failure',
  props<{ errorResponse: IRequestError }>()
);

export const resetModalActionsState = createAction(
  '[LinkedAccounts] Reset Modal Actions State'
);
