import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OrderHistoryResponse } from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class SelfOrderService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getSelfOrders(): Observable<OrderHistoryResponse> {
    const url = `${this.envService.baseUrl}/protected/patients/current/linkedAccounts/orders`;
    return this.http.get<OrderHistoryResponse>(url);
  }

  getOrderPdf(orderId: string): Observable<Blob> {
    const orderPdfUrl = `${this.envService.baseUrl}/protected/patients/current/orders/${orderId}/pdf`;
    return this.http.get(orderPdfUrl, {
      headers: new HttpHeaders({ Accept: 'application/pdf' }),
      responseType: 'blob',
    });
  }
}
