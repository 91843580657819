export class IValidationErrors {
  code!: string;
  description!: string;
  errors!: IErrorMessage[];
}

export class IErrorMessage {
  code?: string;
  description!: string;
  key!: string;
}
