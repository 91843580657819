import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  IAddLinkedAccountRequest,
  ILinkAccountRequestData,
  ILinkedAccount,
  IRemoveLinkedAccountRequest,
  ISendMinorAccessLinkRequest,
  LinkedAcctAccess,
  LinkedAcctRole,
  LinkedAcctStatus,
  Patient,
  PortalUser,
} from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({ providedIn: 'root' })
export class LinkedAccountsService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  loadLinkedAccounts(httpParams = {}): Observable<ILinkedAccount[]> {
    const params = new HttpParams({ fromObject: httpParams });
    return this.http.get<ILinkedAccount[]>(
      `${this.envService.baseUrl}/protected/patients/current/linkedAccounts`,
      {
        params,
      }
    );
  }

  loadSharedAccessPendingRequest(
    httpParams = {}
  ): Observable<ILinkedAccount[]> {
    const params = new HttpParams({ fromObject: httpParams });
    return this.http.get<ILinkedAccount[]>(
      `${this.envService.baseUrl}/protected/patients/current/linkedAccounts?linkedAcctToCurrentUser=INITIATED`,
      {
        params,
      }
    );
  }

  addLinkedAccount(
    data: IAddLinkedAccountRequest
  ): Observable<HttpResponse<unknown>> {
    return this.http.post(
      `${this.envService.baseUrl}/protected/patients/current/linkedAccounts/addLinkedAccount`,
      data,
      {
        observe: 'response',
      }
    );
  }

  linkAccountRequest(data: ILinkAccountRequestData): Observable<void> {
    return this.http.post<void>(
      `${this.envService.baseUrl}/protected/patients/current/linkedAccounts/linkedAccountRequest`,
      data
    );
  }

  removeLinkedAccount(
    data: IRemoveLinkedAccountRequest
  ): Observable<HttpResponse<unknown>> {
    return this.http.post(
      `${this.envService.baseUrl}/protected/patients/current/linkedAccounts/removeLinkedAccount`,
      data,
      { observe: 'response' }
    );
  }

  sendMinorAccessLinkRequest(
    data: ISendMinorAccessLinkRequest
  ): Observable<HttpResponse<unknown>> {
    return this.http.post(
      `${this.envService.baseUrl}/protected/patients/current/linkedAccounts/sendMinorAccessLinkRequest`,
      data,
      { observe: 'response' }
    );
  }

  sendMinorAccessLinkRequests(
    data: ISendMinorAccessLinkRequest[]
  ): Observable<HttpResponse<unknown>> {
    return this.http.post(
      `${this.envService.baseUrl}/protected/patients/current/linkedAccounts/sendMinorAccessLinkRequests`,
      data,
      { observe: 'response' }
    );
  }

  updateLinkedPortalUserListWithPAH(
    user: Patient,
    accountList: ILinkedAccount[]
  ): Observable<PortalUser[]> {
    const linkedPortalUsers: PortalUser[] = [];
    const primaryPatientName = <PortalUser>{
      id: user.id ? user.id : 999,
      patientMasterId: user.id ? user.id.toString() : '999',
      linkedId: undefined,
      recipientLinkedId: undefined,
      displayName: user.firstName + ' ' + user.lastName,
      isPrimary: 'primary',
      isPrimaryAccountHolder: true,
      isMinor: false,
      quizStatus: user.authenticationQuizStatus,
      linkedAcctAccess: LinkedAcctAccess.ALL,
      linkedAcctRole: LinkedAcctRole.READWRITE,
    };
    linkedPortalUsers.push(primaryPatientName);
    const linkedUsers = accountList.filter(
      (linkedAccount) =>
        linkedAccount.linkedAcctToCurrentUserStatus ===
          LinkedAcctStatus.ACCEPTED ||
        (linkedAccount.isMinor &&
          linkedAccount.currentUserToLinkedAcctStatus ===
            LinkedAcctStatus.ACCEPTED)
    );
    if (linkedUsers.length > 0) {
      linkedUsers.forEach((linkedUser) => {
        const patientName = linkedUser.firstName + ' ' + linkedUser.lastName;
        linkedPortalUsers.push({
          id: parseInt(linkedUser.patientMasterId, 10),
          patientMasterId: linkedUser.patientMasterId,
          linkedId: linkedUser.linkedId,
          recipientLinkedId: linkedUser.recipientLinkedId,
          displayName: patientName,
          isPrimary: linkedUser.isPrimaryAccountHolder
            ? 'primary'
            : 'dependent',
          isPrimaryAccountHolder: linkedUser.isPrimaryAccountHolder
            ? true
            : false,
          isMinor: linkedUser.isMinor,
          quizStatus: linkedUser.authQuizStatus,
          linkedAcctAccess: linkedUser.linkedAcctAccess,
          linkedAcctRole: linkedUser.linkedAcctRole,
        });
      });
      //Populate All when there are linked accounts.
      linkedPortalUsers.push({ displayName: 'All Patients', id: 0 });
    }
    return of(linkedPortalUsers);
  }
}
