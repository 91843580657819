import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ILinkURLText } from '@patient-ui/shared/models';

export const navLinksPublic: ILinkURLText[] = [
  {
    url: 'https://patient.labcorp.com/help-center/home',
    text: 'Help Center',
  },
  {
    url: 'https://patient.labcorp.com/registration',
    text: 'Create an Account',
  },
];

@Component({
  selector: 'ui-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  @Input() withStripe!: boolean;
  @Input() showButton!: boolean;
  @Input() navLinks: ILinkURLText[] = [];

  public isMenuCollapsed = true;
}
