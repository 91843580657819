import { createAction, props } from '@ngrx/store';

import {
  GradedQuizResponse,
  LexisNexisCompletedQuiz,
  LexisNexisErrorResponse,
  LexisNexisQuiz,
} from '@patient-ui/shared/models';

export const getLNIVQuestions = createAction(
  '[Account Verification] Get LNIV Questions Payload',
  props<{
    patientToken: string;
    activePatientIsDependent: boolean;
    patientId?: number;
  }>()
);

export const getLNIVQuestionsSuccess = createAction(
  '[Account Verification] Get LNIV Questions Payload Success',
  props<{ lexisNexisQuizResponse: LexisNexisQuiz }>()
);

export const getLNIVQuestionsFailure = createAction(
  '[Account Verification] Get LNIV Questions Payload Failure',
  props<{ lexisNexisErrorResponse: LexisNexisErrorResponse }>()
);

export const gradeLNIVQuestions = createAction(
  '[Account Verification] Grade LNIV Questions Payload',
  props<{
    quiz: LexisNexisCompletedQuiz;
    activePatientIsDependent: boolean;
    patientId?: number;
  }>()
);

export const gradeLNIVQuestionsSuccess = createAction(
  '[Account Verification] Grade LNIV Questions Success',
  props<{ gradeQuizResponse: GradedQuizResponse }>()
);

export const gradeLNIVQuestionsFailure = createAction(
  '[Account Verification] Grade LNIV Questions Failure',
  props<{ lexisNexisErrorResponse: LexisNexisErrorResponse }>()
);

export const clearAccountVerificationState = createAction(
  '[Account Verification] Clear current account verification state'
);

export const clearAuthQuizResponse = createAction(
  '[Account Verification] Clear previous account verification quiz response state'
);

export const AccountVerificationActions = {
  getLNIVQuestions,
  getLNIVQuestionsSuccess,
  getLNIVQuestionsFailure,
  gradeLNIVQuestions,
  gradeLNIVQuestionsSuccess,
  gradeLNIVQuestionsFailure,
  clearAccountVerificationState,
  clearAuthQuizResponse,
};
