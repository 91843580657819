import {
  AbstractControl,
  FormGroupDirective,
  FormGroupName,
} from '@angular/forms';

/**
 * Helper method for getting a formControl or formGroup via an implicitly
 * inherited formGroup, as well as nested formGroupName. To obtain the
 * `parentForm` and `groupName` parameters, use the following declarations:
 * In `@Component`:
 * `viewProviders: [{ provide: ControlContainer, useExisting:
 * [FormGroupDirective, FormGroupName] }]`
 * In the constructor:
 * `@Optional() private parentForm: FormGroupDirective,
 * @Optional() private groupName: FormGroupName`
 * @param componentName Name of the component requesting the form control.
 * Used in error messaging
 * @param parentForm The inherited parent form (see above)
 * @param groupName The nested form group name
 * @param controlName The control name expected to be found within the given
 * form group. Can itself point to a group
 */
export function getControl(
  componentName: string,
  parentForm: FormGroupDirective,
  groupName: FormGroupName,
  controlName: string
): AbstractControl {
  if (parentForm && controlName) {
    const control = parentForm.form.get(
      (groupName ? groupName.name + '.' : '') + controlName
    );
    if (!control) {
      throw new Error(
        `<${componentName}> could not locate the control/group with name '${controlName}'` +
          (groupName ? `inside form group with name '${groupName.name}'` : '')
      );
    }
    return control;
  } else {
    throw new Error(
      `<${componentName}> requires either a control/group, or controlName/groupName while hosted inside a FormGroup`
    );
  }
}
