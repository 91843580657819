import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'patient-ui-duplicate-registration-verify-identity',
  templateUrl: './duplicate-registration-verify-identity.component.html',
  styleUrls: ['./duplicate-registration-verify-identity.component.scss'],
})
export class DuplicateRegistrationVerifyIdentityComponent {
  @Output() submitEvent = new EventEmitter<void>();
  @Output() routeToSignInEvent = new EventEmitter<void>();
  constructor(public activeModal: NgbActiveModal) {}

  closeModal(): void {
    this.activeModal.dismiss('cancel');
  }

  submit() {
    // TODO: Uncomment following while working on PCSA-10907 & PCSA-10908
    // this.submitEvent.emit();
  }

  /**
   * Redirects the user to the landing page
   *
   * This function is used across registration component to handle user redirection
   * after a specific action or process, such as on click on cancel button and close
   * the modal.
   */
  routeToSignIn() {
    this.activeModal.dismiss('cancel');
    this.routeToSignInEvent.emit();
  }
}
