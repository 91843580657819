import { Directive, EventEmitter, Input, Output } from '@angular/core';

export type SortColumn =
  | 'lcaInvoiceNumber'
  | 'patientName'
  | 'dateOfService'
  | 'balanceDue';

export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};
export const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'th[sortable]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class NgbdSortableInvoice {
  @Input() sortable: SortColumn = 'lcaInvoiceNumber';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  compare = (v1: string | number, v2: string | number) =>
    v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
