import { InvoiceState } from '@patient-ui/patient-web/store';
import { RequestStatus } from '@patient-ui/shared/enums';
import { PaymentType } from '@patient-ui/shared/models';

import { currentPatient, otherPatient } from './patient.mock';

export const INITIAL_INVOICE_STATE: InvoiceState = {
  reasonCode: '200',
  invoice: {
    number: '12345',
    zipCode: '27215',
    balanceDue: 0,
    invoiceAmount: 0,
    billingAddress: {
      line1: '3060 S. Church St.',
      line2: '',
      city: 'Burlington',
      state: 'NC',
      zipCode: '27215',
    },
    specimenNumber: '12345',
    dateOfService: '2020-01-01',
    invoiceDate: '2020-01-02',
    documentKey: '',
    patientName: 'Test Patient',
    pid: '12345',
    physicianDetail: {
      firstName: 'Test',
      lastName: 'Doctor',
      middleName: '',
      prefix: 'Dr.',
      orgName: '',
      practiceLocationAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
    },
    totalCharges: 0,
    adjustments: 0,
    medicarePaid: 0,
    medicaidPaid: 0,
    insurancePaid: 0,
    patientPaid: 0,
    orderingPhyAccountNumber: '',
    patientInvoiceDetail: {
      invoiceNumber: '20056637',
      accountNumber: '32102405',
      accountNpi: '1023080454',
      taxId: '13-3757370',
      mailToBarcode: '969750501',
      participateZip: '20056',
      billCode: 'XI',
      billDate: '2022-05-24',
      billTo: {
        firstName: 'NAPP',
        lastName: 'ANITA',
        address: {
          street: '5450 MILLSTREAM RD',
          city: 'MC LEANSVILLE',
          state: 'NC',
          zip: {
            code: '27301',
          },
        },
        phone: '3366753396',
      },
      dunning: {
        number: 1,
        suffix: 'A',
        specialDunning: 'U',
        specialCategory: 'COPAY/DEDUCTIBLE/COINSURANCE',
        message: [
          'THIS BILL REPRESENTS THE COINSURANCE, DEDUCTIBLE OR COPAY AMOUNT DUE. WE UNDERSTAND',
          'OUR PATIENTS MAY BE FACING UNEXPECTED FINANCIAL HARDSHIPS. IF YOU NEED ASSISTANCE',
          'WITH YOUR BILL, PLEASE CALL THE NUMBER LISTED ON YOUR INVOICE.',
        ],
      },
      specimenNumber: '214330500140',
      specimenDate: '2022-05-23',
      patient: {
        lastName: 'ANITA',
        firstName: 'NAPP',
        dateOfBirth: '2004-03-31',
        gender: 'F',
        relationToSubscriber: '1',
      },
      physician: {
        name: 'COMMUNITY FAMILY PRACTICE',
        address: {
          street: '260 MERRIMON AVE STE 200',
          city: 'ASHEVILLE',
          state: 'NC',
          zip: {
            code: '28801',
          },
        },
      },
      lab: {
        code: 'CB',
        name: 'LA',
        brandCode: 'LCA',
        address: {
          street: '6370 WILCOX ROAD',
          city: 'DUBLIN',
          state: 'OH',
          zip: {
            code: '43016',
          },
        },
      },
      orderedTests: [
        {
          testNumber: '001818',
          testType: 'T',
        },
        {
          testNumber: '998085',
          testType: 'T',
        },
      ],
      diagnosisCodes: ['Z00.00'],
      invoiceTests: [
        {
          testName: 'Glucose, Plasma',
          price: '37.00',
        },
        {
          testName: 'Venipuncture',
          price: '25.00',
        },
      ],
      amount: {
        total: {
          payments: '0.00',
          adjustments: '0.00',
          pcDiscount: '0.00',
          medicare: {
            billed: '0.00',
            payments: '0.00',
            denials: '0.00',
            deduction: '0.00',
          },
          medicaid: {
            billed: '0.00',
            payments: '0.00',
            deduction: '0.00',
          },
          insurance: {
            payments: '0.00',
          },
        },
        balance: {
          charges: '62.00',
          adjustments: '0.00',
          insurance: '0.00',
          patient: '0.00',
          due: '62.00',
          medicareMedicaidTotal: '0.00',
        },
      },
      ocr: {
        specimenNumber: '214330500140',
        amount: '62.00',
        checkDigit: 3,
      },
      insurance: {
        payments: null,
      },
      remit: {
        companyName: 'Laboratory Corporation of America Holdings',
        address: {
          street: 'P.O. BOX 2240',
          city: null,
          state: null,
          zip: null,
        },
        phoneNumber: '8008456167',
        web: 'www.labcorp.com/billing',
      },
      drawLocation: 'OTHER',
    },
  },
  findInvoice: {
    number: '',
    zipCode: '',
    balanceDue: 0,
    invoiceAmount: 0,
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    specimenNumber: '',
    dateOfService: '',
    invoiceDate: '',
    documentKey: '',
    patientName: '',
    pid: '',
    physicianDetail: {
      firstName: '',
      lastName: '',
      middleName: '',
      prefix: '',
      orgName: '',
      practiceLocationAddress: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    totalCharges: 0,
    adjustments: 0,
    medicarePaid: 0,
    medicaidPaid: 0,
    insurancePaid: 0,
    patientPaid: 0,
    orderingPhyAccountNumber: '',
    patientInvoiceDetail: {
      invoiceNumber: '20056637',
      accountNumber: '32102405',
      accountNpi: '1023080454',
      taxId: '13-3757370',
      mailToBarcode: '969750501',
      participateZip: '20056',
      billCode: 'XI',
      billDate: '2022-05-24',
      billTo: {
        firstName: 'NAPP',
        lastName: 'ANITA',
        address: {
          street: '5450 MILLSTREAM RD',
          city: 'MC LEANSVILLE',
          state: 'NC',
          zip: {
            code: '27301',
          },
        },
        phone: '3366753396',
      },
      dunning: {
        number: 1,
        suffix: 'A',
        specialDunning: 'U',
        specialCategory: 'COPAY/DEDUCTIBLE/COINSURANCE',
        message: [
          'THIS BILL REPRESENTS THE COINSURANCE, DEDUCTIBLE OR COPAY AMOUNT DUE. WE UNDERSTAND',
          'OUR PATIENTS MAY BE FACING UNEXPECTED FINANCIAL HARDSHIPS. IF YOU NEED ASSISTANCE',
          'WITH YOUR BILL, PLEASE CALL THE NUMBER LISTED ON YOUR INVOICE.',
        ],
      },
      specimenNumber: '214330500140',
      specimenDate: '2022-05-23',
      patient: {
        lastName: 'ANITA',
        firstName: 'NAPP',
        dateOfBirth: '2004-03-31',
        gender: 'F',
        relationToSubscriber: '1',
      },
      physician: {
        name: 'COMMUNITY FAMILY PRACTICE',
        address: {
          street: '260 MERRIMON AVE STE 200',
          city: 'ASHEVILLE',
          state: 'NC',
          zip: {
            code: '28801',
          },
        },
      },
      lab: {
        code: 'CB',
        name: 'LA',
        brandCode: 'LCA',
        address: {
          street: '6370 WILCOX ROAD',
          city: 'DUBLIN',
          state: 'OH',
          zip: {
            code: '43016',
          },
        },
      },
      orderedTests: [
        {
          testNumber: '001818',
          testType: 'T',
        },
        {
          testNumber: '998085',
          testType: 'T',
        },
      ],
      diagnosisCodes: ['Z00.00'],
      invoiceTests: [
        {
          testName: 'Glucose, Plasma',
          price: '37.00',
        },
        {
          testName: 'Venipuncture',
          price: '25.00',
        },
      ],
      amount: {
        total: {
          payments: '0.00',
          adjustments: '0.00',
          pcDiscount: '0.00',
          medicare: {
            billed: '0.00',
            payments: '0.00',
            denials: '0.00',
            deduction: '0.00',
          },
          medicaid: {
            billed: '0.00',
            payments: '0.00',
            deduction: '0.00',
          },
          insurance: {
            payments: '0.00',
          },
        },
        balance: {
          charges: '62.00',
          adjustments: '0.00',
          insurance: '0.00',
          patient: '0.00',
          due: '62.00',
          medicareMedicaidTotal: '0.00',
        },
      },
      ocr: {
        specimenNumber: '214330500140',
        amount: '62.00',
        checkDigit: 3,
      },
      insurance: {
        payments: null,
      },
      remit: {
        companyName: 'Laboratory Corporation of America Holdings',
        address: {
          street: 'P.O. BOX 2240',
          city: null,
          state: null,
          zip: null,
        },
        phoneNumber: '8008456167',
        web: 'www.labcorp.com/billing',
      },
      drawLocation: 'OTHER',
    },
  },
  getPatientInvoiceRequestStatus: RequestStatus.NotSent,
  generateReceiptRequestStatus: RequestStatus.NotSent,
  findInvoiceRequestStatus: RequestStatus.NotSent,
  selectedInvoices: [],
  invoiceSummary: {
    totalNumberOfInvoices: 3,
    collectionsInvoices: [
      {
        number: '67890',
        zipCode: '27215',
        balanceDue: 100,
        invoiceAmount: 100,
        billingAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
        specimenNumber: 'testSpeciman',
        dateOfService: '2020-01-01',
        invoiceDate: '2020-01-01',
        documentKey: '12345_20200101_something_3_filename.pdf',
        patientName: otherPatient.displayName,
        pid: otherPatient.id.toString(),
        physicianDetail: {
          firstName: 'Jane',
          lastName: 'Doe',
          middleName: '',
          prefix: 'Dr.',
          orgName: '',
          practiceLocationAddress: {
            line1: '3060 S. Church St.',
            line2: '',
            city: 'Burlington',
            state: 'NC',
            zipCode: '27215',
          },
        },
        totalCharges: 100,
        adjustments: 0,
        medicarePaid: 0,
        medicaidPaid: 0,
        insurancePaid: 0,
        patientPaid: 0,
        orderingPhyAccountNumber: '1234',
        patientInvoiceDetail: {
          invoiceNumber: '20056637',
          accountNumber: '32102405',
          accountNpi: '1023080454',
          taxId: '13-3757370',
          mailToBarcode: '969750501',
          participateZip: '20056',
          billCode: 'XI',
          billDate: '2022-05-24',
          billTo: {
            firstName: 'NAPP',
            lastName: 'ANITA',
            address: {
              street: '5450 MILLSTREAM RD',
              city: 'MC LEANSVILLE',
              state: 'NC',
              zip: {
                code: '27301',
              },
            },
            phone: '3366753396',
          },
          dunning: {
            number: 1,
            suffix: 'A',
            specialDunning: 'U',
            specialCategory: 'COPAY/DEDUCTIBLE/COINSURANCE',
            message: [
              'THIS BILL REPRESENTS THE COINSURANCE, DEDUCTIBLE OR COPAY AMOUNT DUE. WE UNDERSTAND',
              'OUR PATIENTS MAY BE FACING UNEXPECTED FINANCIAL HARDSHIPS. IF YOU NEED ASSISTANCE',
              'WITH YOUR BILL, PLEASE CALL THE NUMBER LISTED ON YOUR INVOICE.',
            ],
          },
          specimenNumber: '214330500140',
          specimenDate: '2022-05-23',
          patient: {
            lastName: 'ANITA',
            firstName: 'NAPP',
            dateOfBirth: '2004-03-31',
            gender: 'F',
            relationToSubscriber: '1',
          },
          physician: {
            name: 'COMMUNITY FAMILY PRACTICE',
            address: {
              street: '260 MERRIMON AVE STE 200',
              city: 'ASHEVILLE',
              state: 'NC',
              zip: {
                code: '28801',
              },
            },
          },
          lab: {
            code: 'CB',
            name: 'LA',
            brandCode: 'LCA',
            address: {
              street: '6370 WILCOX ROAD',
              city: 'DUBLIN',
              state: 'OH',
              zip: {
                code: '43016',
              },
            },
          },
          orderedTests: [
            {
              testNumber: '001818',
              testType: 'T',
            },
            {
              testNumber: '998085',
              testType: 'T',
            },
          ],
          diagnosisCodes: ['Z00.00'],
          invoiceTests: [
            {
              testName: 'Glucose, Plasma',
              price: '37.00',
            },
            {
              testName: 'Venipuncture',
              price: '25.00',
            },
          ],
          amount: {
            total: {
              payments: '0.00',
              adjustments: '0.00',
              pcDiscount: '0.00',
              medicare: {
                billed: '0.00',
                payments: '0.00',
                denials: '0.00',
                deduction: '0.00',
              },
              medicaid: {
                billed: '0.00',
                payments: '0.00',
                deduction: '0.00',
              },
              insurance: {
                payments: '0.00',
              },
            },
            balance: {
              charges: '62.00',
              adjustments: '0.00',
              insurance: '0.00',
              patient: '0.00',
              due: '62.00',
              medicareMedicaidTotal: '0.00',
            },
          },
          ocr: {
            specimenNumber: '214330500140',
            amount: '62.00',
            checkDigit: 3,
          },
          insurance: {
            payments: null,
          },
          remit: {
            companyName: 'Laboratory Corporation of America Holdings',
            address: {
              street: 'P.O. BOX 2240',
              city: null,
              state: null,
              zip: null,
            },
            phoneNumber: '8008456167',
            web: 'www.labcorp.com/billing',
          },
          drawLocation: 'OTHER',
        },
      },
    ],
    currentInvoices: [
      {
        number: '12345',
        zipCode: '27215',
        balanceDue: 100,
        invoiceAmount: 100,
        billingAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
        specimenNumber: 'testSpeciman',
        dateOfService: '2020-01-01',
        invoiceDate: '2020-01-01',
        documentKey: '12345_20200101_something_1_filename.pdf',
        patientName: otherPatient.displayName,
        pid: otherPatient.id.toString(),
        physicianDetail: {
          firstName: 'Jane',
          lastName: 'Doe',
          middleName: '',
          prefix: 'Dr.',
          orgName: '',
          practiceLocationAddress: {
            line1: '3060 S. Church St.',
            line2: '',
            city: 'Burlington',
            state: 'NC',
            zipCode: '27215',
          },
        },
        totalCharges: 100,
        adjustments: 0,
        medicarePaid: 0,
        medicaidPaid: 0,
        insurancePaid: 0,
        patientPaid: 0,
        orderingPhyAccountNumber: '1234',
        patientInvoiceDetail: {
          invoiceNumber: '20056637',
          accountNumber: '32102405',
          accountNpi: '1023080454',
          taxId: '13-3757370',
          mailToBarcode: '969750501',
          participateZip: '20056',
          billCode: 'XI',
          billDate: '2022-05-24',
          billTo: {
            firstName: 'NAPP',
            lastName: 'ANITA',
            address: {
              street: '5450 MILLSTREAM RD',
              city: 'MC LEANSVILLE',
              state: 'NC',
              zip: {
                code: '27301',
              },
            },
            phone: '3366753396',
          },
          dunning: {
            number: 1,
            suffix: 'A',
            specialDunning: 'U',
            specialCategory: 'COPAY/DEDUCTIBLE/COINSURANCE',
            message: [
              'THIS BILL REPRESENTS THE COINSURANCE, DEDUCTIBLE OR COPAY AMOUNT DUE. WE UNDERSTAND',
              'OUR PATIENTS MAY BE FACING UNEXPECTED FINANCIAL HARDSHIPS. IF YOU NEED ASSISTANCE',
              'WITH YOUR BILL, PLEASE CALL THE NUMBER LISTED ON YOUR INVOICE.',
            ],
          },
          specimenNumber: '214330500140',
          specimenDate: '2022-05-23',
          patient: {
            lastName: 'ANITA',
            firstName: 'NAPP',
            dateOfBirth: '2004-03-31',
            gender: 'F',
            relationToSubscriber: '1',
          },
          physician: {
            name: 'COMMUNITY FAMILY PRACTICE',
            address: {
              street: '260 MERRIMON AVE STE 200',
              city: 'ASHEVILLE',
              state: 'NC',
              zip: {
                code: '28801',
              },
            },
          },
          lab: {
            code: 'CB',
            name: 'LA',
            brandCode: 'LCA',
            address: {
              street: '6370 WILCOX ROAD',
              city: 'DUBLIN',
              state: 'OH',
              zip: {
                code: '43016',
              },
            },
          },
          orderedTests: [
            {
              testNumber: '001818',
              testType: 'T',
            },
            {
              testNumber: '998085',
              testType: 'T',
            },
          ],
          diagnosisCodes: ['Z00.00'],
          invoiceTests: [
            {
              testName: 'Glucose, Plasma',
              price: '37.00',
            },
            {
              testName: 'Venipuncture',
              price: '25.00',
            },
          ],
          amount: {
            total: {
              payments: '0.00',
              adjustments: '0.00',
              pcDiscount: '0.00',
              medicare: {
                billed: '0.00',
                payments: '0.00',
                denials: '0.00',
                deduction: '0.00',
              },
              medicaid: {
                billed: '0.00',
                payments: '0.00',
                deduction: '0.00',
              },
              insurance: {
                payments: '0.00',
              },
            },
            balance: {
              charges: '62.00',
              adjustments: '0.00',
              insurance: '0.00',
              patient: '0.00',
              due: '62.00',
              medicareMedicaidTotal: '0.00',
            },
          },
          ocr: {
            specimenNumber: '214330500140',
            amount: '62.00',
            checkDigit: 3,
          },
          insurance: {
            payments: null,
          },
          remit: {
            companyName: 'Laboratory Corporation of America Holdings',
            address: {
              street: 'P.O. BOX 2240',
              city: null,
              state: null,
              zip: null,
            },
            phoneNumber: '8008456167',
            web: 'www.labcorp.com/billing',
          },
          drawLocation: 'OTHER',
        },
      },
    ],
    pastDueInvoices: [
      {
        number: '234567',
        zipCode: '27215',
        balanceDue: 100,
        invoiceAmount: 100,
        billingAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
        specimenNumber: 'testSpeciman',
        dateOfService: '2020-01-01',
        invoiceDate: '2020-01-01',
        documentKey: '12345_20200101_something_2_filename.pdf',
        patientName: currentPatient.displayName,
        pid: currentPatient.id.toString(),
        physicianDetail: {
          firstName: 'Jane',
          lastName: 'Doe',
          middleName: '',
          prefix: 'Dr.',
          orgName: '',
          practiceLocationAddress: {
            line1: '3060 S. Church St.',
            line2: '',
            city: 'Burlington',
            state: 'NC',
            zipCode: '27215',
          },
        },
        totalCharges: 100,
        adjustments: 0,
        medicarePaid: 0,
        medicaidPaid: 0,
        insurancePaid: 0,
        patientPaid: 0,
        orderingPhyAccountNumber: '1234',
        patientInvoiceDetail: {
          invoiceNumber: '20056637',
          accountNumber: '32102405',
          accountNpi: '1023080454',
          taxId: '13-3757370',
          mailToBarcode: '969750501',
          participateZip: '20056',
          billCode: 'XI',
          billDate: '2022-05-24',
          billTo: {
            firstName: 'NAPP',
            lastName: 'ANITA',
            address: {
              street: '5450 MILLSTREAM RD',
              city: 'MC LEANSVILLE',
              state: 'NC',
              zip: {
                code: '27301',
              },
            },
            phone: '3366753396',
          },
          dunning: {
            number: 1,
            suffix: 'A',
            specialDunning: 'U',
            specialCategory: 'COPAY/DEDUCTIBLE/COINSURANCE',
            message: [
              'THIS BILL REPRESENTS THE COINSURANCE, DEDUCTIBLE OR COPAY AMOUNT DUE. WE UNDERSTAND',
              'OUR PATIENTS MAY BE FACING UNEXPECTED FINANCIAL HARDSHIPS. IF YOU NEED ASSISTANCE',
              'WITH YOUR BILL, PLEASE CALL THE NUMBER LISTED ON YOUR INVOICE.',
            ],
          },
          specimenNumber: '214330500140',
          specimenDate: '2022-05-23',
          patient: {
            lastName: 'ANITA',
            firstName: 'NAPP',
            dateOfBirth: '2004-03-31',
            gender: 'F',
            relationToSubscriber: '1',
          },
          physician: {
            name: 'COMMUNITY FAMILY PRACTICE',
            address: {
              street: '260 MERRIMON AVE STE 200',
              city: 'ASHEVILLE',
              state: 'NC',
              zip: {
                code: '28801',
              },
            },
          },
          lab: {
            code: 'CB',
            name: 'LA',
            brandCode: 'LCA',
            address: {
              street: '6370 WILCOX ROAD',
              city: 'DUBLIN',
              state: 'OH',
              zip: {
                code: '43016',
              },
            },
          },
          orderedTests: [
            {
              testNumber: '001818',
              testType: 'T',
            },
            {
              testNumber: '998085',
              testType: 'T',
            },
          ],
          diagnosisCodes: ['Z00.00'],
          invoiceTests: [
            {
              testName: 'Glucose, Plasma',
              price: '37.00',
            },
            {
              testName: 'Venipuncture',
              price: '25.00',
            },
          ],
          amount: {
            total: {
              payments: '0.00',
              adjustments: '0.00',
              pcDiscount: '0.00',
              medicare: {
                billed: '0.00',
                payments: '0.00',
                denials: '0.00',
                deduction: '0.00',
              },
              medicaid: {
                billed: '0.00',
                payments: '0.00',
                deduction: '0.00',
              },
              insurance: {
                payments: '0.00',
              },
            },
            balance: {
              charges: '62.00',
              adjustments: '0.00',
              insurance: '0.00',
              patient: '0.00',
              due: '62.00',
              medicareMedicaidTotal: '0.00',
            },
          },
          ocr: {
            specimenNumber: '214330500140',
            amount: '62.00',
            checkDigit: 3,
          },
          insurance: {
            payments: null,
          },
          remit: {
            companyName: 'Laboratory Corporation of America Holdings',
            address: {
              street: 'P.O. BOX 2240',
              city: null,
              state: null,
              zip: null,
            },
            phoneNumber: '8008456167',
            web: 'www.labcorp.com/billing',
          },
          drawLocation: 'OTHER',
        },
      },
    ],
    paymentHistory: [],
  },
  invoicePaymentsFilteredByPatient: [],
  invoicePaymentsAllPatients: [],
  serviceUnavailable: false,
  getInvoiceRequestStatus: RequestStatus.NotSent,
  updateInsuranceRequestStatus: RequestStatus.NotSent,
  updateInsuranceRequestResponse: {
    code: '',
    description: '',
  },
  queryForAccessionedOrderRequestStatus: RequestStatus.NotSent,
  queryForAccessionedOrderResponse: null,
  queryForAccessionedOrderErrorResponse: null,
  getProviderListRequestStatus: RequestStatus.NotSent,
  getInvoiceSummaryRequestStatus: RequestStatus.NotSent,
  providerList: [],
  processingInvoices: [],
  getProcessingInvoicesStatus: RequestStatus.NotSent,
  paymentAuthorizationRequest: {
    accountId: '',
    paymentAccountId: '',
    paymentMethod: PaymentType.CREDIT,
    paymentDate: '',
    firstName: '',
    lastName: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    invoiceList: [],
    emailAddress: '',
    isGuestUser: true,
    browser: '',
    mobile: false,
  },
  paymentAuthorizationResponse: {
    successful: false,
    encryptedLpid: null,
    encryptedTransactionId: '',
    transactionId: '',
    reasonCode: '',
    optedIn: false,
  },
  paymentPlanAuthorizationRequest: {
    accountBalance: 0,
    patientEmailAddress: '',
    planFrequency: '',
    paymentSource: '',
    billType: '',
    invoiceDetails: [
      {
        balance: 0,
        lcaInvoiceNumber: '',
        specimenNumber: '',
        dateOfService: '',
      },
    ],
    frequencyCount: 0,
    amountPerPayment: 0,
    paymentDetails: {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      cityName: '',
      stateCode: '',
      postalCode: '',
      paymentType: '',
      accountId: '',
      expirationYear: 0,
      expirationMonth: 0,
      token: '',
    },
  },
  paymentPlanAuthorizationResponse: {
    paymentPlanId: '',
    reasonCode: '',
    reasonDescription: '',
    warnings: [],
    transactionId: '',
    invoiceDetails: [],
    accountBalance: 0,
    successful: null,
    encryptedLpid: '',
    encryptedTransactionId: '',
  },
  getPaymentAuthorizationStatus: RequestStatus.NotSent,
  paymentAuthorizationErrors: undefined,
  paymentAuthorizationTimeout: false,
  payments: [],
  paymentAuthorizationReceipt: {
    isSuccessful: false,
    transactionId: '',
  },
  getPaymentAuthorizationReceiptStatus: RequestStatus.NotSent,
  getInvoicePdfRequestStatus: RequestStatus.NotSent,
  invoicePdf: undefined,
  tokenDetails: {
    token: '',
    paymentType: 'CARD',
    debitLast4: '',
    expirationDate: '',
    cardType: '',
    cardBrand: '',
    accountType: '',
    routingNumber: '',
  },
  isNewPaymentMethod: undefined,
  selectDuplicateInvoice: false,
  applePaySupportFlag: null,
  applePaySupportFlagRequestStatus: RequestStatus.NotSent,
  applePaySupportFlagRequestError: null,
  selectedInvoiceList: [],
  paymentPlanLoading: false,
  createPaymentPlan: {},
  createPaymentPlanError: false,
  email2TextPaymentPlanLink: false,
};

export const FILTERED_INVOICES = {
  currentInvoices: [
    {
      number: '1234566',
      zipCode: '27215',
      balanceDue: 99,
      invoiceAmount: 100,
      billingAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
      specimenNumber: 'testSpeciman',
      dateOfService: '2019-12-31',
      invoiceDate: '2020-01-01',
      documentKey: '12345_20200101_something_2_filename.pdf',
      patientName: 'name one',
      pid: '1111',
      physicianDetail: {
        firstName: 'Jane',
        lastName: 'Doe',
        middleName: '',
        prefix: 'Dr.',
        orgName: '',
        practiceLocationAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
      },
      totalCharges: 100,
      adjustments: 0,
      medicarePaid: 0,
      medicaidPaid: 0,
      insurancePaid: 0,
      patientPaid: 0,
    },
    {
      number: '12345672',
      zipCode: '27215',
      balanceDue: 99,
      invoiceAmount: 100,
      billingAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
      specimenNumber: 'testSpeciman',
      dateOfService: '2019-12-31',
      invoiceDate: '2020-01-01',
      documentKey: '12345_20200101_something_1_filename.pdf',
      patientName: 'name two',
      pid: '2222',
      physicianDetail: {
        firstName: 'Jane',
        lastName: 'Doe',
        middleName: '',
        prefix: 'Dr.',
        orgName: '',
        practiceLocationAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
      },
      totalCharges: 200,
      adjustments: 0,
      medicarePaid: 0,
      medicaidPaid: 0,
      insurancePaid: 0,
      patientPaid: 0,
    },
  ],
};

export const SELECTED_INVOICES = {
  currentInvoices: [
    {
      number: '1234566',
      zipCode: '27215',
      balanceDue: 99,
      invoiceAmount: 100,
      billingAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
      specimenNumber: 'testSpeciman',
      dateOfService: '2019-12-31',
      invoiceDate: '2020-01-01',
      documentKey: '12345_20200101_something_1_filename.pdf',
      patientName: 'name one',
      pid: '1111',
      physicianDetail: {
        firstName: 'Jane',
        lastName: 'Doe',
        middleName: '',
        prefix: 'Dr.',
        orgName: '',
        practiceLocationAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
      },
      totalCharges: 100,
      adjustments: 0,
      medicarePaid: 0,
      medicaidPaid: 0,
      insurancePaid: 0,
      patientPaid: 0,
    },
    {
      number: '12345672',
      zipCode: '27215',
      balanceDue: 99,
      invoiceAmount: 100,
      billingAddress: {
        line1: '3060 S. Church St.',
        line2: '',
        city: 'Burlington',
        state: 'NC',
        zipCode: '27215',
      },
      specimenNumber: 'testSpeciman',
      dateOfService: '2019-12-31',
      invoiceDate: '2020-01-01',
      documentKey: '12345_20200101_something_1_filename.pdf',
      patientName: 'name two',
      pid: '2222',
      physicianDetail: {
        firstName: 'Jane',
        lastName: 'Doe',
        middleName: '',
        prefix: 'Dr.',
        orgName: '',
        practiceLocationAddress: {
          line1: '3060 S. Church St.',
          line2: '',
          city: 'Burlington',
          state: 'NC',
          zipCode: '27215',
        },
      },
      totalCharges: 200,
      adjustments: 0,
      medicarePaid: 0,
      medicaidPaid: 0,
      insurancePaid: 0,
      patientPaid: 0,
    },
  ],
};
