import { Component, OnInit } from '@angular/core';
import WebFont from 'webfontloader';

import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import * as supportedBrowsers from './supportedBrowsers';

@Component({
  selector: 'patient-ui-root',
  template: ` <router-outlet></router-outlet> `,
})
export class AppComponent implements OnInit {
  constructor(private readonly envService: EnvironmentService) {}

  ngOnInit(): void {
    WebFont.load({
      custom: { families: ['Material Icons', 'Material Icons Outline'] },
    });
    if (!this.envService.isProduction) {
      this.printEnvData();
    }

    if (!supportedBrowsers.test(navigator.userAgent)) {
      alert(
        'Your browser version is not supported. Please update ' +
          'your browser to the latest version.'
      );
    }

    this.uaToLocalStorage();
  }

  uaToLocalStorage(): void {
    localStorage.setItem('requestUserAgent', window.navigator.userAgent);
  }

  printEnvData(): void {
    console.log('Environment:', this.envService.environmentName);
  }
}
