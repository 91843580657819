import { createAction, props } from '@ngrx/store';

import {
  PortalUser,
  Report,
  ReportHeader,
  ReportHeaderSummary,
} from '@patient-ui/shared/models';

export const loadResults = createAction('[Results] Load Results');

export const loadResultsSuccess = createAction(
  '[Results] Load Results Success',
  props<{ results: ReportHeaderSummary }>()
);

export const loadResultsFailure = createAction(
  '[Results] Load Results Payload Failure',
  props<{ error: string }>()
);

export const loadResultHeaders = createAction('[Results] Load Result Headers');

export const loadResultHeadersSuccess = createAction(
  '[Results] Get Result Headers Success',
  props<{ resultHeaders: ReportHeader[] }>()
);

export const loadResultHeadersFailure = createAction(
  '[Results] Load Result Headers Failure',
  props<{ error: string }>()
);

export const getResultPdf = createAction(
  '[Results] Get Result Pdf',
  props<{ resultId: string; patient?: PortalUser }>()
);

export const getResultPdfSuccess = createAction(
  '[Results] Get Result Pdf Success',
  props<{ payload: Blob }>()
);

export const getResultPdfFailure = createAction(
  '[Results] Load Result Pdf Failure',
  props<{ error: string }>()
);

export const clearResultPdf = createAction('[Results] Clear Result Pdf');

export const getResultDetail = createAction(
  '[Results] Get Result Detail',
  props<{ resultId: string; patient?: PortalUser }>()
);

export const getResultDetailSuccess = createAction(
  '[Results] Get Result Detail Success',
  props<{ report: Report }>()
);

export const setResultHistorySelectedUser = createAction(
  '[Results] Set Result History Selected User',
  props<{ selectedUser: PortalUser }>()
);

export const getResultDetailFailure = createAction(
  '[Results] Load Result Detail Failure',
  props<{ error: string }>()
);

export const clearResults = createAction('[Results] Clear Results');

export const ResultActions = {
  loadResults,
  loadResultsSuccess,
  loadResultsFailure,
  loadResultHeaders,
  loadResultHeadersSuccess,
  loadResultHeadersFailure,
  getResultPdf,
  getResultPdfSuccess,
  getResultPdfFailure,
  getResultDetail,
  getResultDetailSuccess,
  getResultDetailFailure,
  setResultHistorySelectedUser,
  clearResultPdf,
  clearResults,
};
