<div #modal class="modal-header" role="dialog" aria-labelledby="modalTitle" tabindex="-1">
  <h2 ngbAutofocus class="modal-title flex-fill text-center">
    Turn on MFA
  </h2>
  <button id="closeModal" type="button" class="btn close" aria-label="Close" (click)="routeToSignIn()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="modal-body">
  <div class="custom-modal-body">
    <p>
      Your existing account does not have multifactor authentication (MFA) enabled.
    </p>
    <p class="mb-0">
      Your privacy and security are very important. To continue, you will need to sign in to your existing account and enable MFA.
    </p>
  </div>
</div>
<div class="modal-footer justify-content-right">
  <button id="btnTurnOnMFACancel" type="button" class="btn btn-secondary" (click)="routeToSignIn()">
    Cancel
  </button>
  <button id="btnTurnOnMFASignIn" type="button" class="btn btn-primary" (click)="login()">
    Sign In
  </button>
</div>
