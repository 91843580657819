<div class="modal-body pb-3">
  <div class="custom-modal-body">
    <h3>Confirm your identity</h3>
    <p>
      To get started, enter the password for <a class="link">{{ userEmail }}.</a>
    </p>
    <form [formGroup]="passwordConfirm" novalidate>
      <div class="pt-3 d-flex flex-row flex-wrap">
        <div class="flex-grow-1 col-lg-5 pr-0">
          <input type="password" class="form-control" placeholder="Enter password" formControlName="password" id="password" />
          <small class="form-text invalid-feedback" [ngClass]="invalidPassword ? 'd-block' : 'd-none'">
            <i class="material-icons icon-sm">error_outline</i>
            <span class="align-self-center pl-1" [innerHTML]="'Incorrect password. Please try again.'"></span>
          </small>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer justify-content-start">
  <button id="passwordConfirmationMFA" type="button" class="btn btn-primary" [disabled]="isLoading" (click)="onSubmit()">
    Submit
  </button>
</div>
