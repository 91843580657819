import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() addClass!: string;
  @Input() buttonText!: string;
  @Input() rank: 'primary' | 'secondary' | 'light' | 'link' = 'primary';
  @Input() alertStatus?: 'success' | 'danger' | 'warning' | 'info';
  @Input() size?: 'small' | 'large';
  @Input() buttonType: 'button' | 'submit' = 'button';
  @Input() buttonDisabled = false;
  @Input() minWidth = 0;
  @Input() iconLeft?: 'add_circle_outline' | string;
  @Input() inProgress = false;
  @Input() link?: string;
  @Input() useRouterLink = false;
  @Input() fullWidth = false;
  @Output() handleClick: EventEmitter<void> = new EventEmitter();

  onClick() {
    if (!this.buttonDisabled) {
      this.handleClick.emit();
    }
  }
}
