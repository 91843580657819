import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';

import { ProfileState, profileFeatureKey } from './profile.reducer';

export const selectProfileStateSelector = createFeatureSelector<ProfileState>(
  profileFeatureKey
);

export const selectDeletePatientAccountStatus = createSelector(
  selectProfileStateSelector,
  (state) => state.deletePatientAccountRequestStatus
);

export const selectSectionInEditMode = createSelector(
  selectProfileStateSelector,
  (state) => state.sectionInEditMode
);

export const selectValidatePhoneRequestStatus = createSelector(
  selectProfileStateSelector,
  (state) => {
    if (state.validatePhoneRequestStatus === RequestStatus.Success) {
      const responseDescription = {
        responseStatus: RequestStatus.Success,
        phoneNumber: state.validatePhoneRequestResponse?.phoneNumber,
        type: state.validatePhoneRequestResponse?.type,
        ext: state.validatePhoneRequestResponse?.ext,
        status: state.validatePhoneRequestResponse?.status,
      };
      return responseDescription;
    } else {
      const responseDescription = {
        responseStatus: state.validatePhoneRequestStatus,
        phoneNumber: undefined,
        type: undefined,
        ext: undefined,
        status: undefined,
      };
      return responseDescription;
    }
  }
);

export const profileQuery = {
  selectProfileStateSelector,
  selectDeletePatientAccountStatus,
  selectSectionInEditMode,
  selectValidatePhoneRequestStatus,
};
