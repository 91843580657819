import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  FastRegistrationInput,
  FastRegistrationSubmitRequest,
  FastRegistrationVerifyResponse,
} from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class FastRegistrationService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  verifyFastRegistrationInputs(
    fastRegistrationInput: FastRegistrationInput
  ): Observable<FastRegistrationVerifyResponse> {
    const FastRegistrationVerifyUrl = `${this.envService.baseUrl}/guest/guest/fastregister-verify`;
    return this.http.post<FastRegistrationVerifyResponse>(
      FastRegistrationVerifyUrl,
      fastRegistrationInput
    );
  }

  submitFastRegistrationInputs(
    fastRegistrationInput: FastRegistrationSubmitRequest
  ): Observable<FastRegistrationVerifyResponse> {
    const FastRegistrationSubmitUrl = `${this.envService.baseUrl}/guest/guest/fastregister-submit`;
    return this.http.post<FastRegistrationVerifyResponse>(
      FastRegistrationSubmitUrl,
      fastRegistrationInput
    );
  }
}
