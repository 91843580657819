import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * custom generic validator that can conditionally return the given validator based on the given
 * predicate function. Inspired by
 * https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325.
 * @param predicate boolean function
 * @param validator the conditional validator you want to be applied
 */
export function conditionalValidator(
  predicate: () => boolean,
  validator: ValidatorFn
): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    if (!formControl.parent) {
      return null;
    }

    if (predicate()) {
      return validator(formControl);
    }

    return null;
  };
}
