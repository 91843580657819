export function numericRange(
  start: number,
  count: number,
  startingIndex?: number
): number[] {
  startingIndex = startingIndex || 0;
  return Array.apply(startingIndex, Array(count)).map(
    (_element, index) => index + start
  );
}
