/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
  ActivePaymentsResponse,
  IAddress,
  IAppointment,
  IDependentRegistration,
  INotificationPreferences,
  IPatient,
  IPatientDemographicsUpdate,
  IPhone,
  IRequestError,
  IUpdatePassword,
  NotificationPreference,
  Patient,
  PaymentMethod,
  PortalUser,
  WalletDelete,
  WalletDeleteResponse,
  WalletPayment,
  WalletPaymentResponse,
} from '@patient-ui/shared/models';

export const loadLoggedInPatient = createAction(
  '[Patient] Load Patient',
  props<{ payload: any }>()
);

export const loadLoggedInPatientSuccess = createAction(
  '[Patient] Load Patient Success',
  props<{ payload: any }>()
);

export const loadLoggedInPatientFailure = createAction(
  '[Patient] Load Logged In Patient Failure'
);

export const loadPatientUserList = createAction(
  '[Patient] Load Patient User List',
  props<{ userList: PortalUser[] }>()
);

export const loadPatientUserListFailure = createAction(
  '[Patient] Load Patient User List Failure'
);

export const clearLoggedInPatient = createAction(
  '[Patient] Clear Logged In Patient'
);

export const loadPatientAppointments = createAction(
  '[Patient] Load Patient Appointments'
);

export const loadPatientAppointmentsSuccess = createAction(
  '[Patinet] Load Patient Appointments Success',
  props<{ appointments: IAppointment[] }>()
);

export const loadPatientAppointmentsFailure = createAction(
  '[Patient] Load Patient Appointments Failure',
  props<{ error: any }>()
);

export const getEncryptedLpid = createAction(
  '[Patient] Get Encrypted Lpid',
  props<{ patientId: number }>()
);

export const getEncryptedLpidSuccess = createAction(
  '[Patient] Get Encrypted Lpid Success',
  props<{ encryptedLpid: string }>()
);

export const getEncryptedLpidFailure = createAction(
  '[Patient] Get Encrypted Lpid Failure',
  props<{ error: any }>()
);

export const selectPatientAppointmentsDropdownUser = createAction(
  '[Patient] Select Patient Appointments Dropdown User',
  props<{ user: PortalUser }>()
);

export const resetPatientAppointmentsState = createAction(
  '[Patient] Reset Patient Appointments State'
);

export const upadatePatientProfileSuccess = createAction(
  '[Profile] Update Selected Patient Profile Success',
  props<{ patient: Patient }>()
);

export const upadatePatientProfileFailure = createAction(
  '[Profile] Update Selected Patient Profile Failure'
);

export const updatePatientAddress = createAction(
  '[Profile] Update Patient Address',
  props<{
    patient: IPatient;
    addressId: number;
    addressAction: string;
    address?: IAddress;
  }>()
);

export const updatePatientAfterProfileChange = createAction(
  '[Profile] Update Patient After Profile Change',
  props<{ updatedPatient: Patient }>()
);

export const updatePassword = createAction(
  '[Profile] Update Password',
  props<{ passwords: IUpdatePassword }>()
);

export const updatePasswordSuccess = createAction(
  '[Profile] Update Password Success'
);

export const updatePasswordFailure = createAction(
  '[Profile] Update Password In Patient Failure',
  props<{ errorResponse: any }>()
);

export const clearUpdatePassword = createAction(
  '[Profile] Clear Update Password'
);

export const createOrUpdateAddress = createAction(
  '[Profile] Create or Update Address',
  props<{
    addressOwner: IPatient;
    dependentId?: number;
    addressToUpdate: IAddress;
    addressAction: string;
    switchToPrimary?: boolean;
    usePrimaryAddressOfPrimaryPatient?: boolean;
  }>()
);

export const createOrUpdateOrDeleteAddressSuccess = createAction(
  '[Profile] Create or Update Address Success',
  props<{ response: any }>()
);

export const createOrUpdateOrDeleteAddressFailure = createAction(
  '[Profile] Create or Update Address Failure',
  props<{ errorResponse: any }>()
);

export const deleteAddress = createAction(
  '[Profile] Delete Address',
  props<{
    addressOwner: IPatient;
    addressToDelete: IAddress;
    addressAction: string;
  }>()
);
export const resetAddressChanges = createAction(
  '[Profile] Reset Address Changes'
);

export const createOrUpdatePhone = createAction(
  '[Profile] Create or Update Phone',
  props<{
    phoneOwner: IPatient;
    phoneToUpdate: IPhone;
    phoneAction: string;
    dependentId?: number;
    switchToPrimary?: boolean;
  }>()
);

export const createOrUpdateOrDeletePhoneSuccess = createAction(
  '[Profile] Create or Update Phone Success',
  props<{ response: any }>()
);

export const createOrUpdateOrDeletePhoneFailure = createAction(
  '[Profile] Create or Update Phone Failure',
  props<{ errorResponse: any }>()
);

export const resetPhoneChanges = createAction('[Profile] Reset Phone Changes');

export const deletePhone = createAction(
  '[Profile] Delete Phone',
  props<{
    phoneOwner: IPatient;
    phoneToDelete: IPhone;
    phoneAction: string;
  }>()
);
export const updateAuthenticationQuizStatus = createAction(
  '[LexisNexis] Update Authentication Quiz Status Success',
  props<{
    updatedPatient: Patient;
    primaryAccountHolder: Patient;
    dependents: Patient[];
    isDependent: boolean;
  }>()
);

export const createDependent = createAction(
  '[Profile] Create Dependent',
  props<{ dependentRegistration: IDependentRegistration }>()
);

export const createDependentSucess = createAction(
  '[Profile] Create Dependent Success',
  props<{ dependent: Patient }>()
);

export const createDependentFailure = createAction(
  '[Profile] Create Dependent Failure',
  props<{
    errorResponse: HttpErrorResponse | HttpResponse<any>;
    dependentRegistration: IDependentRegistration;
  }>()
);

export const clearCreateDependentState = createAction(
  '[Profile] Clear Create Dependent State'
);

export const deleteDependent = createAction(
  '[Profile] Delete Dependent',
  props<{ depId: string }>()
);

export const deleteDependentSuccess = createAction(
  '[Profile] Delete Dependent Success',
  props<{ depId: string }>()
);

export const deleteDependentFailure = createAction(
  '[Profile] Delete Dependent Failure'
);
export const updatePatientDemographics = createAction(
  '[Profile] Update Patient Demographics',
  props<{
    patientDemographicsOwner: IPatient;
    patientDemographics: IPatientDemographicsUpdate;
    dependentId?: number;
  }>()
);

export const updatePatientDemographicsSuccess = createAction(
  '[Profile] Update Patient Demographics Success',
  props<{ response: NotificationPreference }>()
);

export const updatePatientDemographicsFailure = createAction(
  '[Profile] Update Patient Demographics Failure',
  props<{ errorResponse: any }>()
);

export const updatePatientNotifications = createAction(
  '[Profile] Update Patient Notifications',
  props<{
    patientNotificationsOwner: IPatient;
    patientNotifications: INotificationPreferences;
    dependentId?: number;
  }>()
);

export const updatePatientNotificationsSuccess = createAction(
  '[Profile] Update Patient Notifications Success',
  props<{ response: any }>()
);

export const updatePatientNotificationsFailure = createAction(
  '[Profile] Update Patient Notifications Failure',
  props<{ errorResponse: IRequestError }>()
);

export const setPatientPAHEmailVerified = createAction(
  '[Patient] Set PAH Email Verified'
);

export const addPaymentMethod = createAction(
  `[Patient] Add Payment Method`,
  props<{ payload: WalletPayment }>()
);

export const addPaymentMethodFailure = createAction(
  `[Patient] Add Payment Method Failure`
);

export const addPaymentMethodFlag = createAction(
  `[Patient] Add Payment Method Flag`,
  props<{ payload: boolean }>()
);

export const addPaymentMethodSuccess = createAction(
  `[Patient] Add Payment Method Success`,
  props<{ payload: WalletPaymentResponse }>()
);

export const clearDeletePaymentMethodError = createAction(
  `[Patient] Clear Delete Payment Method Error`
);

export const clearPatientWallet = createAction(
  `[Patient] Clear Patient Wallet`
);

export const deletePaymentMethod = createAction(
  `[Patient] Delete Payment Method`,
  props<{ payload: WalletDelete }>()
);

export const deletePaymentMethodFailure = createAction(
  `[Patient] Delete Payment Method Failure`,
  props<{ payload: WalletDeleteResponse }>()
);

export const deletePaymentMethodSuccess = createAction(
  `[Patient] Delete Payment Method Success`,
  props<{ payload: WalletDeleteResponse }>()
);

export const getPatientWallet = createAction(`[Patient] Get Patient Wallet`);

export const getPatientWalletFailure = createAction(
  `[Patient] Get Patient Wallet Failure`
);

export const getPatientWalletSuccess = createAction(
  `[Patient] Get Patient Wallet Success`,
  props<{ payload: PaymentMethod[] }>()
);

export const setClinicalTrialsPreference = createAction(
  '[Patient] Set Clinical Trials Preference',
  props<{ payload: string }>()
);

export const setClinicalTrialsPreferenceSuccess = createAction(
  '[Patient] Set Clinical Trials Preference Success',
  props<{ payload: string }>()
);

export const setClinicalTrialsPreferenceFailure = createAction(
  '[Patient] Set Clinical Trials Preference Failure'
);

export const selectOpenInvoiceUser = createAction(
  `[Patient] Select Open Invoice User`,
  props<{ payload: PortalUser }>()
);

export const selectPaymentHistoryUser = createAction(
  `[Patient] Select Payment History User`,
  props<{ payload: PortalUser }>()
);

export const selectSelfOrdersUser = createAction(
  `[Patient] Select Self Orders User`,
  props<{ payload: PortalUser }>()
);

export const updatePatientUserList = createAction(
  '[Patient] Update Patient Users List',
  props<{ payload: PortalUser[] }>()
);

export const updateAuthQuizStatus = createAction(
  '[Patient] Update Patient Auth quiz Status',
  props<{ updatedPatient: Patient }>()
);

export const resetPatientState = createAction('[Patient] Reset State');

export const populateMobile = createAction(
  '[Patient] Populate mobile navigation',
  props<{ payload: boolean }>()
);

export const retrieveActivePayments = createAction(
  `[Patient] Retrieve Active Payment Plans`
);

export const retrieveActivePaymentsSuccess = createAction(
  '[Patient] Retrieve Active Payment Plans Success',
  props<{ activePayments: ActivePaymentsResponse[] }>()
);

export const retrieveActivePaymentsFailure = createAction(
  '[Patient] Retrieve Active Payment Plans Failure',
  props<{ error: any }>()
);
