export const races = [
  {
    display: 'American Indian or Alaska Native',
    value: 'AMERICAN_INDIAN',
  },
  {
    display: 'Asian',
    value: 'ASIAN',
  },
  {
    display: 'Black or African American',
    value: 'BLACK',
  },
  {
    display: 'Native Hawaiian or Other Pacific Islander',
    value: 'NATIVE_HAWAIIAN',
  },
  {
    display: 'White',
    value: 'WHITE',
  },
  {
    display: 'I prefer not to say',
    value: 'UNSPECIFIED',
  },
];

export const ethnicities = [
  {
    display: 'Hispanic or Latino',
    value: 'HISPANIC',
  },
  {
    display: 'Not Hispanic or Latino',
    value: 'NON_HISPANIC',
  },
  {
    display: 'I prefer not to say',
    value: 'UNSPECIFIED',
  },
];
