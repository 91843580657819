export const relationshipTypes = [
  {
    display: 'Adult',
    value: 'adult',
  },
  {
    display: 'Child',
    value: 'child',
  },
  {
    display: 'Daughter',
    value: 'daughter',
  },
  {
    display: 'Domestic Partner',
    value: 'domestic_partner',
  },
  {
    display: 'Father',
    value: 'father',
  },
  {
    display: 'Minor',
    value: 'minor',
  },
  {
    display: 'Mother',
    value: 'mother',
  },
  {
    display: 'Other',
    value: 'other',
  },
  {
    display: 'Parent',
    value: 'parent',
  },
  {
    display: 'Relative',
    value: 'relative',
  },
  {
    display: 'Son',
    value: 'son',
  },
  {
    display: 'Spouse',
    value: 'spouse',
  },
];
