import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import {
  Report,
  ReportHeader,
  Test,
  TestResult,
} from '@patient-ui/shared/models';

import { ResultActions } from './results.actions';
import { ResultsService } from './results.service';

@Injectable()
export class ResultsEffects {
  constructor(
    private actions$: Actions,
    private resultsService: ResultsService
  ) {}

  loadResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultActions.loadResults),
      exhaustMap(() =>
        this.resultsService.getDashboardResults().pipe(
          map((results) => {
            if (results.new.length > 0) {
              results.new.forEach((newResult: ReportHeader) => {
                if (
                  newResult.dateOfService &&
                  newResult.dateOfService.slice(-1) === 'Z'
                ) {
                  const timestr = newResult.dateOfService.slice(0, -1);
                  newResult.dateOfService = timestr;
                }
              });
            }

            if (results.updated.length > 0) {
              results.updated.forEach((updateResult: ReportHeader) => {
                if (
                  updateResult.dateOfService &&
                  updateResult.dateOfService.slice(-1) === 'Z'
                ) {
                  const timestr = updateResult.dateOfService.slice(0, -1);
                  updateResult.dateOfService = timestr;
                }
              });
            }

            if (results.upcoming.length > 0) {
              results.upcoming.forEach((upcomingResult: ReportHeader) => {
                if (
                  upcomingResult.dateOfService &&
                  upcomingResult.dateOfService.slice(-1) === 'Z'
                ) {
                  const timestr = upcomingResult.dateOfService.slice(0, -1);
                  upcomingResult.dateOfService = timestr;
                }
              });
            }
            results.hasResult = true;
            return ResultActions.loadResultsSuccess({ results });
          }),
          catchError((_error) =>
            of(ResultActions.loadResultsFailure({ error: _error }))
          )
        )
      )
    )
  );

  loadResultHeaders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultActions.loadResultHeaders),
      exhaustMap(() =>
        this.resultsService.getResultHeaders().pipe(
          map((responseHeaders) => {
            const resultHeaders: ReportHeader[] = [];
            for (const header of responseHeaders) {
              const newHeader = new ReportHeader(header);
              if (
                newHeader.dateOfService &&
                newHeader.dateOfService.slice(-1) === 'Z'
              ) {
                const timestr = newHeader.dateOfService.slice(0, -1);
                newHeader.dateOfService = timestr;
              }
              resultHeaders.push(newHeader);
            }
            return ResultActions.loadResultHeadersSuccess({ resultHeaders });
          }),
          catchError((_error) =>
            of(ResultActions.loadResultHeadersFailure({ error: _error }))
          )
        )
      )
    )
  );

  getResultPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultActions.getResultPdf),
      exhaustMap((action) =>
        this.resultsService.getResultPdf(action.resultId, action.patient).pipe(
          map((res) => {
            const payload = new Blob([res], { type: 'application/pdf' });
            return ResultActions.getResultPdfSuccess({ payload });
          }),
          catchError((_error) =>
            of(ResultActions.getResultPdfFailure({ error: _error }))
          )
        )
      )
    )
  );

  getResultDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultActions.getResultDetail),
      exhaustMap((action) =>
        this.resultsService
          .getResultDetail(action.resultId, action.patient)
          .pipe(
            map((res) => {
              const report = new Report(res);
              if (
                report.header.dateOfService &&
                report.header.dateOfService.slice(-1) === 'Z'
              ) {
                const timestr = report.header.dateOfService.slice(0, -1);
                report.header.dateOfService = timestr;
              }

              for (const test of res.orderedItems) {
                const testObj = new Test();
                testObj.testName = test.testName;
                testObj.testCode = test.testCode;
                testObj.summary = test.summary;
                for (const testResult of test.results) {
                  const result = new TestResult(testResult);
                  testObj.results.push(result);
                }
                report.orderedItems.push(testObj);
              }
              return ResultActions.getResultDetailSuccess({ report });
            }),
            catchError((_error) =>
              of(ResultActions.getResultDetailFailure({ error: _error }))
            )
          )
      )
    )
  );
}
