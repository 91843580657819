import { ReportHeader } from './ReportHeader.model';
import { Test } from './Test.model';

export class Report {
  header!: ReportHeader;
  orderedItems: Test[] = [];

  constructor(report: Report) {
    this.header = new ReportHeader(report.header);
  }
}
