import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

/** A set of latitude and longitude coordinates.
 *  Note that in practical terms, no more than six decimal digits are ever
 * necessary */
export interface LatLng {
  lat: number;
  lng: number;
}

/** A marker to be placed on the map */
export interface Marker {
  position: LatLng;
  color: string;
}

/**
 *  A re-usable wrapper for a Map SDK, used to display a center position and
 * some markers.
 *
 *  Implementation detail: avoid code that causes this element to load on a
 * page more than once, as most Map SDK providers charge per map load
 */
@Component({
  selector: 'ui-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges, OnInit {
  /** The center of the map */
  @Input() center!: LatLng;
  /** An array of markers to place on the map */
  @Input() markers: Marker[] = [];

  /** A marker with the given index from the input array was clicked */
  @Output() markerClicked = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {
    // The requirement to provide a center for the map avoids having the map
    // flicker from Africa (0,0 coordinates) on load
    if (!this.center) {
      throw new Error(
        '<ui-map> requires the [center] property to be specified before initialization'
      );
    }
    // TODO: Map SDK initialization goes here. Set the center and add the
    // markers during initialization
    console.log('Map SDK initialized');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.center && !changes.center.isFirstChange()) {
      // TODO: Set Map center/current location marker to new value
      console.log(
        'New map center location and marker:',
        changes.center.currentValue
      );
    }
    if (changes.markers && !changes.markers.isFirstChange()) {
      // TODO: Remove previous markers and add the new markers to the map
      console.log('Markers removed');
      console.log('New markers added:', changes.markers.currentValue);
    }
  }
}
