import { createAction, props } from '@ngrx/store';

import {
  IBadDebtMetric,
  IMfaMetric,
  IPaymentPlanMetric,
  IResultMetric,
} from '@patient-ui/shared/models';

export const logBadDebtMetric = createAction(
  '[Metric] Log Bad Debt Metric',
  props<{ metrics: IBadDebtMetric }>()
);

export const logBadDebtMetricSuccess = createAction(
  '[Metric] Log Bad Debt Metric Success'
);

export const logBadDebtMetricFailure = createAction(
  '[Metric] Log Bad Debt Metric Failure'
);
export const logMfaMetric = createAction(
  '[Metric] Log MFA Metric',
  props<{ metrics: IMfaMetric }>()
);

export const logMfaMetricSuccess = createAction(
  '[Metric] Log MFA Metric Success'
);

export const logMfaMetricFailure = createAction(
  '[Metric] Log MFA Metric Failure'
);
export const logPaymentPlanMetric = createAction(
  '[Metric] log PaymentPlan Metric',
  props<{ metrics: IPaymentPlanMetric }>()
);

export const logPaymentPlantMetricSuccess = createAction(
  '[Metric] Log Payment Plan Metric Success'
);

export const logPaymentPlantMetricFailure = createAction(
  '[Metric] Log Payment Plan Metric Failure'
);

export const logResultMetric = createAction(
  '[Metric] Log result Metric',
  props<{ metrics: IResultMetric }>()
);

export const logResultMetricSuccess = createAction(
  '[Metric] Log result Metric Success'
);

export const logResultMetricFailure = createAction(
  '[Metric] Log result Metric Failure'
);

export const MetricActions = {
  logBadDebtMetric,
  logBadDebtMetricSuccess,
  logBadDebtMetricFailure,
  logResultMetric,
  logResultMetricSuccess,
  logResultMetricFailure,
  logMfaMetric,
  logMfaMetricSuccess,
  logMfaMetricFailure,
  logPaymentPlanMetric,
  logPaymentPlantMetricSuccess,
  logPaymentPlantMetricFailure,
};
