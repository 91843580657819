import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AccessToken, RefreshToken, UserClaims } from '@okta/okta-auth-js';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import * as authActions from './auth.actions';
import { AuthService } from './auth.service';

@Injectable()
export class AuthEffects {
  authenticate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authenticate),
        exhaustMap((action) =>
          this.authService
            .login(
              action.email,
              action.password,
              action.redirect || '/portal/dashboard',
              action.context
            )
            .pipe(
              map(() => authActions.authenticateSuccess({})),
              catchError((_error) => of(authActions.authenticateFailure()))
            )
        )
      ),
    { dispatch: false }
  );

  logout = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logOut),
      exhaustMap((action) =>
        this.authService.logoutAPI().pipe(
          map((_success) =>
            authActions.signOutOkta({ redirect: action.redirect })
          ),
          catchError((_error) =>
            of(authActions.logOutFailure({ redirect: action.redirect }))
          )
        )
      )
    )
  );

  logoutOkta = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signOutOkta),
      exhaustMap((action) =>
        this.authService.logoutAllSessions().pipe(
          map((_success) =>
            authActions.logOutSuccess({ redirect: action.redirect })
          ),
          catchError((_error) =>
            of(authActions.logOutFailure({ redirect: action.redirect }))
          )
        )
      )
    )
  );

  redirectAfterLogout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logOutSuccess, authActions.logOutFailure),
        tap((action) => this.authService.redirect(action.redirect))
      ),
    { dispatch: false }
  );

  updateLoginInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updateLoginInfoSuccess),
      exhaustMap((action) =>
        this.authService.getUserInfo().pipe(
          map((_success: UserClaims) => {
            // Setting token value to space - empty string causes an error
            // We need other properties of token, such as expiration
            const curAccess = this.authService.oktaAuth.tokenManager.getTokensSync()
              .accessToken as AccessToken;
            const curRefresh = this.authService.oktaAuth.tokenManager.getTokensSync()
              .refreshToken as RefreshToken;

            this.authService.oktaAuth.tokenManager.setTokens({
              accessToken: {
                ...curAccess,
                accessToken: ' ',
              },
              refreshToken: {
                ...curRefresh,
                refreshToken: ' ',
              },
              idToken: undefined,
            });
            return authActions.authenticateSuccess({ response: _success });
          }),
          catchError((_error) =>
            of(authActions.updateLoginInfoFailure({ payload: action.payload }))
          )
        )
      )
    )
  );

  refreshSession = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.refreshSession),
      exhaustMap(() =>
        this.authService.refreshSession().pipe(
          map(() => authActions.refreshSessionSuccess()),
          catchError((_error) => of(authActions.refreshSessionFailure()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private authService: AuthService) {}
}
