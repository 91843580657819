import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PatientInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<HttpEventType.Response>> {
    const credentialsExcludedRe = /smartystreets.com/;
    if (req.url.search(credentialsExcludedRe) === -1) {
      req = req.clone({ withCredentials: true });
    }
    return next.handle(req);
  }
}
