import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { MFAService } from '@patient-ui/patient-web/store';
import {
  FactorsState,
  MFAActions,
  MFAEnrollResponse,
  PatientState,
  PhoneService,
  selectActivatingFactor,
  selectActivationError,
  selectMFALoading,
  selectPatientState,
} from '@patient-ui/patient-web/store';
import { EventCode } from '@patient-ui/shared/models';
@Component({
  selector: 'patient-ui-sms-enrollment',
  templateUrl: './sms-enrollment.component.html',
  styleUrls: ['./sms-enrollment.component.scss'],
})
export class SmsEnrollmentComponent implements OnInit {
  @Output() showEnroll: EventEmitter<boolean> = new EventEmitter<boolean>();
  destroyed = new Subject();
  data = '';
  smsEnroll!: FormGroup;
  showMobileInputScreen = true;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });
  showOTPForm = false;
  showConfirmationScreen = false;
  showFailedAttemptScreen = false;
  factorId = '';
  mfaEnrollResponse$ = this.mfaStore.select(MFAEnrollResponse);
  activationErr$ = this.mfaStore.select(selectActivationError);
  activatingFactor$ = this.mfaStore.select(selectActivatingFactor);
  mfaLoading$ = this.mfaStore.select(selectMFALoading);
  isLoading = false;
  isValid = true;
  validOTPInput = true;
  resendCount = 0;
  verificationCount = 0;
  invalidInput = false;

  constructor(
    private fb: FormBuilder,
    private mfaStore: Store<FactorsState>,
    private mfaService: MFAService,
    private patientStore: Store<PatientState>,
    private phoneService: PhoneService
  ) {}

  ngOnInit(): void {
    this.initForm();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.mfaEnrollResponse$.subscribe((data: any) => {
      this.factorId = data?.id;
    });
    combineLatest([this.mfaLoading$])
      .pipe(
        tap(([response]) => {
          this.isLoading = response;
        })
      )
      .subscribe();

    this.patientStore
      .select(selectPatientState)
      .pipe(takeUntil(this.destroyed))
      .subscribe((patientState) => {
        const patientPhone = patientState.primaryAccountHolder?.phones?.find(
          (phone) => phone.isPrimary
        )?.number as string;
        this.phoneService
          .validatePhone(patientPhone)
          .pipe(takeUntil(this.destroyed))
          .subscribe((response) => {
            if (
              response.status === 'ValidNumber' &&
              response.type === 'Mobile'
            ) {
              this.phoneForm.controls.phone.setValue(response.phoneNumber);
            }
          });
      });
  }

  initForm() {
    this.smsEnroll = this.fb.group({
      mobilenumber: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
    });
  }

  enroll(phone: string, isPhoneInvalid: boolean, resend?: boolean) {
    if (resend) {
      this.resendCount++;
    }
    if (isPhoneInvalid) {
      this.isValid = false;
    } else {
      this.mfaStore.dispatch(
        MFAActions.enrollFactor({
          factorType: 'sms',
          phoneNumber: phone,
        })
      );
      combineLatest([this.mfaEnrollResponse$])
        .pipe(
          tap(([mfaEnrollResponse]) => {
            if (mfaEnrollResponse?.status === 'ACTIVE') {
              this.showMobileInputScreen = false;
              this.showOTPForm = false;
              this.showConfirmationScreen = true;
            } else if (mfaEnrollResponse?.status === 'PENDING_ACTIVATION') {
              this.showMobileInputScreen = false;
              this.showOTPForm = true;
            }
          })
        )
        .subscribe();
    }
    this.mfaService.logMFAMetric(
      'Enroll sms factor in sms enrollment page.',
      EventCode.mfaSmsEnrollment
    );
  }

  activateSMSFactor(passCode: string) {
    interface FactorState {
      status: number;
    }
    this.verificationCount++;
    this.mfaService.logMFAMetric(
      'Activate sms factor in sms enrollment page.',
      EventCode.mfaSmsFactor
    );
    this.mfaStore.dispatch(
      MFAActions.activateFactor({ factorId: this.factorId, passCode: passCode })
    );
    combineLatest([this.activatingFactor$, this.activationErr$])
      .pipe(
        tap(([activatingFactor, activationErr]) => {
          if (
            (activationErr as FactorState)?.status === 500 &&
            activatingFactor
          ) {
            if (this.verificationCount === 3) {
              this.showOTPForm = false;
              this.showFailedAttemptScreen = true;
            }
            this.invalidInput = true;
            this.validOTPInput = false;
          } else if (!activatingFactor) {
            this.invalidInput = false;
            this.showOTPForm = false;
            this.showConfirmationScreen = true;
            this.mfaStore.dispatch(MFAActions.retriveMFAStatus());
          }
        })
      )
      .subscribe();
  }

  handleFailedAttemp() {
    this.showEnroll.emit();
  }
}
