import { createReducer, on } from '@ngrx/store';

import { IPortalAddress } from '@patient-ui/shared/models';

import { SmartyStreetsActions } from './smartystreets.actions';

export const smartyStreetsFeatureKey = 'smartyStreets';

export type SmartyStreetsState = {
  initializeSuccess: boolean;
  suggestResults: IPortalAddress[];
};

export const smartyStreetsInitialState: SmartyStreetsState = {
  initializeSuccess: false,
  suggestResults: [],
};

export const smartyStreetsReducer = createReducer(
  smartyStreetsInitialState,
  on(SmartyStreetsActions.smartyStreetsInitializeSuccess, (state) => ({
    ...state,
    initializeSuccess: true,
  })),
  on(
    SmartyStreetsActions.smartyStreetsSuggestResults,
    (state, { payload }) => ({
      ...state,
      suggestResults: payload,
    })
  ),
  on(SmartyStreetsActions.clearSmartyStreetsSuggestResults, (state) => ({
    ...state,
    suggestResults: [],
  }))
);
