import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAgent {
  isAndroid: boolean;
  isIPad: boolean;
  isIPhone: boolean;
  isMobile: boolean;
  isMobileChrome: boolean;
  isSafari: boolean;
  isIOS: boolean;
  isWebkit: boolean;

  constructor() {
    this.isAndroid = navigator.userAgent.match(/(Android|Mobile)/g)
      ? true
      : false;
    this.isIPad = navigator.userAgent.match(/iPad/g) ? true : false;
    this.isIPhone = navigator.userAgent.match(/iPhone/g) ? true : false;
    this.isMobile = navigator.userAgent.match(
      /(iPad|iPhone|iPod|Android|Mobile)/g
    )
      ? true
      : false;
    this.isMobileChrome = window.navigator.userAgent.match(/CriOS/i)
      ? true
      : false;
    this.isSafari =
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      navigator.vendor.indexOf('Apple') > -1;
    this.isIOS =
      window.navigator.userAgent.match(/iPad/i) ||
      window.navigator.userAgent.match(/iPhone/i)
        ? true
        : false;
    this.isWebkit =
      /\b(iPad|iPhone|iPod)\b/.test(navigator.userAgent) &&
      /WebKit/.test(navigator.userAgent) &&
      !/Edge/.test(navigator.userAgent) &&
      !window.MSStream;
  }
}
