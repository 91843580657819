import { createAction, props } from '@ngrx/store';

import {
  IInvoiceSearch,
  ILinkedAccount,
  IPatient,
} from '@patient-ui/shared/models';

const pendoInit = createAction('[Pendo] Pendo Init');

const pendoInitializeSuccess = createAction(
  '[Pendo] Pendo Initialize Success',
  props<{ success: boolean }>()
);

const pendoSetUnknownUser = createAction('[Pendo] Pendo Set Unknown User');

const pendoSetGuestUser = createAction(
  '[Pendo] Pendo Set Guest User',
  props<{ invoiceSearch: IInvoiceSearch[] }>()
);

const pendoSetRegisteredUser = createAction(
  '[Pendo] Pendo Set Registered User',
  props<{ patient: IPatient }>()
);

const pendoSetLinkedAccounts = createAction(
  `[Pendo] Pendo Set Linked Accounts`,
  props<{ linkedAccounts: ILinkedAccount[] }>()
);

const pendoSetVersion = createAction(
  `[Pendo] Pendo Set Version`,
  props<{ version: string }>()
);

const pendoResetState = createAction('[Pendo] Reset State');

export const PendoActions = {
  pendoInit,
  pendoInitializeSuccess,
  pendoSetUnknownUser,
  pendoSetGuestUser,
  pendoSetRegisteredUser,
  pendoSetLinkedAccounts,
  pendoSetVersion,
  pendoResetState,
};
