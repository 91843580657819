import { createAction, props } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  IMicrobiomeKitSearch,
  MicrobiomeSurveyResponse,
} from '@patient-ui/shared/models';
import { MicrobiomeSurvey } from '@patient-ui/shared/models';

const getMicrobiomeSurvey = createAction(
  '[Microbiome] Get Microbiome Survey',
  props<{ payload: IMicrobiomeKitSearch }>()
);

const getMicrobiomeSurveySuccess = createAction(
  '[Microbiome] Get Microbiome Survey Success',
  props<{ payload: MicrobiomeSurvey }>()
);

const getMicrobiomeSurveyFailure = createAction(
  '[Microbiome] Get Microbiome Survey Failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

const clearMicrobiomeSurveyData = createAction(
  '[Microbiome] Clear Microbiome Survey Data'
);

const updateMicrobiomeSurveyAnswers = createAction(
  '[Microbiome] Update Microbiome Survey Answers',
  props<{ payload: MicrobiomeSurveyResponse }>()
);

const postMicrobiomeSurveyResponse = createAction(
  '[Microbiome] Post Microbiome Survey Response',
  props<{ payload: MicrobiomeSurveyResponse }>()
);

const postMicrobiomeSurveyResponseSuccess = createAction(
  '[Microbiome] Post Microbiome Survey Response Success',
  props<{ payload: RequestStatus }>()
);

const postMicrobiomeSurveyResponseFailure = createAction(
  '[Microbiome] Post Microbiome Survey Response Failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const MicrobiomeActions = {
  getMicrobiomeSurvey,
  getMicrobiomeSurveySuccess,
  getMicrobiomeSurveyFailure,
  clearMicrobiomeSurveyData,
  updateMicrobiomeSurveyAnswers,
  postMicrobiomeSurveyResponse,
  postMicrobiomeSurveyResponseSuccess,
  postMicrobiomeSurveyResponseFailure,
};
