import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PdfDocType } from '@patient-ui/shared/enums';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class PdfViewerService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getPdfDetails(id: string, docType: string): Observable<Blob> {
    let pdfServiceUrl = '';

    if (PdfDocType.Invoice === docType) {
      pdfServiceUrl = `${this.envService.baseUrl}/guest/invoices/paymentPdf?transactionId=${id}`;
    } else if (PdfDocType.Result === docType) {
      pdfServiceUrl = `${this.envService.baseUrl}/guest/${docType}?resultId=${id}`;
    }

    return this.http
      .get(pdfServiceUrl, { headers: this.getHeaders(), responseType: 'blob' })
      .pipe(map((result) => result));
  }

  getInvoicePdfDetails(
    id: string,
    docType: string,
    dob: string,
    portalUser: boolean
  ): Observable<Blob> {
    let pdfServiceUrl = '';
    const uuid =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    if (PdfDocType.Invoice === docType) {
      const portalUserSnippet = portalUser
        ? `/protected/patients/current`
        : `/guest`;
      pdfServiceUrl = `${this.envService.baseUrl}${portalUserSnippet}/invoices/paymentPdf`;
    }
    const request = {
      transactionId: id,
      dateOfBirth: dob,
      uuid: dob.length === 0 ? uuid : '',
    };
    return this.http
      .post(pdfServiceUrl, request, {
        headers: this.getHeaders(),
        responseType: 'blob',
      })
      .pipe(map((result) => result));
  }

  private getHeaders() {
    return new HttpHeaders({
      Accept: 'application/pdf',
    });
  }

  getCombinedInvoicePdfDetails(
    transIdList: string[],
    docType: string,
    portalUser: boolean,
    encryptedIds: boolean,
    dob: string
  ): Observable<Blob> {
    let pdfServiceUrl = '';
    let uuid = '';
    if (dob === '') {
      uuid =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    }
    if (PdfDocType.Invoice === docType) {
      const portalUserSnippet = portalUser
        ? `/protected/patients/current`
        : `/guest`;
      if (encryptedIds === true) {
        pdfServiceUrl = `${this.envService.baseUrl}${portalUserSnippet}/invoices/paymentCombinedPdf/v2`;
      } else {
        pdfServiceUrl = `${this.envService.baseUrl}${portalUserSnippet}/invoices/paymentCombinedPdf`;
      }
    }
    const request = {
      transIdList: transIdList,
      uuid: uuid ? uuid : '',
      patientDob: dob ? dob : '',
    };
    return this.http
      .post(pdfServiceUrl, request, {
        headers: this.getHeaders(),
        responseType: 'blob',
      })
      .pipe(map((result) => result));
  }
}
