<div class="modal-header">
  <h2 ngbAutofocus class="modal-title flex-fill text-center mt-2">
    Verify Your Identity
  </h2>
  <button id="closeModal" type="button" class="btn close" aria-label="Close" (click)="routeToSignIn()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="modal-body">
  <div class="custom-modal-body">
    To continue, you'll need to verify your identity using multifactor authentication (MFA).
  </div>
</div>
<div class="modal-footer justify-content-right">
  <button id="btnMFAVerificationCancel" type="button" class="btn btn-secondary" (click)="routeToSignIn()">
    Cancel
  </button>
  <button id="btnMFAVerificationContinue" #btnMFAVerificationContinue type="button" class="btn btn-primary" (click)="submit()">
    Continue
  </button>
</div>
