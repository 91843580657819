import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import {
  ActivateFactorResponse,
  IMfaMetric,
  MFACatalogItem,
  MFAStatusResponse,
  OktaEnrolledFactor,
  OktaFactorEnrollResponse,
} from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { MetricActions } from '../metric/metric.actions';
import { MetricState } from '../metric/metric.reducer';

@Injectable({
  providedIn: 'root',
})
export class MFAService {
  uuid = '';
  browserInfo = `Browser: ${navigator.userAgent}`;
  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private metricStore: Store<MetricState>
  ) {}
  getEnrolledFactors(): Observable<OktaEnrolledFactor[]> {
    const url = `${this.envService.baseUrl}/protected/patients/current/mfa/factors/enrolled`;
    // Not storing the data which has status as PENDING activation because once user starts
    // the enrollment process and stops before activation, then it will come as pending activation
    return this.http
      .get<OktaEnrolledFactor[]>(url)
      .pipe(
        map((enrolledFactors: OktaEnrolledFactor[]) =>
          enrolledFactors.filter(
            (enrolledFactor) => enrolledFactor.status !== 'PENDING_ACTIVATION'
          )
        )
      );
  }

  getMFACatalog(): Observable<MFACatalogItem[]> {
    const url = `${this.envService.baseUrl}/protected/patients/current/mfa/factors/catalog`;
    // Store data with the status as not setup. Only those are available for enrollment.
    return this.http
      .get<MFACatalogItem[]>(url)
      .pipe(
        map((factorCatalog: MFACatalogItem[]) =>
          factorCatalog.filter((factor) => factor.status === 'NOT_SETUP')
        )
      );
  }

  enrollFactor(
    factorType: string,
    phoneNumber?: string
  ): Observable<OktaFactorEnrollResponse> {
    const url = `${this.envService.baseUrl}/protected/patients/current/mfa/factor`;
    let requestBody: unknown;
    switch (factorType) {
      case 'sms':
        if (!phoneNumber) {
          throw new Error('Phone number is required for SMS enrollment.');
        }
        requestBody = {
          factorType: 'sms',
          provider: 'OKTA',
          profile: {
            phoneNumber,
          },
        };
        break;
      case 'okta:totp':
        requestBody = {
          factorType: 'push',
          provider: 'OKTA',
        };
        break;
      case 'google:totp':
        requestBody = {
          factorType: 'token:software:totp',
          provider: 'GOOGLE',
        };
        break;
      case 'okta:push':
        requestBody = {
          factorType: 'push',
          provider: 'OKTA',
        };
        break;
    }
    return this.http.post<OktaFactorEnrollResponse>(url, requestBody).pipe(
      catchError((error) => {
        throw error;
      }),
      take(1)
    );
  }

  activateFactor(
    factorId: string,
    passCode: string
  ): Observable<ActivateFactorResponse> {
    const url = `${this.envService.baseUrl}/protected/patients/current/mfa/factor/${factorId}/activate`;
    const requestBody = {
      passCode, // OTP generated by the device
    };
    return this.http.post<ActivateFactorResponse>(url, requestBody).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  deleteFactor(factorId: string): Observable<void> {
    const url = `${this.envService.baseUrl}/protected/patients/current/mfa/factor/${factorId}`;
    return this.http.delete<void>(url).pipe(
      catchError((error) => {
        throw error;
      }),
      take(1)
    );
  }

  retriveMFAStatus(): Observable<MFAStatusResponse> {
    const url = `${this.envService.baseUrl}/protected/patients/current/mfa/retrieveMfaByPatientId`;
    return this.http.get<MFAStatusResponse>(url);
  }

  setMFAPreference(preference: boolean): Observable<void> {
    const url = `${this.envService.baseUrl}/protected/patients/current/mfa/acceptDeclineMFA/${preference}`;
    return this.http.post<void>(url, {}).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  logMFAMetric(alertText: string, eventCode: string) {
    this.uuid = this.uuid
      ? this.uuid
      : Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    const mfaMetric: IMfaMetric = {
      dashboardId: 'MFA',
      eventCode: eventCode,
      alertMsg:
        alertText +
        ' USER EMAIL ==> ' +
        sessionStorage.getItem('metricEmail') +
        ' <--BROWSER DETAILS--> ' +
        this.browserInfo,

      userType: 'Registered',
      sessionID: this.uuid,
      templateId: '',
    };
    this.metricStore.dispatch(
      MetricActions.logMfaMetric({ metrics: mfaMetric })
    );
  }
}
