import { Address } from '@patient-ui/shared/models';

export type BraintreeTransactionSetupRequest = {
  isAwsUiOnly: boolean;
  paymentProcessor: string;
  paymentSource: string;
  amount: number;
  isVaultToken: boolean;
};

export type BraintreeTransactionSetupResponse = {
  awsUrl: string;
  secureToken: string;
  reasonCode: string;
  reasonDescription: string;
  isSuccessful: boolean;
  type: string;
};

export type BinData = {
  prepaid: string;
  healthcare: string;
  debit: string;
  durbinRegulated: string;
  commercial: string;
  payroll: string;
  issuingBank: string;
  countryOfIssuance: string;
  productId: string;
};

export type BraintreeTransactionSetupOutput = {
  isSuccessful: boolean;
  reasonCode: string;
  reasonDescription: string;
  paymentProcessor: string;
  brainTreeResp?: BraintreeResp;
  isNotIframeRespEvent?: boolean;
} | null;

export type BraintreeResp = {
  nonce: string;
  type: string;
  details: Details;
};

export type Details = {
  cardType?: string;
  lastFour?: string;
  lastTwo?: string;
  isNetworkTokenized?: string;
  bin?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  cardholderName?: string;
  payerId?: string;
  countryCode?: string;
  shippingAddress?: Address;
};
export enum BraintreePaymentTypes {
  android = 'AndroidPayCard',
  paypal = 'PayPalAccount',
  apple = 'ApplePay',
  applePay = 'ApplePayCard',
}
