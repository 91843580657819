import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { OrderEntity } from '@patient-ui/shared/models';

import * as SelfOrdersActions from './self-orders.actions';
import { PdfResponseFlag } from './self-orders.reducer';
import { SelfOrderService } from './self-orders.service';

@Injectable()
export class SelfOrdersEffects {
  constructor(
    private actions$: Actions,
    private selfOrdersService: SelfOrderService
  ) {}

  loadSelfOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelfOrdersActions.loadSelfOrders),
      exhaustMap(() =>
        this.selfOrdersService.getSelfOrders().pipe(
          map((response) => {
            const oList: OrderEntity[] = [];
            if (response.orderList) {
              for (const order of response.orderList) {
                const newOrder = new OrderEntity(order);
                oList.push(newOrder);
              }
            }
            return SelfOrdersActions.loadSelfOrdersSuccess({
              selfOrders: oList.sort((a, b) => b.orderId - a.orderId),
            });
          }),
          catchError((_error) => of(SelfOrdersActions.loadSelfOrdersFailure()))
        )
      )
    )
  );

  getOrderPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelfOrdersActions.getOrderPdf),
      mergeMap((action) =>
        this.selfOrdersService.getOrderPdf(action.orderId).pipe(
          map((res) => {
            const payload = new Blob([res], { type: 'application/pdf' });
            const responseFlag: PdfResponseFlag = {
              id: action.orderId,
              pdf: payload,
              orderApproved: true,
            };
            return SelfOrdersActions.getOrderPdfSuccess({
              payload: responseFlag,
            });
          }),
          catchError((error) => {
            switch (error.status) {
              case 403: {
                const errorFlag: PdfResponseFlag = {
                  id: action.orderId,
                  pdf: undefined,
                  orderApproved: false,
                };
                return of(
                  SelfOrdersActions.getOrderPdfSuccess({ payload: errorFlag })
                );
              }
              default: {
                const flag: PdfResponseFlag = {
                  id: action.orderId,
                  pdf: undefined,
                  orderApproved: false,
                };
                return of(
                  SelfOrdersActions.getOrderPdfFailure({ payload: flag })
                );
              }
            }
          })
        )
      )
    )
  );
}
