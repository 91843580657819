import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';

import * as patientActions from './phone.actions';

export const phoneFeatureKey = 'phone';

export type PhoneState = {
  optInPhoneRequestStatus: RequestStatus;
  optedIn: boolean;
  message: string;
};

export const initialState: PhoneState = {
  optInPhoneRequestStatus: RequestStatus.NotSent,
  optedIn: false,
  message: '',
};

export const phoneReducer = createReducer(
  initialState,
  on(patientActions.billingTextsOptIn, (state) => ({
    ...state,
    optInPhoneRequestStatus: RequestStatus.Pending,
  })),
  on(patientActions.billingTextsOptInFailure, (state) => ({
    ...state,
    optInPhoneRequestStatus: RequestStatus.Failure,
  })),
  on(patientActions.billingTextsOptInSuccess, (state, { response }) => ({
    ...state,
    optedIn: response.optedIn,
    message: response.message,
    optInPhoneRequestStatus: RequestStatus.Success,
  }))
);
