import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ISplunkMetric } from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class MetricService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  logSplunkMetric(metricList: ISplunkMetric[]): Observable<void> {
    const metricUrl = `${this.envService.baseUrl}/guest/guest/metric`;
    return this.http.post<void>(metricUrl, { metricList });
  }
}
