import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MFAService } from '@patient-ui/patient-web/store';
import {
  FactorsState,
  MFAActions,
  selectDeletingFactor,
  selectMFALoading,
} from '@patient-ui/patient-web/store';
import { EventCode } from '@patient-ui/shared/models';

import { OktaModalService } from '../okta-modal.service';

@Component({
  selector: 'patient-ui-unenrollment',
  templateUrl: './unenrollment.component.html',
  styleUrls: ['./unenrollment.component.scss'],
})
export class UnenrollmentComponent implements OnInit {
  @Output() showEnroll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() factorID!: string;
  @Input() factor!: string;
  @Input() enrolledDataLength!: number;
  showInstruction = true;
  showConfirmationScreen = false;
  showLastFactorRemoval = false;
  mfaUnenrollResponse$ = this.mfaStore.select(selectDeletingFactor);
  mfaLoading$ = this.mfaStore.select(selectMFALoading);
  isLoading = false;

  constructor(
    private mfaStore: Store<FactorsState>,
    private oktaModalService: OktaModalService,

    private mfaService: MFAService
  ) {}

  ngOnInit(): void {
    combineLatest([this.mfaLoading$])
      .pipe(
        tap(([response]) => {
          this.isLoading = response;
        })
      )
      .subscribe();
  }

  getFactor(factor: string) {
    let factorVal = '';
    switch (factor) {
      case 'SMS':
        factorVal = 'SMS authentication';
        break;
      case 'GOOGLE':
        factorVal = 'Google Authenticator';
        break;
      case 'OKTA':
        factorVal = 'Okta Verify';
        break;
    }
    return factorVal;
  }

  cancel() {
    this.mfaService.logMFAMetric(
      'Cancel Removing MFA factor',
      EventCode.mfaRemoveFactor
    );
    this.showEnroll.emit();
  }

  showLastFactorRemovalModal() {
    this.showLastFactorRemoval = true;
    this.mfaStore.dispatch(MFAActions.retriveMFAStatus());
    this.showInstruction = false;
  }

  removeFactor() {
    if (this.enrolledDataLength === 1) {
      this.showLastFactorRemovalModal();
    } else {
      this.confirmRemoveFactor(false);
    }
  }

  async confirmRemoveFactor(lastFactor: boolean) {
    await this.mfaStore.dispatch(
      MFAActions.deleteFactor({
        factorId: this.factorID,
        enrolledDataLength: this.enrolledDataLength,
      })
    );
    combineLatest([this.mfaUnenrollResponse$])
      .pipe(
        tap(([mfaUnenrollResponse]) => {
          if (mfaUnenrollResponse) {
            this.showConfirmationScreen = true;
            this.showInstruction = false;
            this.showLastFactorRemoval = false;
            if (lastFactor) {
              this.mfaStore.dispatch(
                MFAActions.setMFAPreference({ preference: false })
              );
              this.mfaService.logMFAMetric(
                'Remove LAST Factor',
                EventCode.mfaRemoveFactor
              );
            } else {
              this.mfaService.logMFAMetric(
                'Remove  Factor',
                EventCode.mfaRemoveFactor
              );
            }
          }
        })
      )
      .subscribe();
  }

  closeModal() {
    this.oktaModalService.close();
  }
}
