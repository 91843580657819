export class Payment {
  paymentStatus!: string;
  encryptedTransactionId!: string;
  transactionId!: number;
  amountPaid!: number;
  paymentDate!: string;
  paymentDateValue: Date = new Date();
  scheduledPaymentId?: string;
  scheduledPaymentInvoiceId?: string;
  paymentDisplayName!: string;
  dateOfService!: string;
  dateOfServiceValue: Date = new Date();
  lcaInvoiceNumber!: string;
  pid!: number;
  documentKey?: string;
}
