import { createAction, props } from '@ngrx/store';

import { ProfileSectionEditing } from '@patient-ui/shared/enums';
import { IPhoneValidationResponse } from '@patient-ui/shared/models';

export const deletePatientAccount = createAction(
  '[Profile] Delete Patient Account'
);

export const deletePatientAccountSuccess = createAction(
  '[Profile] Delete Patient Account Success'
);

export const deletePatientAccountFailure = createAction(
  '[Profile] Delete Patient Account Failure'
);

export const setSectionInEditMode = createAction(
  '[Profile] Set SectionInEditMode',
  props<{ section: ProfileSectionEditing }>()
);

export const validatePhone = createAction(
  '[Profile] Validate Phone',
  props<{ phoneNumber: string }>()
);

export const validatePhoneSuccess = createAction(
  '[Profile] Validate Phone Success',
  props<{ response: IPhoneValidationResponse }>()
);

export const validatePhoneFailure = createAction(
  '[Profile] Validate Phone Failure'
);

export const resetPhone = createAction('[Profile] Reset Phone');

export const profileActions = {
  deletePatientAccount,
  deletePatientAccountSuccess,
  deletePatientAccountFailure,
  setSectionInEditMode,
  validatePhone,
  validatePhoneSuccess,
  validatePhoneFailure,
  resetPhone,
};
