import { Payment } from './payment.model';
export class PaymentHistory {
  pid?: number;
  lcaInvoiceNumber?: string;
  patientFirstName?: string;
  patientLastName?: string;
  dateOfService!: string;
  payments: Payment[] = [];
  specimenNumber!: string;
}
