import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  ILinkedAccount,
  IRequestError,
  PortalUser,
} from '@patient-ui/shared/models';

import * as LinkedAccountActions from './linked-accounts.actions';

export const linkedAccountsFeatureKey = 'linkedAccountsFeature';

export interface LinkedAccountsState {
  getLinkedAccountsStatus: RequestStatus;
  linkedAccounts: ILinkedAccount[];
  linkedPortalUserList: PortalUser[];
  getSharedAccessPendingRequestStatus: RequestStatus;
  sharedAccessPending: ILinkedAccount[] | null;
  addLinkedAccountRequestStatus: RequestStatus;
  addLinkedAccountRequestError: IRequestError | null;
  linkRequestActionStatus: RequestStatus;
  linkRequestActionError: IRequestError | null;
  removeLinkedAccountRequestStatus: RequestStatus;
  removeLinkedAccountRequestError: IRequestError | null;
  sendMinorAccessLinkRequestRequestStatus: RequestStatus;
  sendMinorAccessLinkRequestRequestError: IRequestError | null;
  sendMinorAccessLinkRequestsRequestStatus: RequestStatus;
  sendMinorAccessLinkRequestsRequestError: IRequestError | null;
}

export const linkedAccountsInitialState: LinkedAccountsState = {
  getLinkedAccountsStatus: RequestStatus.NotSent,
  linkedAccounts: [],
  linkedPortalUserList: [],
  getSharedAccessPendingRequestStatus: RequestStatus.NotSent,
  sharedAccessPending: null,
  addLinkedAccountRequestStatus: RequestStatus.NotSent,
  addLinkedAccountRequestError: null,
  linkRequestActionStatus: RequestStatus.NotSent,
  linkRequestActionError: null,
  removeLinkedAccountRequestStatus: RequestStatus.NotSent,
  removeLinkedAccountRequestError: null,
  sendMinorAccessLinkRequestRequestStatus: RequestStatus.NotSent,
  sendMinorAccessLinkRequestRequestError: null,
  sendMinorAccessLinkRequestsRequestStatus: RequestStatus.NotSent,
  sendMinorAccessLinkRequestsRequestError: null,
};

export const linkedAccountsReducer = createReducer(
  linkedAccountsInitialState,
  on(LinkedAccountActions.loadLinkedAccounts, (state) => ({
    ...state,
    getLinkedAccountsStatus: RequestStatus.Pending,
  })),

  on(
    LinkedAccountActions.loadLinkedAccountsSuccess,
    (state, { accountList }) => ({
      ...state,
      getLinkedAccountsStatus: RequestStatus.Success,
      linkedAccounts: accountList,
    })
  ),

  on(LinkedAccountActions.loadLinkedAccountsFailure, (state) => ({
    ...state,
    getLinkedAccountsStatus: RequestStatus.Failure,
  })),

  on(
    LinkedAccountActions.updateLinkedPortalUserListWithPAHSuccess,
    (state, { updatedLinkedAccountList }) => ({
      ...state,
      linkedPortalUserList: updatedLinkedAccountList,
    })
  ),

  on(LinkedAccountActions.loadSharedAccesPendingRequest, (state) => ({
    ...state,
    getSharedAccessPendingRequestStatus: RequestStatus.Pending,
  })),

  on(
    LinkedAccountActions.loadSharedAccesPendingRequestSuccess,
    (state, { pendingAccounttList }) => ({
      ...state,
      getSharedAccessPendingRequestStatus: RequestStatus.Success,
      sharedAccessPending: pendingAccounttList,
    })
  ),

  on(LinkedAccountActions.loadSharedAccesPendingRequestFailure, (state) => ({
    ...state,
    getSharedAccessPendingRequestStatus: RequestStatus.Failure,
    sharedAccessPending: null,
  })),

  on(LinkedAccountActions.addLinkedAccount, (state) => ({
    ...state,
    addLinkedAccountRequestStatus: RequestStatus.Pending,
  })),

  on(LinkedAccountActions.addLinkedAccountSuccess, (state) => ({
    ...state,
    addLinkedAccountRequestStatus: RequestStatus.Success,
  })),

  on(
    LinkedAccountActions.addLinkedAccountFailure,
    (state, { errorResponse }) => ({
      ...state,
      addLinkedAccountRequestStatus: RequestStatus.Failure,
      addLinkedAccountRequestError: errorResponse,
    })
  ),

  on(LinkedAccountActions.linkRequestAction, (state) => ({
    ...state,
    linkRequestActionStatus: RequestStatus.Pending,
  })),

  on(LinkedAccountActions.linkRequestActionSuccess, (state) => ({
    ...state,
    linkRequestActionStatus: RequestStatus.Success,
  })),

  on(
    LinkedAccountActions.linkRequestActionFailure,
    (state, { errorResponse }) => ({
      ...state,
      linkRequestActionStatus: RequestStatus.Failure,
      linkRequestAcceptError: errorResponse,
    })
  ),

  on(LinkedAccountActions.removeLinkedAccount, (state) => ({
    ...state,
    removeLinkedAccountRequestStatus: RequestStatus.Pending,
  })),

  on(
    LinkedAccountActions.removeLinkedAccountSuccess,
    (state, { linkedIds }) => {
      let newLinkedAccounts = state.linkedAccounts;
      linkedIds.forEach((linkedId) => {
        newLinkedAccounts = newLinkedAccounts.filter(
          (linkedAccount) => linkedAccount.linkedId !== linkedId
        );
      });
      return {
        ...state,
        removeLinkedAccountRequestStatus: RequestStatus.Success,
        linkedAccounts: newLinkedAccounts,
      };
    }
  ),

  on(
    LinkedAccountActions.removeLinkedAccountFailure,
    (state, { errorResponse }) => ({
      ...state,
      removeLinkedAccountRequestStatus: RequestStatus.Failure,
      removeLinkedAccountRequestError: errorResponse,
    })
  ),

  on(LinkedAccountActions.sendMinorAccessLinkRequest, (state) => ({
    ...state,
    sendMinorAccessLinkRequestRequestStatus: RequestStatus.Pending,
    sendMinorAccessLinkRequestRequestError: null,
  })),

  on(LinkedAccountActions.sendMinorAccessLinkRequestSuccess, (state) => ({
    ...state,
    sendMinorAccessLinkRequestRequestStatus: RequestStatus.Success,
  })),

  on(
    LinkedAccountActions.sendMinorAccessLinkRequestFailure,
    (state, { errorResponse }) => ({
      ...state,
      sendMinorAccessLinkRequestRequestStatus: RequestStatus.Failure,
      sendMinorAccessLinkRequestRequestError: errorResponse,
    })
  ),

  on(LinkedAccountActions.sendMinorAccessLinkRequests, (state) => ({
    ...state,
    sendMinorAccessLinkRequestsRequestStatus: RequestStatus.Pending,
    sendMinorAccessLinkRequestsRequestError: null,
  })),

  on(LinkedAccountActions.sendMinorAccessLinkRequestsSuccess, (state) => ({
    ...state,
    sendMinorAccessLinkRequestsRequestStatus: RequestStatus.Success,
  })),

  on(
    LinkedAccountActions.sendMinorAccessLinkRequestsFailure,
    (state, { errorResponse }) => ({
      ...state,
      sendMinorAccessLinkRequestRequestsStatus: RequestStatus.Failure,
      sendMinorAccessLinkRequestRequestsError: errorResponse,
    })
  ),

  on(LinkedAccountActions.resetModalActionsState, (state) => ({
    ...state,
    addLinkedAccountRequestStatus:
      linkedAccountsInitialState.addLinkedAccountRequestStatus,
    addLinkedAccountRequestError:
      linkedAccountsInitialState.addLinkedAccountRequestError,
    linkRequestActionStatus: linkedAccountsInitialState.linkRequestActionStatus,
    linkRequestActionError: linkedAccountsInitialState.linkRequestActionError,
    removeLinkedAccountRequestStatus:
      linkedAccountsInitialState.removeLinkedAccountRequestStatus,
    removeLinkedAccountRequestError:
      linkedAccountsInitialState.removeLinkedAccountRequestError,
    sendMinorAccessLinkRequestRequestStatus:
      linkedAccountsInitialState.sendMinorAccessLinkRequestRequestStatus,
    sendMinorAccessLinkRequestRequestError:
      linkedAccountsInitialState.sendMinorAccessLinkRequestRequestError,
  }))
);
