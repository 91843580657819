import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  PortalUser,
  Report,
  ReportHeader,
  ReportHeaderSummary,
} from '@patient-ui/shared/models';

import { ResultActions } from './results.actions';

export const resultsFeatureKey = 'results';

export interface ResultsState {
  results: ReportHeaderSummary;
  resultHeaders: ReportHeader[] | undefined;
  loadResultsPayloadRequestStatus: RequestStatus;
  loadResultHeadersStatus: RequestStatus;
  resultDetail?: Report;
  getResultDetailStatus?: RequestStatus;
  getResultPdfRequestStatus: RequestStatus;
  resultPdf: Blob | undefined;
  resultHistorySelectedUser?: PortalUser;
  error?: string;
}

export const ResultsInitialState: ResultsState = {
  results: {
    new: [],
    upcoming: [],
    updated: [],
    recently_viewed: [],
    totalNew: 0,
    totalUpcoming: 0,
    totalUpdated: 0,
    totalRecentlyViewed: 0,
    totalAll: 0,
    hasResult: false,
  },
  resultHeaders: [],
  loadResultsPayloadRequestStatus: RequestStatus.NotSent,
  loadResultHeadersStatus: RequestStatus.NotSent,
  getResultDetailStatus: RequestStatus.NotSent,
  getResultPdfRequestStatus: RequestStatus.NotSent,
  resultPdf: undefined,
};

export const resultsReducer = createReducer(
  ResultsInitialState,
  on(ResultActions.loadResultsSuccess, (state, { results }) => ({
    ...state,
    results,
    loadResultsPayloadRequestStatus: RequestStatus.Success,
  })),
  on(ResultActions.loadResultsFailure, (state, action) => ({
    ...state,
    loadResultsPayloadRequestStatus: RequestStatus.Failure,
    error: action.error,
  })),
  on(ResultActions.loadResultHeaders, (state) => ({
    ...state,
    resultHeaders: undefined,
    loadResultHeadersStatus: RequestStatus.Pending,
  })),
  on(ResultActions.loadResultHeadersSuccess, (state, results) => ({
    ...state,
    resultHeaders: results.resultHeaders,
    loadResultHeadersStatus: RequestStatus.Success,
  })),
  on(ResultActions.loadResultHeadersFailure, (state, action) => ({
    ...state,
    resultHeaders: [],
    loadResultHeadersStatus: RequestStatus.Failure,
    error: action.error,
  })),
  on(ResultActions.getResultDetail, (state) => ({
    ...state,
    getResultDetailStatus: RequestStatus.Pending,
    resultDetail: undefined,
  })),
  on(ResultActions.getResultDetailSuccess, (state, reportDetail) => ({
    ...state,
    resultDetail: reportDetail.report,
    getResultDetailStatus: RequestStatus.Success,
  })),
  on(ResultActions.getResultDetailFailure, (state, action) => ({
    ...state,
    getResultDetailStatus: RequestStatus.Failure,
    error: action.error,
  })),
  on(ResultActions.getResultPdfSuccess, (state, action) => ({
    ...state,
    resultPdf: action.payload,
    getResultPdfRequestStatus: RequestStatus.Success,
  })),
  on(ResultActions.getResultPdfFailure, (state, action) => ({
    ...state,
    resultPdf: undefined,
    getResultPdfRequestStatus: RequestStatus.Failure,
    error: action.error,
  })),
  on(ResultActions.clearResultPdf, (state) => ({
    ...state,
    resultPdf: undefined,
    getResultPdfRequestStatus: RequestStatus.NotSent,
  })),
  on(ResultActions.setResultHistorySelectedUser, (state, { selectedUser }) => ({
    ...state,
    resultHistorySelectedUser: selectedUser,
  })),
  on(ResultActions.clearResults, (state) => ({
    ...state,
    results: ResultsInitialState.results,
    resultHeaders: ResultsInitialState.resultHeaders,
    loadResultHeadersStatus: ResultsInitialState.loadResultHeadersStatus,
  }))
);
