import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import * as authActions from '@patient-ui/patient-web/store';
import * as patientActions from '@patient-ui/patient-web/store';
import {
  AuthState,
  PatientState,
  selectPatientState,
} from '@patient-ui/patient-web/store';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Component({
  selector: 'patient-ui-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalHeaderComponent implements OnInit, OnDestroy {
  patientUrl = '';
  onDemandUrl = '';
  helpCenterUrl = '';
  patientResultsUrl = '';
  patientLinkedAccountsUrl = '';
  patientProfileUrl = '';
  patientSignOutUrl = '';
  destroyed = new Subject();
  pahName = '';
  pahAbbreviation = '';
  showAlert$ = new BehaviorSubject<boolean>(false);
  isTechDifficulties$ = new BehaviorSubject<boolean>(false);
  showSuccess$ = new BehaviorSubject<boolean>(true);
  isMenuCollapsed = true;
  minimizedNavBar$ = new BehaviorSubject<boolean>(false);
  subs: Array<Subscription> = [];
  isMobile = false;
  navigatedFrom: string | undefined;

  constructor(
    private envService: EnvironmentService,
    private patientStore: Store<PatientState>,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AuthState>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.navigatedFrom =
      this.route.snapshot.queryParamMap.get('navigatedFrom') || '';
    this.patientUrl = this.envService.patientUrl;
    this.onDemandUrl = this.envService.onDemandUrl;
    this.subs[0] = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.minimizedNavBar$.next(route.data.navbarType === 'minimized');
      });
    this.helpCenterUrl = `${this.patientUrl}/help-center/home?nav=r`;
    this.patientResultsUrl = `${this.patientUrl}/portal/results`;
    this.patientLinkedAccountsUrl = `${this.patientUrl}/portal/linked-accounts`;
    this.patientProfileUrl = `${this.patientUrl}/portal/profile`;
    const isMobileNavigation = localStorage.getItem('isMobileNavigation');
    if (isMobileNavigation) {
      localStorage.removeItem('isMobileNavigation');
      this.patientStore.dispatch(
        patientActions.populateMobile({ payload: true })
      );
    }

    this.patientStore
      .select(selectPatientState)
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (patientState) => {
          this.isMobile = patientState.isMobile;
          const patient = patientState.primaryAccountHolder;
          if (patient) {
            this.pahName = patient.firstName + ' ' + patient.lastName;
            this.pahAbbreviation =
              patient.firstName.charAt(0) + patient.lastName.charAt(0);
          }
        },
        () => {
          this.showAlert$.next(true);
          this.isTechDifficulties$.next(true);
          this.showSuccess$.next(false);
        }
      );

    window.addEventListener(
      'click',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (evt: any) => {
        if (
          !document.querySelector('[id^="portal-header"]')?.contains(evt.target)
        ) {
          this.collapseMenu();
        }
      },
      true
    );
  }

  signOut($event: Event): void {
    $event.preventDefault();
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.store.dispatch(authActions.logOut({ redirect: '/landing' }));
    sessionStorage.clear();
    window.history.replaceState(null, '', '/');
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.subs.forEach((s) => s.unsubscribe());
  }

  collapseMenu(): void {
    this.isMenuCollapsed = true;
    this.changeDetectorRef.detectChanges();
  }
}
