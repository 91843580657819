import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import { OrderEntity } from '@patient-ui/shared/models';

import * as SelfOrdersActions from './self-orders.actions';

export const selfOrdersFeatureKey = 'selfOrders';
export type PdfResponseFlag = {
  id: string;
  pdf: Blob | undefined;
  orderApproved: boolean;
};
export interface SelfOrdersState {
  selfOrders: OrderEntity[];
  loadSelfOrdersPayloadRequestStatus: RequestStatus;
  getOrderPdfRequestStatus: RequestStatus;
  orderPdf: Blob | undefined;
  pdfList: PdfResponseFlag[];
}

export const SelfOrdersInitialState: SelfOrdersState = {
  selfOrders: [],
  loadSelfOrdersPayloadRequestStatus: RequestStatus.NotSent,
  getOrderPdfRequestStatus: RequestStatus.NotSent,
  orderPdf: undefined,
  pdfList: [],
};

export const selfOrdersReducer = createReducer(
  SelfOrdersInitialState,
  on(SelfOrdersActions.loadSelfOrders, (state) => ({
    ...state,
    loadSelfOrdersPayloadRequestStatus: RequestStatus.Pending,
  })),
  on(SelfOrdersActions.loadSelfOrdersSuccess, (state, { selfOrders }) => ({
    ...state,
    selfOrders: selfOrders,
    loadSelfOrdersPayloadRequestStatus: RequestStatus.Success,
  })),
  on(SelfOrdersActions.loadSelfOrdersFailure, (state) => ({
    ...state,
    selfOrders: [],
    loadSelfOrdersPayloadRequestStatus: RequestStatus.Failure,
  })),
  on(SelfOrdersActions.getOrderPdfSuccess, (state, action) => ({
    ...state,
    pdfList: [...state.pdfList, action.payload],
    getOrderPdfRequestStatus: RequestStatus.Success,
  })),
  on(SelfOrdersActions.getOrderPdfFailure, (state, action) => ({
    ...state,
    pdfList: [...state.pdfList, action.payload],
    getOrderPdfRequestStatus: RequestStatus.Failure,
  })),
  on(SelfOrdersActions.clearOrderPdf, (state) => ({
    ...state,
    orderPdf: undefined,
    getOrderPdfRequestStatus: RequestStatus.NotSent,
  })),
  on(SelfOrdersActions.clearPdfList, (state) => ({
    ...state,
    pdfList: [],
    getOrderPdfRequestStatus: RequestStatus.NotSent,
  }))
);
