import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import {
  FactorsState,
  MFAActions,
  MFAService,
  PatientState,
  getMFASelectionStatus,
  mfaTechDifficultiesStatus,
  selectEnrolledFactors,
  selectMFACatalog,
  selectMFALoading,
  selectPatientState,
} from '@patient-ui/patient-web/store';
import { enrollmentChoice } from '@patient-ui/shared/constants';
import { EventCode } from '@patient-ui/shared/models';

import { OktaModalService } from './okta-modal.service';
import { MFASharedService } from '../../../../shared';

@Component({
  selector: 'patient-ui-enroll-mfa',
  templateUrl: './enroll-mfa.component.html',
  styleUrls: ['./enroll-mfa.component.scss'],
})
export class EnrollMfaComponent implements OnInit, OnDestroy, AfterViewInit {
  multiFactorAuth!: FormGroup;
  factorProcessSelection = '';
  factorSelection = '';
  factorID!: string | null | undefined;
  isPasswordConfirmationRequired = true;
  patientName = '';
  destroyed = new Subject();
  mfaStatus$ = this.mfaStore.select(getMFASelectionStatus);
  enrolledData$ = this.mfaStore.select(selectEnrolledFactors);
  availableEnrollment$ = this.mfaStore.select(selectMFACatalog);
  mfaTechDifficulties$: Observable<boolean> = this.mfaStore.select(
    mfaTechDifficultiesStatus
  );
  mfaTechDifficultiesStatus = false;
  enrollmentChoice: enrollmentChoice[] = [];
  factorDropdownList: enrollmentChoice[] = [];
  isNextDisabled = true;
  userEmail = '';
  enrollmentSelect = false;
  showFirstModal = true;
  userClickedSetup = false;
  closeModalSubscription: Subscription | null = null;
  isPasswordRequiredSubscription!: Subscription;
  showEnrollment = true;
  mfaLoading$ = this.mfaStore.select(selectMFALoading);
  isLoading = false;
  // Check user group status
  oktaID = ''; // Temporary ID to get okta push ID because we are getting two okta ids (PUSH/TOTP)

  @ViewChild('btnSetUpMFA') firstButton!: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private mfaStore: Store<FactorsState>,
    private patientStore: Store<PatientState>,
    private mfaSharedService: MFASharedService,
    private mfaService: MFAService,
    private oktaModalService: OktaModalService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.mfaStore.dispatch(MFAActions.retriveMFAStatus());
    this.mfaStore.dispatch(MFAActions.loadEnrolledFactors());
    this.mfaStore.dispatch(MFAActions.loadMFACatalog());

    this.mfaTechDifficulties$.subscribe(
      (value) => (this.mfaTechDifficultiesStatus = value)
    );
    this.closeModalSubscription = this.oktaModalService.closeModal$.subscribe(
      () => {
        this.closeModal();
        this.unsubscribeCloseModal();
      }
    );
    this.initForm();
    this.patientStore
      .select(selectPatientState)
      .pipe(takeUntil(this.destroyed))
      .subscribe((patientState) => {
        const patient = patientState.primaryAccountHolder;
        if (patient) {
          this.patientName = patient.firstName + ' ' + patient.lastName;
          this.userEmail = patient.loginEmail ? patient.loginEmail : '';
        }
      });

    //Combine the enrolledData and availableEnrollment observables to create enrollmentChoice
    combineLatest([this.enrolledData$, this.availableEnrollment$])
      .pipe(
        map(([enrolledData, availableEnrollment]) => {
          if (enrolledData.length > 0 && availableEnrollment.length > 0) {
            this.enrollmentChoice = [
              {
                text: 'Enroll a factor',
                value: 'enroll',
              },
              {
                text: 'Remove a factor',
                value: 'remove',
              },
            ];
          } else if (enrolledData.length > 0) {
            this.enrollmentChoice = [
              {
                text: 'Remove a factor',
                value: 'remove',
              },
            ];
          } else if (availableEnrollment.length) {
            this.enrollmentChoice = [
              {
                text: 'Enroll a factor',
                value: 'enroll',
              },
            ];
          }
        })
      )
      .subscribe();

    combineLatest([this.mfaLoading$])
      .pipe(
        tap(([response]) => {
          this.isLoading = response;
        })
      )
      .subscribe();

    this.isPasswordRequiredSubscription = this.mfaSharedService
      .getPasswordConfirmation()
      .subscribe((passwordRequiredState) => {
        this.isPasswordConfirmationRequired = passwordRequiredState;
        this.ref.detectChanges();
      });

    this.mfaStatus$.pipe(takeUntil(this.destroyed)).subscribe((state) => {
      switch (state) {
        case undefined:
        case null:
        case false:
          this.showFirstModal = true;
          break;
        default:
          this.showFirstModal = false;
          break;
      }
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.unsubscribeCloseModal();
  }

  ngAfterViewInit() {
    if (this.firstButton) {
      this.firstButton.nativeElement.focus();
    }
  }

  initForm() {
    this.multiFactorAuth = this.fb.group({
      mfaProcess: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
      factorValue: new FormControl(
        { value: null, disabled: true },
        { validators: [Validators.required], updateOn: 'blur' }
      ),
    });
  }

  handleEnrollmentSelection(selectionValue: enrollmentChoice) {
    this.isNextDisabled = true;
    this.enrollmentSelect = true;
    this.multiFactorAuth.get('factorValue')?.enable();
    if (selectionValue.value === 'enroll') {
      this.availableEnrollment$.subscribe((data) =>
        this.generateFactorList(data)
      );
    } else {
      this.enrolledData$.subscribe((data) => this.generateFactorList(data));
    }
    this.multiFactorAuth.get('factorValue')?.setValue('');
  }

  generateFactorList(data: enrollmentChoice[]) {
    let oktaTotlFound = false;
    let oktaPushFound = false;
    let oktaAdded = false;
    this.factorDropdownList = [];
    data.map((item: enrollmentChoice) => {
      if (item.factorType === 'sms' && item.provider === 'OKTA') {
        this.factorDropdownList.push({
          text: 'SMS Text Message',
          value: 'SMS',
          id: item.id,
        });
      } else if (
        (item.factorType === 'token:software:totp' ||
          item.factorType === 'push') &&
        item.provider === 'OKTA'
      ) {
        if (!oktaAdded) {
          if (item.factorType === 'token:software:totp') {
            oktaTotlFound = true;
          } else if (item.factorType === 'push') {
            this.oktaID = item.id ? item.id : '';
            oktaPushFound = true;
          }
          if (oktaTotlFound && oktaPushFound) {
            oktaAdded = true;
            this.factorDropdownList.push({
              text: 'Okta Verify Mobile App',
              value: 'OKTA',
              id: this.oktaID,
            });
          }
        }
      } else if (
        item.factorType === 'token:software:totp' &&
        item.provider === 'GOOGLE'
      ) {
        this.factorDropdownList.push({
          text: 'Google Authenticator Mobile App',
          value: 'GOOGLE',
          id: item.id,
        });
      }
    });
  }

  handleFactorSelection() {
    this.isNextDisabled = false;
  }

  getIDFromValue(value: string) {
    for (let i = 0; i < this.factorDropdownList.length; i++) {
      if (this.factorDropdownList[i].value === value) {
        return this.factorDropdownList[i].id;
      }
    }
    return null;
  }

  async closeModal() {
    this.activeModal.dismiss('cancel');
    this.mfaService.logMFAMetric(
      'Close MFA Setup in MFA Selection page',
      EventCode.mfaSetup
    );
  }

  unsubscribeCloseModal() {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }

  setUpMFA() {
    this.mfaService.logMFAMetric(
      'User confirmed MFA Setup',
      EventCode.mfaSetup
    );
    this.userClickedSetup = true;
  }

  goToNextPage() {
    this.mfaService.logMFAMetric(
      'Initial MFA Selection page.',
      EventCode.mfaSetup
    );
    this.showEnrollment = false;
    this.factorSelection = this.multiFactorAuth.get('factorValue')?.value;
    this.factorProcessSelection = this.multiFactorAuth.get('mfaProcess')?.value;
    this.factorID = this.getIDFromValue(
      this.multiFactorAuth.get('factorValue')?.value
    );
  }

  updateShowEnrollmentPage() {
    this.showEnrollment = true;
  }
}
