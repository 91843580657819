import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouteData } from '@patient-ui/shared-ui/utils';

import { BillingOptinComponent } from './billing-optin/billing-optin.component';
import { PortalLayoutComponent } from './containers/portal-layout/portal-layout.component';
import { PublicLayoutComponent } from './containers/public-layout/public-layout.component';
import { AciTokenHandlerComponent } from './invoices/containers/invoices-payment/iframe-aws-paymentech/AciTokenHandler';
import { InvoicesReviewComponent } from './invoices/containers/invoices-review/invoices-review.component';
import { OktaAuthComponent } from './okta-auth/okta-auth.component';
import { AuthGuard } from './okta-auth/okta-auth.guard';
import { AnonymousRequiredGuard } from './shared/guards/anonymous-required.guard';
import { AuthenticationRequiredGuard } from './shared/guards/authentication-required.guard';
import { RedirectGuard } from './shared/guards/redirect.guard';
import { PdfViewerComponent } from './shared/pdf-viewer/pdf-viewer.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    component: OktaAuthComponent,
  },
  {
    path: 'login',
    data: { preload: false } as RouteData,
    loadChildren: () =>
      import('./ondemand-login/ondemand-login.module').then(
        (m) => m.OnDemandLoginModule
      ),
  },
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'v1',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'pay-my-bill',
    redirectTo: 'invoices/find',
    pathMatch: 'full',
  },
  {
    path: 'ui/guestBilling',
    redirectTo: 'invoices/find',
    pathMatch: 'full',
  },
  {
    path: 'registration',
    redirectTo: 'account/registration/register/:campaignId',
    pathMatch: 'full',
  },
  {
    path: 'registration',
    redirectTo: 'account/registration/register',
    pathMatch: 'full',
  },
  {
    path: 'v2/microbiome',
    redirectTo: 'microbiome',
    pathMatch: 'full',
  },
  {
    path: 'v2/medical-record-share',
    redirectTo: 'medical-record-share',
    pathMatch: 'full',
  },
  {
    path: 'E2P/invoices/find',
    redirectTo: 'invoices/findE2P',
    pathMatch: 'full',
  },
  {
    path: 'v2/invoices/find',
    redirectTo: 'invoices/find',
    pathMatch: 'full',
  },
  {
    path: 'E2P/invoices/find',
    redirectTo: 'invoices/find',
    pathMatch: 'full',
  },
  {
    path: 'paymentPlan',
    redirectTo: 'invoices/payment-plan-landing',
    pathMatch: 'full',
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'landing',
        canActivate: [AnonymousRequiredGuard],
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'ui/transition',
        data: { preload: false } as RouteData,
        pathMatch: 'full',
        loadChildren: () =>
          import('./transition/transition.module').then(
            (m) => m.TransitionModule
          ),
      },
      {
        path: 'callback',
        loadChildren: () =>
          import('./transition/transition.module').then(
            (m) => m.TransitionModule
          ),
      },
      {
        path: 'mobile-callback',
        loadChildren: () =>
          import('./transition/transition.module').then(
            (m) => m.TransitionModule
          ),
      },
      {
        path: 'appointments',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./appointments/appointments.module').then(
            (m) => m.AppointmentsModule
          ),
      },
      {
        path: 'billing-texts/:lpid/:campaignId',
        data: { preload: false } as RouteData,
        component: BillingOptinComponent,
      },
      {
        path: 'invoices',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: 'account/registration',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./account-registration/account-registration.module').then(
            (m) => m.AccountRegistrationModule
          ),
      },
      {
        path: 'account/identity-verification',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import(
            './account-verification/duplicate-account-verification.module'
          ).then((m) => m.DuplicateAccountVerificationModule),
      },
      {
        path: 'account',
        redirectTo: 'account/create',
      },
      {
        path: 'help-center/contact',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./help-center-contact/help-center-contact.module').then(
            (m) => m.HelpCenterContactModule
          ),
      },
      {
        path: 'help-center',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./help-center/help-center.module').then(
            (m) => m.HelpCenterModule
          ),
      },
      {
        path: 'microbiome',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./guest-microbiome/microbiome.module').then(
            (m) => m.MicrobiomeModule
          ),
      },
      {
        path: 'medical-record-share',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./medical-record-share/medical-record-share.module').then(
            (m) => m.MedicalRecordShareModule
          ),
      },
      {
        path: 'fast-registration',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./fast-registration/fast-registration.module').then(
            (m) => m.FastRegistrationModule
          ),
      },
      {
        path: 'user-agreement',
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./user-agreement/user-agreement.module').then(
            (m) => m.UserAgreementModule
          ),
      },
    ],
  },
  {
    path: 'renderpdf/:docType/:id',
    redirectTo: '/invoices/generate-receipt/:id',
  },
  {
    path: 'renderpdf/:docType',
    component: PdfViewerComponent,
  },
  {
    path: 'rendermultiplepdf/:docType',
    component: PdfViewerComponent,
  },
  {
    path: 'portal',
    component: PortalLayoutComponent,
    children: [
      {
        path: 'account/identity-verification',
        canActivate: [AuthenticationRequiredGuard],
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./account-verification/account-verification.module').then(
            (m) => m.AccountVerificationModule
          ),
      },
      {
        path: 'invoices',
        canActivate: [AuthenticationRequiredGuard],
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./portal-invoices/portal-invoices.module').then(
            (m) => m.PortalInvoicesModule
          ),
      },
      {
        path: 'appointments',
        canActivate: [AuthenticationRequiredGuard],
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./portal-appointments/portal-appointments.module').then(
            (m) => m.PortalAppointmentsModule
          ),
      },
      {
        path: 'orders',
        canActivate: [AuthenticationRequiredGuard],
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./portal-orders/portal-orders.module').then(
            (m) => m.PortalOrdersModule
          ),
      },
      {
        path: 'results',
        canActivate: [AuthenticationRequiredGuard],
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./portal-results/portal-results.module').then(
            (m) => m.PortalResultsModule
          ),
      },
      {
        path: 'account',
        canActivate: [AuthenticationRequiredGuard],
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./portal-account/portal-account.module').then(
            (m) => m.PortalAccountModule
          ),
      },
      {
        path: 'dashboard',
        canActivate: [AuthenticationRequiredGuard],
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./portal-dashboard/portal-dashboard.module').then(
            (m) => m.PortalDashboardModule
          ),
      },
      {
        path: 'ondemand',
        canActivate: [AuthenticationRequiredGuard],
        data: { preload: false } as RouteData,
        loadChildren: () =>
          import('./portal-pixel/portal-pixel.module').then(
            (m) => m.PortalPixelModule
          ),
      },
    ],
  },
  {
    path: 'externalRedirect',
    canActivate: [RedirectGuard],
    component: InvoicesReviewComponent,
  },
  {
    path: 'acitoken',
    component: AciTokenHandlerComponent,
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientWebFeatureShellRoutingModule {}
