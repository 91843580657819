import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  MicrobiomeSurvey,
  MicrobiomeSurveyResponse,
} from '@patient-ui/shared/models';

import { MicrobiomeActions } from './microbiome.actions';

export const microbiomeFeatureKey = 'microbiome';

export type MicrobiomeState = {
  microbiomeSurvey: MicrobiomeSurvey;
  microbiomeResponse: MicrobiomeSurveyResponse;
  serviceUnavailable: boolean;
  getMicrobiomeSurveyRequestStatus: RequestStatus;
  getMicrobiomeSurveyRequestError: string;
  postMicrobiomeSurveyResponseStatus: RequestStatus;
  postMicrobiomeSurveyResponseError: string;
};

export const initialState: MicrobiomeState = {
  microbiomeSurvey: {
    labcorpOrderId: '',
    successful: false,
    microbiomeQuestion: [],
  },
  microbiomeResponse: {
    labcorpOrderId: '',
    dob: '',
    questionAndAnswers: [],
  },
  serviceUnavailable: false,
  getMicrobiomeSurveyRequestStatus: RequestStatus.NotSent,
  getMicrobiomeSurveyRequestError: '',
  postMicrobiomeSurveyResponseStatus: RequestStatus.NotSent,
  postMicrobiomeSurveyResponseError: '',
};

export const microbiomeReducer = createReducer(
  initialState,
  on(MicrobiomeActions.getMicrobiomeSurvey, (state) => ({
    ...state,
    getMicrobiomeSurveyRequestStatus: RequestStatus.Pending,
  })),
  on(MicrobiomeActions.getMicrobiomeSurveySuccess, (state, { payload }) => ({
    ...state,
    microbiomeSurvey: { ...payload },
    getMicrobiomeSurveyRequestStatus: RequestStatus.Success,
  })),
  on(MicrobiomeActions.getMicrobiomeSurveyFailure, (state, { payload }) => ({
    ...state,
    getMicrobiomeSurveyRequestStatus: payload.status,
    getMicrobiomeSurveyRequestError: payload.error,
  })),
  on(MicrobiomeActions.clearMicrobiomeSurveyData, (state) => ({
    ...state,
    microbiomeSurvey: { ...initialState.microbiomeSurvey },
  })),
  on(MicrobiomeActions.updateMicrobiomeSurveyAnswers, (state, { payload }) => ({
    ...state,
    microbiomeResponse: { ...payload },
  })),
  on(MicrobiomeActions.postMicrobiomeSurveyResponse, (state) => ({
    ...state,
    postMicrobiomeSurveyResponseStatus: RequestStatus.Pending,
  })),
  on(
    MicrobiomeActions.postMicrobiomeSurveyResponseSuccess,
    (state, { payload }) => ({
      ...state,
      postMicrobiomeSurveyResponseStatus: payload,
    })
  ),
  on(
    MicrobiomeActions.postMicrobiomeSurveyResponseFailure,
    (state, { payload }) => ({
      ...state,
      postMicrobiomeSurveyResponseStatus: payload.statue,
      postMicrobiomeSurveyResponseError: payload.error,
    })
  )
);
