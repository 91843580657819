export class TransactionSetupOutput {
  isSuccessful!: boolean;
  reasonCode!: string;
  reasonDescription!: string;
  paymentAccountId!: string;
  transactionSetupId!: string;
  validationCode!: string;
  paymentType!: string;
  cardLastFourDigits!: string;
  expirationMonth!: string;
  expirationYear!: string;
  cardBrand!: string;
}
