/**
 * Status of a network request
 */
export enum RequestStatus {
  /**
   * Use `NotSent` as the initialized value of a request status
   */
  NotSent,

  /**
   * Use `Pending` after a network request is sent and we are waiting for a
   * response.
   */
  Pending,

  /**
   * Use `Failure` when a network request fails.
   */
  Failure,

  /**
   * Use `SuccessButEmptyResponse` when a network request succeeds but no data
   * is returned.
   */
  SuccessButEmptyResponse,

  /**
   * Use `Success` when a network request succeeds
   */
  Success,
}
