import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { MetricActions, MetricState } from '@patient-ui/patient-web/store';
import {
  DownloadInvoiceRequest,
  IFlagValueResponse,
  IInsuranceUpdateDetail,
  IInvoiceLookup,
  IInvoiceSearch,
  IPaymentPlanMetric,
  InsuranceProvider,
  Invoice,
  InvoiceSummary,
  PaymentAuthorizationRequest,
  PaymentAuthorizationResponse,
  PaymentPlanAuthorizationRequest,
  PaymentPlanAuthorizationResponse,
  PaymentPlanRequest,
  PaymentPlanResponse,
  PaymentReceiptResponse,
  PortalUser,
  ProcessingInvoice,
  QueryAccessionedOrderRequest,
  QueryAccessionedOrderResponse,
  UpdateInsuranceFailedResponse,
} from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { AuthState } from '../auth/auth.reducer';
import { selectAuthState } from '../auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  uuid = '';
  browserInfo = `Browser: ${navigator.userAgent}`;
  isLoggedIn = false;
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService,
    private authStore: Store<AuthState>,
    private metricStore: Store<MetricState>
  ) {
    this.authStore
      .select(selectAuthState)
      .subscribe((state) => (this.isLoggedIn = state.loggedIn));
  }

  authorizePayment(
    authorizationRequest: PaymentAuthorizationRequest,
    campaignId: string
  ): Observable<PaymentAuthorizationResponse> {
    let pymtUrl = '';
    if (authorizationRequest.isGuestUser) {
      pymtUrl = `${this.envService.baseUrl}/guest/invoices/payment`;
    } else {
      pymtUrl = `${this.envService.baseUrl}/protected/patients/current/invoices/payment/submit`;
    }

    let queryParams = new HttpParams();
    if (campaignId) {
      queryParams = queryParams.append('campaign', campaignId);
    }

    return this.http.post<PaymentAuthorizationResponse>(
      pymtUrl,
      authorizationRequest,
      {
        params: queryParams,
      }
    );
  }

  authorizePaymentPlan(
    authorizationRequest: PaymentPlanAuthorizationRequest,
    campaignId: string
  ): Observable<PaymentPlanAuthorizationResponse> {
    let pymtUrl = '';
    pymtUrl = `${this.envService.baseUrl}/protected/patients/current/paymentplans/createpaymentplan`;

    let queryParams = new HttpParams();
    if (campaignId) {
      queryParams = queryParams.append('campaign', campaignId);
    }

    return this.http.post<PaymentPlanAuthorizationResponse>(
      pymtUrl,
      authorizationRequest,
      {
        params: queryParams,
      }
    );
  }

  generateReceipt(
    confirmationNumber: string
  ): Observable<PaymentReceiptResponse> {
    return this.http.get<PaymentReceiptResponse>(
      `${this.envService.baseUrl}/guest/invoices/receiptEmail?confirmationNumber=${confirmationNumber}`
    );
  }

  getInvoice(
    invoiceSearch: IInvoiceSearch,
    campaignId?: string
  ): Observable<Invoice> {
    const invoiceSearchUrl = `${this.envService.baseUrl}/${
      this.isLoggedIn ? 'protected' : 'guest'
    }/invoices`;

    let queryParams = new HttpParams()
      .append('invoiceNumber', invoiceSearch.invoiceNumber as string)
      .append('zipCode', invoiceSearch.zipCode);

    if (invoiceSearch.dateOfBirth) {
      queryParams = queryParams.append('patientDob', invoiceSearch.dateOfBirth);
    }
    if (campaignId) {
      queryParams = queryParams.append('campaign', campaignId);
    }

    return this.http.get<Invoice>(invoiceSearchUrl, {
      params: queryParams,
    });
  }

  getPatientInvoice(invoiceLookup: IInvoiceLookup): Observable<Invoice> {
    const invoiceLookupUrl = `${this.envService.baseUrl}/protected/patients/current/invoices/${invoiceLookup.invoiceNumber}/${invoiceLookup.campaignId}`;
    return this.http.get<Invoice>(invoiceLookupUrl);
  }

  getInvoicePdf(invoiceRequest: DownloadInvoiceRequest): Observable<Blob> {
    let linkedAccountsUrlSegment = '';
    /* Failsafe to make sure the document key is a top-level field */
    if (!invoiceRequest.documentKey) {
      invoiceRequest.documentKey = invoiceRequest.invoice?.documentKey
        ? invoiceRequest.invoice.documentKey
        : ``;
    }
    if (
      invoiceRequest.currentPatient &&
      invoiceRequest.currentPatient.isPrimary === 'dependent' &&
      invoiceRequest.currentPatient.id.toString() !== '0'
    ) {
      linkedAccountsUrlSegment = this.getLinkedAccountsURLSegment(
        invoiceRequest.currentPatient.recipientLinkedId
          ? invoiceRequest.currentPatient.recipientLinkedId
          : invoiceRequest.currentPatient.linkedId
      );
    }
    const invoicePdfUrl = `${this.envService.baseUrl}/protected/patients/current${linkedAccountsUrlSegment}/invoices/pdf?documentKey=${invoiceRequest.documentKey}`;
    return this.http.get(invoicePdfUrl, {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob',
    });
  }

  getInvoiceSummary(currentPatient: PortalUser): Observable<InvoiceSummary> {
    let linkedAccountsUrlSegment = '/linkedAccounts';
    if (
      currentPatient &&
      currentPatient.isPrimary === 'dependent' &&
      currentPatient.id.toString() !== '0'
    ) {
      linkedAccountsUrlSegment = this.getLinkedAccountsURLSegment(
        currentPatient.recipientLinkedId
          ? currentPatient.recipientLinkedId
          : currentPatient.linkedId
      );
    }
    let allUrlSegment = '';
    if (currentPatient && currentPatient.id.toString() === '0') {
      allUrlSegment = '?all=true';
    }
    const invoiceSummaryUrl = `${this.envService.baseUrl}/protected/patients/current${linkedAccountsUrlSegment}/invoices/summary${allUrlSegment}`;
    return this.http.get<InvoiceSummary>(invoiceSummaryUrl);
  }

  createPaymentPlan(
    paymentPlanRequest: PaymentPlanRequest
  ): Observable<PaymentPlanResponse> {
    return this.http.post<PaymentPlanResponse>(
      `${this.envService.baseUrl}/protected/patients/current/paymentplans/calculatepayments`,
      paymentPlanRequest
    );
  }

  getProcessingInvoices(): Observable<ProcessingInvoice[]> {
    // TODO: CALL THE BACKEND WILL THAT'S ADDED
    // TODO For mock data:
    const resp: ProcessingInvoice[] = [];
    return of(resp);
  }

  updateInsurance(
    invoiceOrSpecimenNumber: string,
    invoiceZipCode: string,
    insuranceData: IInsuranceUpdateDetail,
    campaign: string
  ): Observable<unknown | UpdateInsuranceFailedResponse | undefined> {
    let queryParams = new HttpParams();
    if (campaign) {
      queryParams = queryParams.append('campaign', campaign);
    }

    return this.http.post<unknown>(
      `${this.envService.baseUrl}/guest/invoices/${invoiceOrSpecimenNumber}/${invoiceZipCode}/insurance/update`,
      insuranceData,
      {
        params: queryParams,
      }
    );
  }

  queryForAccessionedOrder(
    queryRequest: QueryAccessionedOrderRequest
  ): Observable<QueryAccessionedOrderResponse> {
    return this.http.post<QueryAccessionedOrderResponse>(
      `${this.envService.baseUrl}/guest/guest/accessioned-order`,
      queryRequest
    );
  }

  getInsuranceProviderList(): Observable<InsuranceProvider[]> {
    return this.http.get<InsuranceProvider[]>(
      `${this.envService.baseUrl}/guest/insurance/payerList`
    );
    // TODO For mock data
    // const provider: InsuranceProvider[] = [{
    //   payerCode: '1234',
    //   payerType: 'Other',
    //   payerId: 1,
    //   payerName: 'Not Listed',
    //   alwaysShowDuringSearch: false
    // }];
    // return of(provider);
  }

  getPdfDetails(id: string): Observable<Blob> {
    const pdfServiceUrl = `${this.envService.baseUrl}/guest/invoices/paymentPdf?transactionId=${id}`;
    return this.http.get(pdfServiceUrl, {
      headers: this.getHeaders(),
      responseType: 'blob',
    });
  }

  getApplePaySupportFlag(): Observable<IFlagValueResponse> {
    return this.http.get<IFlagValueResponse>(
      `${this.envService.baseUrl}/guest/invoices/applePaySupportFlag`
    );
  }

  getLinkedAccountsURLSegment(linkedId?: string): string {
    return `/linkedAccounts${linkedId ? `/${linkedId}` : ''}`;
  }

  private getHeaders() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/pdf');
    return headers;
  }
  logPaymentPlanMetric(
    alertText: string,
    invoice: string,
    eventCode: string,
    templateId?: string,
    showErrorFlag?: boolean,
    reasonCode?: string
  ) {
    this.uuid = this.uuid
      ? this.uuid
      : Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    const paymentPlanMetric: IPaymentPlanMetric = {
      invoiceNumber: invoice,
      dashboardId: 'PaymentPlanMetric',
      eventCode: eventCode,
      templateId: templateId || '',
      userType: 'Registered',
      alertMsg:
        alertText +
        ' USER EMAIL ==> ' +
        sessionStorage.getItem('metricEmail') +
        ' <--BROWSER DETAILS--> ' +
        this.browserInfo,
      reasonCode: reasonCode,
      sessionID: this.uuid,
      showErrorFlag: showErrorFlag,
    };
    this.metricStore.dispatch(
      MetricActions.logPaymentPlanMetric({ metrics: paymentPlanMetric })
    );
  }
}
