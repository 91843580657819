import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor() {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const externalUrl = route.paramMap.get('externalUrl');
    if (externalUrl) {
      return new Promise<boolean>((resolve, reject) => {
        try {
          resolve(!!window.open(externalUrl, '_self'));
        } catch (e) {
          reject(e);
        }
      });
    }
    return false;
  }
}
