export class SecureTokenResp {
  awsUrl!: string;
  acceptorId!: string;
  refNum!: string;
  returnUrl!: string;
  secureToken!: string;
  timeStamp!: string;

  reasonCode!: string;
  reasonDescription!: string;
  isSuccessful!: boolean;
  paymentAccountId!: string;
}
