import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'patient-ui-payment-terms-conditions',
  template: `
    <p>
      Labcorp provides on-line electronic payment transaction processing
      services using software programs proprietary to Labcorp.
    </p>
    <p>
      Labcorp endeavors to use its best efforts to process all transactions in
      conformance with the information provided by the user. Labcorp shall have
      no liability for incomplete or erroneous transactions caused by the user's
      provision of inaccurate information, by the user's lack of sufficient
      funds or credit, by misuse of the user's password or for circumstances not
      subject to Labcorp's control. Labcorp is not responsible for providing the
      goods or services for which payment is made and shall have no liability
      for their delivery, quality, performance or any resulting damages. Labcorp
      reserves the right not to process any transaction which it, in its sole
      discretion, believes may be erroneous, unauthorized or otherwise invalid.
    </p>
    <p>
      Except to the extent necessary to process a transaction, Labcorp endeavors
      to use its best efforts to maintain the confidentiality of all information
      provided by the user.
    </p>
    <p class="text-uppercase">
      THE FOREGOING WARRANTIES ARE IN LIEU OF, AND Labcorp DISCLAIMS, ALL OTHER
      WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
      WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
    </p>
    <p class="text-uppercase">
      IN NO EVENT SHALL Labcorp BE LIABLE FOR ANY TYPE OF INCIDENTAL, PUNITIVE,
      INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES, EVEN IF ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGES, WHETHER ARISING UNDER THEORY OF CONTRACT,
      TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE. Labcorp'S
      LIABILITY WITH RESPECT TO ANY CLAIM SHALL BE LIMITED TO A REFUND OF ANY
      REVENUE RECEIVED BY Labcorp FOR PROCESSING THE SPECIFIC TRANSACTION GIVING
      RISE TO THE CLAIM.
    </p>
    <p>
      Labcorp reserves the right to amend these terms and conditions without
      prior notice by posting on this site.
    </p>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentTermsConditionsComponent {
  constructor() {}
}
