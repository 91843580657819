export const addressTypes = [
  {
    display: 'Home',
    value: 'HOME',
  },
  {
    display: 'Work',
    value: 'WORK',
  },
  {
    display: 'Unknown',
    value: 'UNKNOWN',
  },
];
