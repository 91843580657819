import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SuiModalModule, SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterState,
  StoreRouterConnectingModule,
  routerReducer,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { PatientWebFeatureShellModule } from '@patient-ui/patient-web/feature-shell';
import {
  AuthConfigService,
  BOOTSTRAP_ENVIRONMENT_TOKEN,
  EnvironmentService,
  SelectivePreloadingStrategyService,
} from '@patient-ui/shared-ui/utils';

import { AppComponent } from './app.component';
import { OktaAuthConfig } from './auth-config';
import { ConfirmModal, ConfirmModalComponent } from './modal/confirm.component';
import { AddHeaderInterceptor } from './shared/http/addHeaderInterceptor';
import { bootstrapEnvironment } from '../environments/bootstrap/environment';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [AppComponent, ConfirmModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(options),
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'enabled',
      useHash: false,
      preloadingStrategy: SelectivePreloadingStrategyService,
      relativeLinkResolution: 'legacy',
      enableTracing: bootstrapEnvironment.isDebugEnabled,
    }),
    PatientWebFeatureShellModule,
    StoreModule.forRoot(
      { router: routerReducer },
      {
        metaReducers: bootstrapEnvironment.isDebugEnabled ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionSerializability: false,
          strictStateSerializability: false,
        },
      }
    ),
    OktaAuthModule,
    EffectsModule.forRoot([]),
    bootstrapEnvironment.isDebugEnabled
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          name: 'Patient Web NgRx Store',
        })
      : [],
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    SuiModalModule,
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (es: EnvironmentService, acs: AuthConfigService) => () =>
        es.loadEnvironment().then(() => acs.setupAuthConfig()),
      deps: [EnvironmentService, AuthConfigService],
      multi: true,
    },
    {
      provide: OKTA_CONFIG,
      deps: [OktaAuthConfig],
      useFactory: (oktaConfig: OktaAuthConfig) => ({
        oktaAuth: new OktaAuth({
          ...oktaConfig.config,
        }),
        onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
          const triggerLogin = async () => {
            await oktaAuth.signInWithRedirect();
          };
          if (
            !oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated
          ) {
            triggerLogin();
          } else {
            const modalService = injector.get(SuiModalService);
            modalService
              .open(
                new ConfirmModal(
                  'Do you want to re-authenticate?',
                  'Auth required',
                  'Yes',
                  'No'
                )
              )
              .onApprove(triggerLogin)
              .onDeny(() => {});
          }
        },
      }),
    },
    {
      provide: BOOTSTRAP_ENVIRONMENT_TOKEN,
      useValue: bootstrapEnvironment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },

    EnvironmentService,
    DeviceDetectorService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
