export class PaymentechPostMsg {
  messageId!: string;
  reply!: boolean;
  subject!: string;
  content!: Content;
}

export class Content {
  errorCode!: string[];
  delimiter!: string;
  gatewayCode!: string;
  gatewayMessage!: string;
  xStatus!: string;
  code!: string;
  message!: string;
  uID!: string;
}
