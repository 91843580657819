import { Invoice } from './invoice.model';
import { PaymentHistory } from './paymenthistory.model';

export class InvoiceSummary {
  collectionsInvoices?: Invoice[];
  currentInvoices?: Invoice[];
  pastDueInvoices?: Invoice[];
  totalNumberOfInvoices?: number;
  paymentHistory?: PaymentHistory[];
}
