/**
 * Status of an editible section edit toggle mode
 */
export enum SectionEditMode {
  /**
   * Use `Static` when section is displaying static untouched information
   */
  Static,

  /**
   * Use `Editing` when section is in edit mode, showing a form for updating
   */
  Editing,

  /**
   * Use `Preview` if needing a preview before submiting update.
   */
  Preview,

  /**
   * Use `Success` if needing a success message to show before returning to
   * static
   */
  Success,

  /**
   * Use `Failure` if needing a success message to show before returning to
   * static or edit
   */
  Failure,
}

export enum ProfileSectionEditing {
  none,
  profileDemographics,
  profileAddressPrimary,
  profileAddressSecondary,
  profilePassword,
  profileEmail,
  profilePhonePrimary,
  profilePhoneSecondary,
  dependentAddress,
}
