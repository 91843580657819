import { createReducer, on } from '@ngrx/store';

import {
  MFACatalogItem,
  OktaEnrolledFactor,
  OktaFactorEnrollResponse,
} from '@patient-ui/shared/models';

import { MFAActions } from './mfa.actions';

export const MFAFeatureKey = 'mfa';

export interface FactorsState {
  enrolledFactors: OktaEnrolledFactor[];
  factorCatalog: MFACatalogItem[];
  enrollingSmsFactor: boolean;
  enrollmentError: unknown;
  activatingFactor: boolean;
  activationError: unknown;
  deletingFactor: boolean;
  deletionError: unknown;
  loading: boolean;
  error: unknown;
  enrollFactorResponse?: OktaFactorEnrollResponse;
  mfaTechDifficulties: boolean;
  mfaSelectionStatus: boolean | null | undefined;
}

export const initialState: FactorsState = {
  enrolledFactors: [],
  factorCatalog: [],
  enrollingSmsFactor: false,
  enrollmentError: null,
  activatingFactor: false,
  activationError: null,
  deletingFactor: false,
  deletionError: null,
  loading: false,
  error: null,
  enrollFactorResponse: undefined,
  mfaTechDifficulties: false,
  mfaSelectionStatus: undefined,
};

export const mfaReducer = createReducer(
  initialState,
  on(MFAActions.loadEnrolledFactors, (state) => ({
    ...state,
    enrollFactorResponse: undefined,
    loading: true,
    error: null,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.loadEnrolledFactorsSuccess, (state, { factors }) => ({
    ...state,
    enrolledFactors: factors,
    loading: false,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.loadEnrolledFactorsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    mfaTechDifficulties: true,
  })),
  on(MFAActions.loadMFACatalog, (state) => ({
    ...state,
    loading: true,
    error: null,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.loadMFACatalogSuccess, (state, { factorCatalog }) => ({
    ...state,
    factorCatalog,
    loading: false,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.loadMFACatalogFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    mfaTechDifficulties: true,
  })),
  on(MFAActions.enrollFactor, (state) => ({
    ...state,
    enrollingSmsFactor: true,
    enrollmentError: null,
    loading: true,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.enrollFactorSuccess, (state, { enrollFactorResponse }) => ({
    ...state,
    enrollFactorResponse,
    enrollingSmsFactor: false,
    enrollmentError: null,
    loading: false,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.enrollFactorFailure, (state, { error }) => ({
    ...state,
    enrollingSmsFactor: false,
    enrollmentError: error,
    loading: false,
    mfaTechDifficulties: true,
  })),
  on(MFAActions.activateFactor, (state) => ({
    ...state,
    activatingFactor: true,
    activationError: null,
    loading: true,
  })),
  on(MFAActions.activateFactorSuccess, (state) => ({
    ...state,
    activatingFactor: false,
    activationError: null,
    loading: false,
  })),
  on(MFAActions.activateFactorFailure, (state, { error }) => ({
    ...state,
    activatingFactor: true,
    activationError: error,
    loading: false,
  })),
  on(MFAActions.deleteFactor, (state) => ({
    ...state,
    deletingFactor: false,
    deletionError: null,
    loading: true,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.deleteFactorSuccess, (state) => ({
    ...state,
    deletingFactor: true,
    deletionError: null,
    loading: false,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.deleteFactorFailure, (state, { error }) => ({
    ...state,
    deletingFactor: false,
    deletionError: error,
    loading: false,
    mfaTechDifficulties: true,
  })),
  on(MFAActions.retriveMFAStatus, (state) => ({
    ...state,
    loading: true,
    error: null,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.retriveMFAStatusSuccess, (state, { mfaSelectionStatus }) => ({
    ...state,
    loading: false,
    mfaSelectionStatus,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.retriveMFAStatusFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
    mfaTechDifficulties: true,
  })),
  on(MFAActions.setMFAPreference, (state) => ({
    ...state,
    loading: true,
    error: null,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.setMFAPreferenceSuccess, (state) => ({
    ...state,
    loading: false,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.setMFAPreferenceFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
    mfaTechDifficulties: true,
  })),
  on(MFAActions.clearEnrollFactorResponse, (state) => ({
    ...state,
    enrollFactorResponse: undefined,
    enrollingSmsFactor: false,
    enrollmentError: null,
    loading: false,
    mfaTechDifficulties: false,
  })),
  on(MFAActions.clearEnrolledFactors, (state) => ({
    ...state,
    enrolledFactors: [],
    loading: false,
    error: null,
    mfaTechDifficulties: false,
  }))
);
