import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PendoState, pendoFeatureKey } from './pendo.reducer';

export const pendoStateSelector = createFeatureSelector<PendoState>(
  pendoFeatureKey
);

export const selectPendoInitializeSuccess = createSelector(
  pendoStateSelector,
  (state) => state.initializeSuccess
);

export const selectPendoRegisteredUser = createSelector(
  pendoStateSelector,
  (state) => state.registeredPatient
);
