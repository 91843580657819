<ng2-pdfjs-viewer
  #pdfViewerOnLoad
  [externalWindow]="false"
  [downloadFileName]="'Labcorp-Receipt'"
  [openFile]="false"
  [viewBookmark]="false"
  [download]="true"
>
</ng2-pdfjs-viewer>
<ng-template #securityModalRef let-modal>
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title flex-fill text-center">
      Enter Patient's Date of Birth
    </h2>
    <button type="button" class="btn btn-link close-icon" aria-label="Close" (click)="handleCancel()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <form [formGroup]="dobForm">
    <div class="modal-body">
      <div class="custom-modal-body">
        <ui-alert
          [alertStatus]="'danger'"
          [hidden]="(isTechDifficulties$ | async) === false"
          alertText="We are not able to process your receipt at this time. We're sorry for any inconvenience. Please call 800-845-6167 for assistance."
        >
        </ui-alert>
        <ui-alert
          [alertStatus]="'danger'"
          [hidden]="(showAlert$ | async) === false"
          alertText="Date of birth does not match any patient from this transaction. Please try again or call 800-845-6167 for assistance."
        >
        </ui-alert>
        <p>
          For your protection, we ask that you enter the patient's date of birth to view and/or download the receipt.
        </p>
        <p>
          If you are paying invoices for multiple patients, enter the date of birth for any patient from this transaction to see your
          receipt.
        </p>
        <div class="d-flex justify-content-center">
          <div class="form-group col-md-7">
            <label for="invoiceDateOfBirth">Patient's Date of Birth</label>
            <input
              type="text"
              id="invoiceDateOfBirth"
              autocomplete="off"
              class="form-control"
              matInput
              mask="00/00/0000"
              [showMaskTyped]="true"
              [ngClass]="{
                'is-invalid': formControls.dateOfBirth.invalid && formControls.dateOfBirth.dirty
              }"
              formControlName="dateOfBirth"
            />
            <ui-form-field-msg
              id="invoiceDateOfBirth"
              [validationMessages]="validationMessages.dateOfBirth"
              controlName="dateOfBirth"
            ></ui-form-field-msg>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <ui-button [buttonText]="'Cancel'" [rank]="'link'" (handleClick)="handleCancel()"></ui-button>
      <ui-button
        id="btnPdfFormSubmit"
        [inProgress]="btnPdfFormSubmitSpin"
        [buttonText]="'Submit'"
        [buttonDisabled]="!dobForm.valid"
        (handleClick)="handleSubmit()"
      ></ui-button>
    </div>
  </form>
</ng-template>
<ng-template #ieNotificationModalRef let-modal>
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title flex-fill text-center">
      <span class="alert-icon material-icons">warning</span> Browser Not Supported
    </h2>
  </div>
  <div class="modal-body">
    <div class="custom-modal-body">
      <p>
        Internet Explorer is no longer supported. You will be unable to access important features of the site on this browser. We recommend
        using the latest version of Google Chrome or Microsoft Edge.
      </p>

      <p>
        If you're unable to use a different browser, contact Customer Service at 1-800-845-6167, Monday through Friday between 8 am and 8 pm
        EST.
      </p>
    </div>
  </div>
</ng-template>
<ng-template #techIssueModalRef let-modal>
  <div class="modal-header">
    <h2 ngbAutofocus class="modal-title flex-fill text-center">
      <span class="alert-icon material-icons">warning</span> Technical Difficulties
    </h2>
  </div>
  <div class="modal-body">
    <div class="custom-modal-body">
      <p>
        We are not able to process your receipt at this time. We're sorry for any inconvenience. Please call 800-845-6167 for assistance.
      </p>
    </div>
  </div>
</ng-template>
