import { createAction, props } from '@ngrx/store';

import {
  IPortalAddress,
  ISmartyStreetsQuery,
  ISmartyStreetsSuggestResponse,
} from '@patient-ui/shared/models';

const smartyStreetsInitializeSuccess = createAction(
  '[SmartyStreets] SmartyStreets Initialize Success',
  props<{ payload: boolean }>()
);

const smartyStreetsSearch = createAction(
  '[SmartyStreets] SmartyStreets Search',
  props<{ payload: ISmartyStreetsQuery }>()
);

const smartyStreetsSuggestResults = createAction(
  '[SmartyStreets] SmartyStreets Suggest Results',
  props<{ payload: IPortalAddress[] }>()
);

const smartyStreetsAddZipCode = createAction(
  '[SmartyStreets] SmartyStreets Add Zip Code',
  props<{ payload: ISmartyStreetsSuggestResponse }>()
);

const clearSmartyStreetsSuggestResults = createAction(
  '[SmartyStreets] SmartyStreets Clear Suggest Results'
);

export const SmartyStreetsActions = {
  smartyStreetsInitializeSuccess,
  smartyStreetsSearch,
  smartyStreetsSuggestResults,
  smartyStreetsAddZipCode,
  clearSmartyStreetsSuggestResults,
};
