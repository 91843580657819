import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-map-marker',
  templateUrl: './map-marker.component.html',
  styleUrls: ['./map-marker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapMarkerComponent {
  @Input() color: 'blue' | 'red' = 'blue';
}
