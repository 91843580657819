import { Pipe, PipeTransform } from '@angular/core';

import { Counter } from './counter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const counters = new WeakMap<any, Counter>();

@Pipe({
  name: 'counterPipe',
})
export class CounterPipe implements PipeTransform {
  transform(value: unknown): Counter | undefined {
    if (!counters.has(value)) {
      counters.set(value, new Counter());
    }
    return counters.get(value);
  }
}
