import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  IAddress,
  IElemTextValue,
  IPhone,
  IResponseDescription,
} from '@patient-ui/shared/models';

import { PatientState, patientFeatureKey } from './patient.reducer';

export const selectPatientState = createFeatureSelector<PatientState>(
  patientFeatureKey
);

export const selectPatient = createSelector(
  selectPatientState,
  (state) => state.primaryAccountHolder
);

export const selectLoadedPatient = createSelector(
  selectPatientState,
  (state) => state.primaryAccountHolder
);

export const selectPrimaryAccountHolder = createSelector(
  selectPatientState,
  (state) => state.primaryAccountHolder
);

export const selectAddPaymentMethodFlag = createSelector(
  selectPatientState,
  (state) => state.addPaymentMethodFlag
);

export const selectAddPaymentMethodStatus = createSelector(
  selectPatientState,
  (state) => state.addPaymentMethodStatus
);

export const selectGetPatientStatus = createSelector(
  selectPatientState,
  (state) => state.getPatientRequestStatus
);

export const selectLoadLoggedInPatientRequestStatus = createSelector(
  selectPatientState,
  (state) => state.loadLoggedInPatientRequestStatus
);

export const selectDeletePaymentMethodStatus = createSelector(
  selectPatientState,
  (state) => state.deletePaymentMethodStatus
);

export const selectPatientWallet = createSelector(
  selectPatientState,
  (state) => state.patientWallet
);

export const selectGetPatientWalletStatus = createSelector(
  selectPatientState,
  (state) => state.getPatientWalletRequestStatus
);

export const selectPrimaryPatientAddress = createSelector(
  selectPatientState,
  (state) =>
    state?.primaryAccountHolder?.addresses?.filter(
      (address) => address.isPrimary
    )
);

export const selectOpenInvoiceSelectedUser = createSelector(
  selectPatientState,
  (state) => state.openInvoiceSelectedUser
);

export const selectPatientUserList = createSelector(
  selectPatientState,
  (state) => state.patientUserList
);

export const selectPaymentHistorySelectedUser = createSelector(
  selectPatientState,
  (state) => state.paymentHistorySelectedUser
);

export const selectOrderHistorySelectedUser = createSelector(
  selectPatientState,
  (state) => state.orderHistorySelectedUser
);

export const selectAddressToCreateOrUpdate = createSelector(
  selectPatientState,
  (state) => state.createOrUpdateAddress
);

export const selectActivePayments = createSelector(
  selectPatientState,
  (state) => state.activePaymentsList
);

export const selectActivePaymentsStatus = createSelector(
  selectPatientState,
  (state) => state.getActivePaymentStatus
);

export const selectCreateOrUpdateAddressRequestStatus = createSelector(
  selectPatientState,
  (state) => {
    if (state.createOrUpdateAddressRequestStatus === RequestStatus.Success) {
      let newAddress = state.createOrUpdateAddress as IAddress;
      newAddress = {
        ...newAddress,
        id: newAddress.id
          ? newAddress.id
          : Number.parseInt(state.createOrUpdateAddressRequestResponse, 10),
      };
      const responseDescription = {
        status: RequestStatus.Success,
        addressId: newAddress.id,
        address: newAddress,
        addressAction: state.createOrUpdateAddressAction,
        addressOwner: state.createOrUpdateAddressOwner,
        switchToPrimary: state.createOrUpdateAddressSwitchToPrimary,
      };
      return responseDescription;
    } else {
      const responseDescription = {
        status: state.createOrUpdateAddressRequestStatus,
        addressId: -1,
        address: null,
        addressAction: 'none',
        addressOwner: null,
        switchToPrimary: false,
      };
      return responseDescription;
    }
  }
);

export const selectPhoneToCreateOrUpdate = createSelector(
  selectPatientState,
  (state) => state.createOrUpdatePhone
);

export const selectCreateOrUpdatePhoneRequestStatus = createSelector(
  selectPatientState,
  (state) => {
    if (state.createOrUpdatePhoneRequestStatus === RequestStatus.Success) {
      let newPhone = state.createOrUpdatePhone as IPhone;
      if (state.createOrUpdatePhoneRequestResponse != null) {
        newPhone = {
          ...newPhone,
          id: Number.parseInt(state.createOrUpdatePhoneRequestResponse, 10),
        };
      }
      const responseDescription = {
        status: RequestStatus.Success,
        phoneId: newPhone.id,
        phone: newPhone,
        phoneAction: state.createOrUpdatePhoneAction,
        phoneOwner: state.createOrUpdatePhoneOwner,
        switchToPrimary: state.createOrUpdatePhoneSwitchToPrimary,
      };
      return responseDescription;
    } else {
      const responseDescription = {
        status: state.createOrUpdatePhoneRequestStatus,
        phoneId: -1,
        phone: null,
        phoneAction: 'none',
        phoneOwner: null,
        switchToPrimary: false,
      };
      return responseDescription;
    }
  }
);

export const selectPatientDetailsForDemographicUpdate = createSelector(
  selectPatientState,
  (state) => {
    const patientDemographicDetails = {
      patientToUpdate: state.updatePatientDemographicsOwner,
      demographicsToUpdate: state.updatePatientDemographicsData,
    };
    return patientDemographicDetails;
  }
);

export const selectPatientDetailsForNotificationsUpdate = createSelector(
  selectPatientState,
  (state) => {
    const patientNotificationsDetails = {
      patientToUpdate: state.updatePatientNotificationsOwner,
      notificationsToUpdate: state.updatePatientNotificationsData,
      notificationPrimaryEmail: state.updatePatientNotificationsEmail,
    };
    return patientNotificationsDetails;
  }
);

export const selectUpdatePasswordRequestResponse = createSelector(
  selectPatientState,
  (state) => {
    if (state.updatePasswordRequestStatus === RequestStatus.Failure) {
      const responseDescription: IResponseDescription = {
        status: RequestStatus.Failure,
        error: state.updatePasswordRequestError,
      };
      return responseDescription;
    } else {
      const responseDescription: IResponseDescription = {
        status: state.updatePasswordRequestStatus,
        error: null,
      };
      return responseDescription;
    }
  }
);

export const selectUpdatePatientNotificationsRequestStatus = createSelector(
  selectPatientState,
  (state) => {
    if (
      state.updatePatientNotificationsRequestStatus === RequestStatus.Failure
    ) {
      const responseDescription: IResponseDescription = {
        status: RequestStatus.Failure,
        error: state.updatePatientNotificationsRequestError,
      };
      return responseDescription;
    } else {
      const responseDescription: IResponseDescription = {
        status: state.updatePasswordRequestStatus,
        error: null,
      };
      return responseDescription;
    }
  }
);

const selectPrimaryPatientEmailAddress = createSelector(
  selectPatient,
  (state) =>
    state.emailAddresses?.filter((emailAddress) => emailAddress.isPrimary)
);
export const selectMobileNavigation = createSelector(
  selectPatientState,
  (state) => state.isMobile
);
export const selectDependents = createSelector(
  selectPatientState,
  (state) => state.dependentsList
);
export const selectDependentDeleteStatus = createSelector(
  selectPatientState,
  (state) => state.deleteDependentRequestStatus
);
export const selectDependentDeleteIsLoading = createSelector(
  selectPatientState,
  (state) => state.deleteDependentIsLoading
);
export const selectCreateDependentState = createSelector(
  selectPatientState,
  (state) => state.createDependentState
);
export const selectPatientAppointmentsState = createSelector(
  selectPatientState,
  (state) => state.patientAppointmentsState
);
export const selectPatientAppointments = createSelector(
  selectPatientAppointmentsState,
  (state) => state.appointments
);
export const selectPatientAppointmentsRequestStatus = createSelector(
  selectPatientAppointmentsState,
  (state) => state.requestStatus
);
export const selectPatientAppointmentsLoading = createSelector(
  selectPatientAppointmentsState,
  (state) => state.appointmentsLoading
);
export const selectPatientAppointmentsEncryptedLpid = createSelector(
  selectPatientAppointmentsState,
  (state) => state.encryptedLpid
);
export const selectPatientAppointmentsDropdownUser = createSelector(
  selectPatientAppointmentsState,
  (state) => state.selectedPatientDropdownUser
);
export const selectPatientAppointmentsError = createSelector(
  selectPatientAppointmentsState,
  (state) => state.error
);
export const selectPatientAppointmentsPatientList = createSelector(
  selectPrimaryAccountHolder,
  selectDependents,
  (pah, deps) => {
    let list = [pah];
    if (deps) {
      list = [...list, ...deps];
    }
    return list;
  }
);
export const selectPatientAppointmentsDropdownPatientList = createSelector(
  selectPatientUserList,
  (list) =>
    list.map(
      (patient) =>
        ({
          text: patient.displayName || '',
          value: patient.id.toString() || '',
        } as IElemTextValue)
    )
);
export const selectPatientAppointmentsFilteredAppointments = createSelector(
  selectPatientAppointments,
  selectPatientAppointmentsDropdownUser,
  (appts, selectedUser) => {
    if (
      appts.length &&
      !!selectedUser &&
      selectedUser.displayName !== 'All Patients'
    ) {
      return appts.filter((appt) => appt.id === selectedUser.id);
    }
    return appts;
  }
);
export const selectPatientAppointmentsViewModel = createSelector(
  selectPatientAppointmentsLoading,
  selectPatientAppointmentsFilteredAppointments,
  selectPatientAppointmentsDropdownUser,
  (loading, filteredAppts, selectedDdUser) => ({
    loading,
    filteredAppts,
    selectedDdUser,
  })
);
export const selectUpdatePatientRequestStatus = createSelector(
  selectPatientState,
  (state) => ({
    createOrUpdateAddressRequestStatus:
      state.createOrUpdateAddressRequestStatus,
    createOrUpdatePhoneRequestStatus: state.createOrUpdatePhoneRequestStatus,
    updatePatientDemographicsRequestStatus:
      state.updatePatientDemographicsRequestStatus,
    updatePatientNotificationsRequestStatus:
      state.updatePatientNotificationsRequestStatus,
  })
);
export const patientQuery = {
  selectPatientState,
  selectPatient,
  selectLoadedPatient,
  selectPrimaryAccountHolder,
  selectAddPaymentMethodFlag,
  selectDeletePaymentMethodStatus,
  selectGetPatientStatus,
  selectLoadLoggedInPatientRequestStatus,
  selectPrimaryPatientAddress,
  selectOpenInvoiceSelectedUser,
  selectPatientUserList,
  selectPaymentHistorySelectedUser,
  selectOrderHistorySelectedUser,
  selectPrimaryPatientEmailAddress,
  selectMobileNavigation,
  selectPatientWallet,
  selectGetPatientWalletStatus,
  selectUpdatePasswordRequestResponse,
  selectUpdatePatientNotificationsRequestStatus,
  selectDependents,
  selectDependentDeleteStatus,
  selectDependentDeleteIsLoading,
  selectCreateOrUpdateAddressRequestStatus,
  selectAddressToCreateOrUpdate,
  selectCreateOrUpdatePhoneRequestStatus,
  selectPhoneToCreateOrUpdate,
  selectPatientDetailsForDemographicUpdate,
  selectCreateDependentState,
  selectPatientDetailsForNotificationsUpdate,
  selectPatientAppointmentsState,
  selectPatientAppointments,
  selectPatientAppointmentsRequestStatus,
  selectPatientAppointmentsLoading,
  selectPatientAppointmentsEncryptedLpid,
  selectPatientAppointmentsDropdownUser,
  selectPatientAppointmentsError,
  selectPatientAppointmentsPatientList,
  selectPatientAppointmentsDropdownPatientList,
  selectPatientAppointmentsFilteredAppointments,
  selectPatientAppointmentsViewModel,
  selectUpdatePatientRequestStatus,
  selectActivePayments,
};
