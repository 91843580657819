import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  MedicalRecordShareConsent,
  MedicalRecordShareInput,
  MedicalRecordShareResponse,
} from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class MedicalRecordShareService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  verifyMedicalRecordShareInputs(
    medicalRecordShareInput: MedicalRecordShareInput
  ): Observable<MedicalRecordShareResponse> {
    const medicalRecordShareVerifyUrl = `${this.envService.baseUrl}/guest/guest/celeste-verify`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<MedicalRecordShareResponse>(
      medicalRecordShareVerifyUrl,
      JSON.stringify(medicalRecordShareInput),
      {
        headers: headers,
      }
    );
  }

  submitMedicalRecordShareConsent(
    medicalRecordShareConsent: MedicalRecordShareConsent
  ): Observable<void> {
    const medicalRecordShareSubmitUrl = `${this.envService.baseUrl}/guest/guest/celeste-submit`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<void>(
      medicalRecordShareSubmitUrl,
      JSON.stringify(medicalRecordShareConsent),
      {
        headers: headers,
      }
    );
  }
}
