<input
  #inputEl
  *ngFor="let input of inputs.controls; let i = index"
  class="input-box {{ invalidInput ? 'is-invalid' : '' }}"
  (focus)="handleFocus($event)"
  (keypress)="handleKeyPress($event, i)"
  (input)="handleInput()"
  (keydown)="handleKeyDown($event, i)"
  (paste)="handlePaste($event, i)"
  [formControl]="$any(input)"
  [attr.autocomplete]="i === 0 ? 'one-time-code' : null"
  type="text"
  inputmode="numeric"
  id="oneTimeCodeMFA"
/>
