import { createAction, props } from '@ngrx/store';

import { BillingNotificationsOptIn } from '@patient-ui/shared/models';

export interface PhoneOptInResponse {
  optedIn: boolean;
  message: string;
}

export const billingTextsOptIn = createAction(
  '[Phone] Billing Texts Opt-In',
  props<{ optInData: BillingNotificationsOptIn }>()
);

export const billingTextsOptInFailure = createAction(
  '[Phone] Billing Texts Opt-In Failure'
);

export const billingTextsOptInSuccess = createAction(
  '[Phone] Billing Texts Opt-In Success',
  props<{ response: PhoneOptInResponse }>()
);
