import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'ui-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  @HostBinding('class.row') isRow = true;
  @HostBinding('class.text-center') isTextCentered = true;
  @HostBinding('class.mb-5') hasBottomMargin = true;

  @Input() pageTitles: string[] = [];
  @Input() completedSteps = 0;
}
