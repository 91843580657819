import { createReducer, on } from '@ngrx/store';

import { ProfileSectionEditing, RequestStatus } from '@patient-ui/shared/enums';
import { IPhoneValidationResponse } from '@patient-ui/shared/models';

import * as ProfileActions from './profile.actions';

export const profileFeatureKey = 'profile';

export interface ProfileState {
  profileAddressToDeleteId: number;
  deleteAddressRequestStatus: RequestStatus;
  deletePatientAccountRequestStatus: RequestStatus;
  sectionInEditMode: ProfileSectionEditing;
  validatePhoneRequestStatus: RequestStatus;
  validatePhoneRequestResponse?: IPhoneValidationResponse;
}

export const ProfileInitialState: ProfileState = {
  profileAddressToDeleteId: -1,
  deleteAddressRequestStatus: RequestStatus.NotSent,
  deletePatientAccountRequestStatus: RequestStatus.NotSent,
  sectionInEditMode: ProfileSectionEditing.none,
  validatePhoneRequestStatus: RequestStatus.NotSent,
  validatePhoneRequestResponse: undefined,
};

export const ProfileReducer = createReducer(
  ProfileInitialState,

  on(ProfileActions.deletePatientAccount, (state) => ({
    ...state,
    deletePatientAccountRequestStatus: RequestStatus.Pending,
  })),

  on(ProfileActions.deletePatientAccountSuccess, (state) => ({
    ...state,
    deletePatientAccountRequestStatus: RequestStatus.Success,
  })),

  on(ProfileActions.deletePatientAccountFailure, (state) => ({
    ...state,
    deletePatientAccountRequestStatus: RequestStatus.Failure,
  })),

  on(ProfileActions.setSectionInEditMode, (state, { section }) => ({
    ...state,
    sectionInEditMode: section,
  })),

  on(ProfileActions.validatePhone, (state) => ({
    ...state,
    validatePhoneRequestStatus: RequestStatus.Pending,
    validatePhoneRequestResponse: undefined,
  })),

  on(ProfileActions.validatePhoneSuccess, (state, { response }) => ({
    ...state,
    validatePhoneRequestStatus: RequestStatus.Success,
    validatePhoneRequestResponse: response,
  })),

  on(ProfileActions.validatePhoneFailure, (state) => ({
    ...state,
    validatePhoneRequestStatus: RequestStatus.Failure,
    validatePhoneRequestResponse: undefined,
  })),

  on(ProfileActions.resetPhone, (state) => ({
    ...state,
    validatePhoneRequestStatus: RequestStatus.NotSent,
    validatePhoneRequestResponse: undefined,
  }))
);
