import { Time } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'ui-location-list-item',
  templateUrl: './location-list-item.component.html',
  styleUrls: ['./location-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationListItemComponent {
  @HostBinding('class.d-block') isBlock = true;

  @Input() number!: number;
  @Input() isWalgreens = false;
  @Input() isBusy = false;
  @Input() address!: string[];
  @Input() distanceMi!: number;
  @Input() appointments: string[] = [];
  @Input() reasonForNoAppointments =
    'There are no available appointments at this location.';
  @Input() waitTime!: Time;

  @Output() headerClicked = new EventEmitter<void>();
  @Output() appointmentSelected = new EventEmitter<string>();
}
