import { IPatient, Patient } from '@patient-ui/shared/models';

export const patientFactory = (json: IPatient, isDependent: boolean) => {
  const patient = new Patient();
  patient.id = json.id;
  patient.loginEmail = json.loginEmail;
  patient.firstName = json.firstName;
  patient.middleName = json.middleName;
  patient.lastName = json.lastName;
  patient.dateOfBirth = json.dateOfBirth;
  patient.addresses = json.addresses;
  patient.phones = json.phones;
  patient.emailAddresses = json.emailAddresses;
  patient.hashId = json.hashId;
  patient.gender = json.gender?.toUpperCase();
  patient.ethnicity = json.ethnicity?.toUpperCase();
  patient.race = json.race?.toUpperCase();
  patient.dependents = [];
  patient.notificationPreferences = json.notificationPreferences;
  patient.relationshipType = json.relationshipType;
  patient.usePrimaryAddressOfPrimaryPatient =
    json.usePrimaryAddressOfPrimaryPatient;
  patient.usePrimaryPhoneOfPrimaryPatient =
    json.usePrimaryPhoneOfPrimaryPatient;
  patient.usePrimaryEmailOfPrimaryPatient =
    json.usePrimaryEmailOfPrimaryPatient;
  patient.authenticationQuizStatus = json.authenticationQuizStatus || '';
  patient.hasFailedMaxVerificationAttempts =
    json.hasFailedMaxVerificationAttempts;
  patient.clinicalTrialsPreferenceStatus = json.clinicalTrialsPreferenceStatus?.toUpperCase();
  patient.profileVerificationDate = json.profileVerificationDate;
  patient.emailVerified = json.emailVerified;
  patient.isDependent = isDependent;
  return patient;
};
