import { createAction, props } from '@ngrx/store';

import {
  FastRegistrationError,
  FastRegistrationInput,
  FastRegistrationSubmitRequest,
  FastRegistrationVerifyResponse,
} from '@patient-ui/shared/models';

export const VerifyFastRegistration = createAction(
  '[FastRegistration] Verify Fast Registration',
  props<{ payload: FastRegistrationInput }>()
);
export const VerifyFastRegistrationSuccess = createAction(
  '[FastRegistration] Verify Fast Registration Success',
  props<{ response: FastRegistrationVerifyResponse }>()
);
export const VerifyFastRegistrationFailure = createAction(
  '[FastRegistration] Verify Fast Registration Failure',
  props<{ payload: FastRegistrationError }>()
);
export const SubmitFastRegistration = createAction(
  '[FastRegistration] Submit Fast Registration',
  props<{ payload: FastRegistrationSubmitRequest }>()
);
export const SubmitFastRegistrationSuccess = createAction(
  '[FastRegistration] Submit Fast Registration Success',
  props<{ response: FastRegistrationVerifyResponse }>()
);
export const SubmitFastRegistrationFailure = createAction(
  '[FastRegistration] Submit Fast Registration Failure',
  props<{ payload: FastRegistrationError }>()
);

export const FastRegistrationActions = {
  VerifyFastRegistration,
  VerifyFastRegistrationSuccess,
  VerifyFastRegistrationFailure,
  SubmitFastRegistration,
  SubmitFastRegistrationSuccess,
  SubmitFastRegistrationFailure,
};
