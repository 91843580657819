import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { IElemTextValue } from '@patient-ui/shared/models';

@Component({
  selector: 'ui-dropdown-link',
  templateUrl: './dropdown-link.component.html',
  styleUrls: ['./dropdown-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownLinkComponent {
  @Input() iconOpen = 'expand_more';
  @Input() iconClose = 'expand_less';
  @Input() selectedText = 'Placeholder';
  @Input() addClass = 'd-inline-block';
  @Input() id = 'dropDownLink';
  @Input() listItems: IElemTextValue[] = [];
  @Input() showClearFilter = false;
  /** The placement of the drop-down menu, sent directly to ngbDropdown */
  @Input() placement = 'bottom-left bottom-right top-left top-right';
  @Output() handleClick: EventEmitter<IElemTextValue> = new EventEmitter();

  onClick(selectedItem: IElemTextValue) {
    this.handleClick.emit(selectedItem);
  }
}
