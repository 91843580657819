import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-dropdown-fields',
  templateUrl: './dropdown-fields.component.html',
  styleUrls: ['./dropdown-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownFieldsComponent {
  @Input() iconOpen = 'more_vert';
  @Input() iconClose = 'close';
  @Input() btnClass = 'btn btn-light px-2';
  @Input() divClass = '';
  @Input() autoClose: true | false | 'outside' | 'inside' = false;
  /** The placement of the drop-down menu, sent directly to ngbDropdown */
  @Input() placement = 'bottom-left bottom-right top-left top-right';
  /** (Optional) The breakpoint at which to forcefully render the drop-down
   * menu in-line */
  @Input() expandSize: '' | 'sm' | 'md' | 'lg' | 'xl' = '';
  @Input() btnText = '';
}
