import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  FactorsState,
  PatientState,
  getMFASelectionStatus,
  selectPatientState,
} from '@patient-ui/patient-web/store';
import { Patient } from '@patient-ui/shared/models';

import { MfaSetupConfirmationComponent } from '../mfa-setup-confirmation/mfa-setup-confirmation.component';

@Component({
  selector: 'patient-ui-portal-timout-modal',
  templateUrl: './portal-timout-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalTimoutModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalPortalIdle') modal!: TemplateRef<unknown>;
  @Output() logoutDecision = new EventEmitter<boolean>();
  destroyed = new Subject();
  timedOut = false;
  timeToIdle = 60 * 15;
  timeToTimeout = 60 * 5;
  mfaStatus$ = this.mfaStore.select(getMFASelectionStatus);
  patient?: Patient;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: NgbModal,
    private patientStore: Store<PatientState>,
    private mfaStore: Store<FactorsState>
  ) {
    idle.setIdle(this.timeToIdle);
    // sets a timeout period of 5 minutes. after 15 minutes of inactivity, the user will be considered timed out.
    idle.setTimeout(this.timeToTimeout);

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.reset();
    });

    idle.onTimeout.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.modalService.dismissAll();
      sessionStorage.clear();
      window.history.replaceState(null, '', '/');
      this.logoutDecision.emit(true);
    });

    idle.onIdleStart.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.openModal();
    });

    this.keepalive.interval(15);

    this.reset();
  }

  ngOnInit(): void {
    this.patientStore
      .select(selectPatientState)
      .pipe(takeUntil(this.destroyed))
      .subscribe((patientState) => {
        this.patient = patientState.primaryAccountHolder;
      });
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  openModal() {
    this.modalService.dismissAll();
    this.modalService
      .open(this.modal, {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        (result) => {
          if (result === 'continue') {
            this.reset();
            this.logoutDecision.emit(false);
          } else {
            sessionStorage.clear();
            window.history.replaceState(null, '', '/');
            this.logoutDecision.emit(true);
          }
        },
        (reason) => reason
      );
  }

  close() {
    this.modalService.dismissAll();
    this.mfaStatus$.subscribe((data) => {
      if (data === null && this.patient?.emailVerified) {
        this.modalService.open(MfaSetupConfirmationComponent, {
          backdrop: 'static',
          keyboard: false,
        });
      }
    });
    if (window.location.href.indexOf('RU') !== -1) {
      this.reset();
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
