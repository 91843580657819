import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Component({
  selector: 'patient-ui-duplicate-registration-mfa-setup',
  templateUrl: './duplicate-registration-mfa-setup.component.html',
  styleUrls: ['./duplicate-registration-mfa-setup.component.scss'],
})
export class DuplicateRegistrationMfaSetupComponent implements AfterViewInit {
  @Output() routeToSignInEvent = new EventEmitter<void>();
  @ViewChild('modal') modal!: ElementRef;
  focusableElements!: HTMLElement[];

  constructor(
    public activeModal: NgbActiveModal,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.modal.nativeElement.focus();
    }, 0);
    this.initializeFocusTrap();
  }

  initializeFocusTrap() {
    if (this.modal) {
      // Get all focusable elements inside the modal
      this.focusableElements = Array.from(
        this.modal.nativeElement.querySelectorAll('button, [tabindex="0"]')
      ) as HTMLElement[];
      // Set initial focus to the first focusable element
      if (this.focusableElements.length > 0) {
        this.focusableElements[0].focus();
      }
      // Add event listener to handle focus trapping
      this.modal.nativeElement.addEventListener('keydown', this.trapFocus);
    }
  }

  /**
   * Redirects the user to the landing page
   *
   * This function is used across registration component to handle user redirection
   * after a specific action or process, such as on click on cancel button and close
   * the modal.
   */
  routeToSignIn() {
    this.activeModal.dismiss('cancel');
    this.routeToSignInEvent.emit();
  }

  async login() {
    this.activeModal.dismiss('cancel');
    await this.oktaAuth.signInWithRedirect({ originalUri: '/' });
  }

  trapFocus(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      const focusableElements = this.focusableElements;
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];
      if (event.shiftKey) {
        // Shift + Tab
        if (document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        }
      } else {
        // Tab
        if (document.activeElement === lastElement) {
          event.preventDefault();
          firstElement.focus();
        }
      }
    }
  }
}
