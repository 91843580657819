import { Pipe, PipeTransform } from '@angular/core';

// Use with innerHtml if html needs to be decoded more than once
@Pipe({ name: 'htmldecode' })
export class HtmlDecodePipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    // Create a temporary element to decode HTML entities
    const tempElement = document.createElement('div');

    tempElement.innerHTML = value;
    return tempElement.textContent || tempElement.innerText || '';
  }
}
