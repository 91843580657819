import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MetricState, metricFeatureKey } from './metric.reducer';

export const metricStateSelector = createFeatureSelector<MetricState>(
  metricFeatureKey
);

export const selectCampaignTemplateId = createSelector(
  metricStateSelector,
  (state) => state.campaignTemplateId
);
