import { IAddress } from '@patient-ui/shared/models';

export interface ILinkedAccount {
  linkedId: string;
  recipientLinkedId?: string;
  patientMasterId: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  dateOfBirth: string;
  email: string;
  gender: string;
  isMinor: boolean;
  isPrimaryAccountHolder?: boolean;
  primaryAccount: ILinkedAccount | null;
  secondaryAccountList: ILinkedAccount[] | null;
  linkedAcctToCurrentUserStatus: LinkedAcctStatus;
  currentUserToLinkedAcctStatus: LinkedAcctStatus;
  linkedAcctAccess: LinkedAcctAccess;
  linkedAcctRole: LinkedAcctRole;
  authQuizStatus: string;
}

export type AlertMessageType = 'success' | 'error' | 'warning' | 'all';

/**
 * Status of a shared account transaction
 */
export enum LinkedAcctStatus {
  INITIATED = 'initiated',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  REVOKED = 'revoked',
  NOT_INITIATED = 'not_initiated',
}

export enum LinkedAcctAccess {
  ALL = 'all',
  RESULTS = 'results',
  INVOICES = 'invoices',
}

export enum LinkedAcctRole {
  READWRITE = 'readwrite',
  READONLY = 'readonly',
}

/**
 * Actions for sharing accounts
 */
export enum LinkAccountActions {
  SHARE_MINOR = 'share-minor',
  MANAGE_MINOR = 'manage-minor',
  LINK_ADULT = 'link-adult',
  REMOVE_ADULT = 'remove-adult',
  RESEND_LINK_ADULT = 'resend-link-adult',
  RESEND_LINK_TO_ADULTS_FOR_MINOR = 'resend-link-to-adults-for-minor',
  ACCEPT_FROM_OTHER_ADULT = 'accept-from-other-adult',
  DECLINE_FROM_OTHER_ADULT = 'decline-from-other-adult',
}

/**
 * IAddLinkedAccountRequest model that can be used for adding adult and minor
 * linked accounts
 */
export interface IAddLinkedAccountRequest {
  firstName: string;
  middleName?: string;
  lastName: string;
  gender?: string;
  email?: string;
  dateOfBirth?: string;
  address?: IAddress;
  isMinor: boolean;
  resend?: boolean;
}

export interface ILinkAccountRequestData {
  patientMasterId: string;
  isMinor: boolean;
  action: 'ACCEPT' | 'DECLINE';
}

export interface IRemoveLinkedAccountRequest {
  linkedIds: string[];
}

export interface ISendMinorAccessLinkRequest {
  minorPatientMasterId: string;
  recipientFirstName: string;
  recipientMiddleName: string;
  recipientLastName: string;
  recipientEmail: string;
  resend?: boolean;
}
