import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import { FastRegistrationVerifyResponse } from '@patient-ui/shared/models';

import { FastRegistrationActions } from './fast-registration.actions';

export const fastRegistrationFeatureKey = 'fastregistration';

export interface FastRegistrationState {
  fastRegistrationPatient: FastRegistrationVerifyResponse;
  serviceUnavailable: boolean;
  verifyFastRegistrationRequestStatus: RequestStatus;
  verifyFastRegistrationRequestError: string;
  submitFastRegistrationRequestStatus: RequestStatus;
  submitFastRegistrationRequestError: string;
}

export const initialState: FastRegistrationState = {
  fastRegistrationPatient: {
    hashId: '',
    mdmHashId: '',
    emailId: '',
  },
  serviceUnavailable: false,
  verifyFastRegistrationRequestStatus: RequestStatus.NotSent,
  verifyFastRegistrationRequestError: '',
  submitFastRegistrationRequestStatus: RequestStatus.NotSent,
  submitFastRegistrationRequestError: '',
};

export const fastRegReducer = createReducer(
  initialState,
  on(FastRegistrationActions.VerifyFastRegistration, (state) => ({
    ...state,
    verifyFastRegistrationRequestStatus: RequestStatus.Pending,
  })),
  on(
    FastRegistrationActions.VerifyFastRegistrationSuccess,
    (state, { response }) => ({
      ...state,
      fastRegistrationPatient: response,
      verifyFastRegistrationRequestStatus: RequestStatus.Success,
    })
  ),
  on(
    FastRegistrationActions.VerifyFastRegistrationFailure,
    (state, { payload }) => ({
      ...state,
      verifyFastRegistrationRequestError: payload.error,
      verifyFastRegistrationRequestStatus: RequestStatus.Failure,
    })
  ),
  on(FastRegistrationActions.SubmitFastRegistration, (state) => ({
    ...state,
    submitFastRegistrationRequestStatus: RequestStatus.Pending,
  })),
  on(
    FastRegistrationActions.SubmitFastRegistrationSuccess,
    (state, { response }) => ({
      ...state,
      fastRegistrationPatient: response,
      submitFastRegistrationRequestStatus: RequestStatus.Success,
    })
  ),
  on(
    FastRegistrationActions.SubmitFastRegistrationFailure,
    (state, { payload }) => ({
      ...state,
      submitFastRegistrationRequestError: payload.error,
      submitFastRegistrationRequestStatus: RequestStatus.Failure,
    })
  )
);
