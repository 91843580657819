import { LexisNexisQuestion } from './LexisNexisQuestion.model';

export class LexisNexisQuiz {
  id!: string;
  transactionId!: string;
  public questions?: LexisNexisQuestion[];
  authenticationQuizStatus!: string;
  constructor(lexisNexisQuiz: LexisNexisQuiz) {
    if (lexisNexisQuiz) {
      this.id = lexisNexisQuiz.id;
      this.transactionId = lexisNexisQuiz.transactionId;
      this.questions = lexisNexisQuiz.questions;
      this.authenticationQuizStatus = lexisNexisQuiz.authenticationQuizStatus;
    }
  }
}
