<div
  #alertContainer
  class="alert alert-dismissible"
  [class.alert-primary]="alertStatus === 'primary'"
  [class.alert-success]="alertStatus === 'success'"
  [class.alert-danger]="alertStatus === 'danger'"
  [class.alert-warning]="alertStatus === 'warning'"
  [class.alert-info]="alertStatus === 'info'"
  [class.d-inline-block]="fullWidth === false"
  [class.col-12]="fullWidthAlert === true"
  role="alert"
>
  <i class="alert-icon material-icons">{{ mainIcon }}</i>
  <ng-template #defaultTextTemplate>
    <div class="text" [innerHTML]="alertText"></div>
  </ng-template>
  <ng-container *ngTemplateOutlet="alertTextTemplate ? alertTextTemplate : defaultTextTemplate"> </ng-container>

  <a *ngIf="sideIconIcon" [routerLink]="sideIconLink" queryParamsHandling="preserve" class="action alert-link"
    ><i class="material-icons">{{ sideIconIcon }}</i> {{ sideIconText }}</a
  >

  <button *ngIf="showCloseBtn" type="button" class="close" (click)="closeAlert()" aria-label="Close">
    <i class="material-icons">close</i>
  </button>
</div>
