import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'ui-location-list-item-header',
  templateUrl: './location-list-item-header.component.html',
  styleUrls: ['./location-list-item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationListItemHeaderComponent {
  @HostBinding('class.d-flex') setFlex = true;

  @Input() number!: number;
  @Input() isWalgreens = false;
  @Input() isBusy = false;
  @Input() address!: string[];
  @Input() distanceMi!: number;
}
