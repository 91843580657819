<div class="card" [id]="cardId" [class.card-shadow]="addShadow === true">
  <div *ngIf="showHeader" class="card-header border-0 bg-white d-flex">
    <div class="flex-fill">{{ headerText }}</div>
    <div *ngIf="showHeaderMenu" ngbDropdown class="d-inline-block dropdown">
      <button class="btn btn-link" id="cardDropdownMenu" ngbDropdownToggle>
        <i class="material-icons float-right">more_vert</i>
      </button>
      <div ngbDropdownMenu aria-labelledby="cardDropdownMenu" class="dropdown-menu">
        <a
          *ngFor="let item of headerLinks"
          [routerLink]="item.url"
          class="card-link small text-nowrap"
          ngbDropdownItem
          class="dropdown-item"
        >
          {{ item.text }}</a
        >
      </div>
    </div>
  </div>
  <div class="card-body pt-0">
    <h6 class="card-title" *ngIf="showTitle" [class.pt-3]="!showHeader">
      {{ titleText }}
    </h6>
    <small class="card-subtitle mb-2" *ngIf="showSubTitle" [class.pt-3]="!showHeader && !showTitle">{{ subTitleText }}</small>
    <ng-content></ng-content>
    <ng-container *ngIf="footerLinks">
      <button *ngFor="let link of footerLinks" (click)="navigate(link.url)" type="button" class="btn btn-link p-0">
        <i class="material-icons icon-sm">{{ link.icon }}</i> {{ link.text }}
      </button>
    </ng-container>
  </div>
</div>
