import { ServiceTypes } from '@patient-ui/shared/models';

export const SERVICE_TYPES: ServiceTypes = {
  BREATH_ALCOHOL: {
    id: 1,
    name: 'Breath Alcohol',
  },
  SALIVA_ALCOHOL: {
    id: 2,
    name: 'Saliva Alcohol',
  },
  PEDIATRIC: {
    id: 3,
    name: 'Pediatric',
  },
  HAIR_ANALYSIS: {
    id: 4,
    name: 'Hair Analysis',
  },
  LABWORK: {
    id: 5,
    name: 'Labwork',
  },
  DRUG_SCREEN: {
    id: 6,
    name: 'Employment Drug Screen',
  },
  PATERNITY: {
    id: 7,
    name: 'Paternity',
  },
  FINGERPRINTING: {
    id: 8,
    name: 'Fingerprinting',
  },
  H_PYLORI: {
    id: 9,
    name: 'H. Pylori Breath Test',
  },
  GLUCOSE_TOLERANCE: {
    id: 10,
    name: 'Glucose Tolerance',
  },
  LACTOSE_TOLERANCE: {
    id: 11,
    name: 'Lactose Tolerance',
  },
  OTHER: {
    id: 12,
    name: 'Other',
  },
  SPECIMEN_DROP_OFF: {
    id: 13,
    name: 'Specimen Drop Off',
  },
  STANDING_ORDER: {
    id: 14,
    name: 'Standing Order',
  },
  SEMEN_ANALYSIS: {
    id: 15,
    name: 'Semen Analysis',
  },
  ALLOMAP: {
    id: 16,
    name: 'AlloMap® (Heart Transplant Patient Management)',
  },
  EMPLOYEE_WELLNESS: {
    id: 17,
    name: 'Employee Wellness with Body Measurement',
  },
  GENETIC_RESULTS: {
    id: 18,
    name: 'Genetic Results Consult',
  },
  CLINICAL_TRIAL: {
    id: 19,
    name: 'Covance Clinical Trial',
  },
};
