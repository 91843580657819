import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  AuthService,
  AuthState,
  PatientState,
  selectAuthState,
  selectLoadLoggedInPatientRequestStatus,
} from '@patient-ui/patient-web/store';
import { RequestStatus } from '@patient-ui/shared/enums';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationRequiredGuard implements CanActivate {
  authLoggedIn = new BehaviorSubject(false);
  patientLoggedIn = new BehaviorSubject(false);

  constructor(
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private authStore: Store<AuthState>,
    private patientStore: Store<PatientState>,
    private authService: AuthService
  ) {}

  canActivate(
    _activatedRoute: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, _reject) => {
      this.setAuthState();
      const redirect = _activatedRoute.queryParams['redirecturl'];
      // Check if logged in and all values acquired
      if (this.authLoggedIn.getValue() && this.patientLoggedIn.getValue()) {
        resolve(true);
      } else {
        sessionStorage.setItem('originalPath', routerState.url);
        // Check if session exists
        this.authService.oktaAuth.session?.get().then(async (sess) => {
          if (
            sess.status === 'ACTIVE' &&
            !this.authService.oktaAuth.isLoginRedirect()
          ) {
            this.authService.logLoginMetric('Auth guard');
            await this.authService.handleExistingSession(sess, redirect);
            this.setAuthState();
            if (
              this.authLoggedIn.getValue() &&
              this.patientLoggedIn.getValue()
            ) {
              resolve(true);
            } else {
              await this.oktaAuth.signInWithRedirect({
                originalUri: redirect,
              });
              resolve(false);
            }
          } else {
            await this.oktaAuth.signInWithRedirect({
              originalUri: redirect,
            });
            resolve(false);
          }
        });
      }
    });
  }

  setAuthState(): void {
    this.authStore
      .select(selectAuthState)
      .subscribe((state) => this.authLoggedIn.next(state.loggedIn));

    this.patientStore
      .select(selectLoadLoggedInPatientRequestStatus)
      .subscribe((status) => {
        if (status === RequestStatus.Success) {
          this.patientLoggedIn.next(true);
        } else {
          this.patientLoggedIn.next(false);
        }
      });
  }
}
