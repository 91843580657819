import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ILinkedAccount } from '@patient-ui/shared/models';

import {
  LinkedAccountsState,
  linkedAccountsFeatureKey,
} from './linked-accounts.reducer';

export const selectLinkedAccountsStateSelector = createFeatureSelector<
  LinkedAccountsState
>(linkedAccountsFeatureKey);

export const selectRawLinkedAccountsList = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.linkedAccounts
);

export const selectProcessedLinkedAccountsList = createSelector(
  selectRawLinkedAccountsList,
  (rawLinkedAccountsList) => {
    const rawCopy: ILinkedAccount[] = JSON.parse(
      JSON.stringify(rawLinkedAccountsList)
    );

    if (!rawCopy || !rawCopy.length) {
      return rawLinkedAccountsList;
    }

    const processedLinkedAccountsList: ILinkedAccount[] = [];
    const patientMasterIds = rawCopy.map(
      (linkedAccount) => linkedAccount.patientMasterId
    );
    const duplicatePatientMasterIds = patientMasterIds.filter(
      (id, index) => patientMasterIds.indexOf(id) !== index
    );

    const unidirectionalLinkedAccounts = rawCopy.filter(
      (rawLinkedAccount) =>
        !duplicatePatientMasterIds.includes(rawLinkedAccount.patientMasterId)
    );
    unidirectionalLinkedAccounts.forEach((linkedAccount) =>
      processedLinkedAccountsList.push(linkedAccount)
    );

    // process bidirectional linked accounts (converge into one linked account obj)
    const processedBidirectionalLinkedAccounts: ILinkedAccount[] = [];
    duplicatePatientMasterIds.forEach((duplicatePatientMasterId) => {
      const senderLinkedAccount = rawCopy.filter(
        (linkedAccount) =>
          linkedAccount.patientMasterId === duplicatePatientMasterId &&
          !!linkedAccount.currentUserToLinkedAcctStatus &&
          !linkedAccount.linkedAcctToCurrentUserStatus
      )[0];
      const recipientLinkedAccount = rawCopy.filter(
        (linkedAccount) =>
          linkedAccount.patientMasterId === duplicatePatientMasterId &&
          !linkedAccount.currentUserToLinkedAcctStatus &&
          !!linkedAccount.linkedAcctToCurrentUserStatus
      )[0];
      senderLinkedAccount.linkedAcctToCurrentUserStatus =
        recipientLinkedAccount.linkedAcctToCurrentUserStatus;
      senderLinkedAccount.recipientLinkedId = recipientLinkedAccount.linkedId;
      processedBidirectionalLinkedAccounts.push(senderLinkedAccount);
    });
    processedBidirectionalLinkedAccounts.forEach((linkedAccount) =>
      processedLinkedAccountsList.push(linkedAccount)
    );

    return processedLinkedAccountsList;
  }
);

export const selectGetLinkedAccountsStatus = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.getLinkedAccountsStatus
);

export const selectLinkedPortalUserList = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.linkedPortalUserList
);

export const selectSharedAccessPending = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.sharedAccessPending
);

export const selectAddLinkedAccountRequestStatus = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.addLinkedAccountRequestStatus
);

export const selectAddLinkedAccountRequestError = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.addLinkedAccountRequestError
);

export const selectAddLinkedAccountRequestState = createSelector(
  selectAddLinkedAccountRequestStatus,
  selectAddLinkedAccountRequestError,
  (status, error) => ({
    status,
    errorDetails: error,
  })
);

export const selectLinkRequestActionStatus = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.linkRequestActionStatus
);

export const selectRemoveLinkedAccountRequestStatus = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.removeLinkedAccountRequestStatus
);

export const selectSendMinorAccessLinkRequestRequestStatus = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.sendMinorAccessLinkRequestRequestStatus
);

export const selectSendMinorAccessLinkRequestRequestError = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.sendMinorAccessLinkRequestRequestError
);

export const selectSendMinorAccessLinkRequestState = createSelector(
  selectSendMinorAccessLinkRequestRequestStatus,
  selectSendMinorAccessLinkRequestRequestError,
  (status, error) => ({
    status,
    errorDetails: error,
  })
);

export const selectSendMinorAccessLinkRequestsRequestStatus = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.sendMinorAccessLinkRequestsRequestStatus
);

export const selectSendMinorAccessLinkRequestsRequestError = createSelector(
  selectLinkedAccountsStateSelector,
  (state) => state.sendMinorAccessLinkRequestsRequestError
);

export const selectSendMinorAccessLinkRequestsState = createSelector(
  selectSendMinorAccessLinkRequestsRequestStatus,
  selectSendMinorAccessLinkRequestsRequestError,
  (status, error) => ({
    status,
    errorDetails: error,
  })
);

export const linkedAccountsQuery = {
  selectLinkedAccountsStateSelector,
  selectRawLinkedAccountsList,
  selectProcessedLinkedAccountsList,
  selectGetLinkedAccountsStatus,
  selectLinkedPortalUserList,
  selectSharedAccessPending,
  selectAddLinkedAccountRequestStatus,
  selectAddLinkedAccountRequestError,
  selectAddLinkedAccountRequestState,
  selectLinkRequestActionStatus,
  selectRemoveLinkedAccountRequestStatus,
  selectSendMinorAccessLinkRequestRequestStatus,
  selectSendMinorAccessLinkRequestRequestError,
  selectSendMinorAccessLinkRequestState,
  selectSendMinorAccessLinkRequestsRequestStatus,
  selectSendMinorAccessLinkRequestsRequestError,
  selectSendMinorAccessLinkRequestsState,
};
