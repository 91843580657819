import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LayoutService } from '@patient-ui/patient-web/feature-shell';
import {
  AuthState,
  PatientState,
  logOut,
  refreshSession,
  selectPatientState,
} from '@patient-ui/patient-web/store';
import { Patient } from '@patient-ui/shared/models';

@Component({
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicLayoutComponent implements OnInit, OnDestroy {
  @HostBinding('class') cssClass = 'd-flex flex-column vh-100';
  @ViewChild('ieNotificationModalRef', { static: true })
  ieNotificationModalRef!: TemplateRef<unknown>;
  navigatedFrom: string | undefined;
  destroyed = new Subject();
  patient?: Patient;

  constructor(
    private modalService: NgbModal,
    private authstore: Store<AuthState>,
    private patientStore: Store<PatientState>,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    if (window.location.href.indexOf('RU') !== -1) {
      this.navigatedFrom = 'RU';
    }
    this.msIeVersion();
    this.patientStore
      .select(selectPatientState)
      .pipe(takeUntil(this.destroyed))
      .subscribe((patientState) => {
        this.patient = patientState.primaryAccountHolder;
      });
  }

  openNotificationModal() {
    return this.modalService.open(this.ieNotificationModalRef, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  msIeVersion(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    // eslint-disable-next-line no-useless-escape
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      this.openNotificationModal();
      return true;
    } // If another browser, return 0
    else {
      return false;
    }
    return false;
  }
  handleSession(logoutDecision: boolean) {
    if (logoutDecision) {
      this.layoutService.setSignOutClicked(true);
      this.authstore.dispatch(logOut({ redirect: '/landing' }));
      sessionStorage.clear();
      window.history.replaceState(null, '', '/');
    } else {
      this.authstore.dispatch(refreshSession());
    }
  }
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
