import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import { IRequestError, PasswordReset } from '@patient-ui/shared/models';

import * as AccountActions from './account.actions';

export const accountFeatureKey = 'account';

export interface AccountState {
  passwordReset: PasswordReset;
  getPasswordResetPayloadRequestStatus: RequestStatus;
  getPasswordReserPayloadRequestErrorResponse: HttpErrorResponse | null;
  sendPasswordResetVerifyCodeRequestStatus: RequestStatus;
  validatePasswordResetVerifyCodeRequestStatus: RequestStatus;
  validatePasswordResetVerifyCodeRequestError: IRequestError;
  setNewPasswordRequestStatus: RequestStatus;
}

export const AccountPasswordInitialState: AccountState = {
  passwordReset: {
    token: null,
    last4Mobile: null,
    email: null,
    method: 'email',
  },
  getPasswordResetPayloadRequestStatus: RequestStatus.NotSent,
  getPasswordReserPayloadRequestErrorResponse: null,
  sendPasswordResetVerifyCodeRequestStatus: RequestStatus.NotSent,
  validatePasswordResetVerifyCodeRequestStatus: RequestStatus.NotSent,
  validatePasswordResetVerifyCodeRequestError: {
    code: '',
    description: '',
  },
  setNewPasswordRequestStatus: RequestStatus.NotSent,
};

export const accountReducer = createReducer(
  AccountPasswordInitialState,
  on(
    AccountActions.getPasswordResetPayloadSuccess,
    (state, { passwordResetPayload }) => ({
      ...state,
      passwordReset: {
        token: passwordResetPayload?.token,
        last4Mobile: passwordResetPayload?.last4Mobile,
        email: state.passwordReset?.email,
        method: state.passwordReset?.method,
      },
      getPasswordResetPayloadRequestStatus: RequestStatus.Success,
    })
  ),

  on(
    AccountActions.getPasswordResetPayloadFailure,
    (state, { errorResponse }) => ({
      ...state,
      getPasswordResetPayloadRequestStatus: RequestStatus.Failure,
      getPasswordReserPayloadRequestErrorResponse: errorResponse,
    })
  ),

  on(AccountActions.clearPasswordResetPayload, (state) => ({
    ...state,
    passwordReset: AccountPasswordInitialState.passwordReset,
    getPasswordResetPayloadRequestStatus:
      AccountPasswordInitialState.getPasswordResetPayloadRequestStatus,
    getPasswordReserPayloadRequestErrorResponse:
      AccountPasswordInitialState.getPasswordReserPayloadRequestErrorResponse,
  })),

  on(AccountActions.setPasswordResetEmail, (state, { email }) => ({
    ...state,
    passwordReset: {
      token: state.passwordReset.token,
      last4Mobile: state.passwordReset.last4Mobile,
      email: email,
      method: state.passwordReset.method,
    },
  })),

  on(AccountActions.setPasswordResetMethod, (state, { method }) => ({
    ...state,
    passwordReset: {
      token: state.passwordReset.token,
      last4Mobile: state.passwordReset.last4Mobile,
      email: state.passwordReset.email,
      method: method === 'TEXT' ? 'text' : 'email',
    },
  })),

  on(AccountActions.sendPasswordResetVerifyCodeSuccess, (state, { token }) => ({
    ...state,
    passwordReset: {
      token: token,
      last4Mobile: state.passwordReset.last4Mobile,
      email: state.passwordReset.email,
      method: state.passwordReset.method,
    },
    sendPasswordResetVerifyCodeRequestStatus: RequestStatus.Success,
  })),

  on(AccountActions.sendPasswordResetVerifyCodeFailure, (state) => ({
    ...state,
    sendPasswordResetVerifyCodeRequestStatus: RequestStatus.Failure,
  })),

  on(AccountActions.clearSendPasswordResetVerifyCodeRequestStatus, (state) => ({
    ...state,
    sendPasswordResetVerifyCodeRequestStatus:
      AccountPasswordInitialState.sendPasswordResetVerifyCodeRequestStatus,
  })),

  on(
    AccountActions.validatePasswordResetVerifyCodeSuccess,
    (state, { token }) => ({
      ...state,
      passwordReset: {
        token: token,
        last4Mobile: state.passwordReset.last4Mobile,
        email: state.passwordReset.email,
        method: state.passwordReset.method,
      },
      validatePasswordResetVerifyCodeRequestStatus: RequestStatus.Success,
    })
  ),

  on(
    AccountActions.validatePasswordResetVerifyCodeFailure,
    (state, { error }) => ({
      ...state,
      validatePasswordResetVerifyCodeRequestError: error,
      validatePasswordResetVerifyCodeRequestStatus: RequestStatus.Failure,
    })
  ),

  on(
    AccountActions.clearValidatePasswordResetVerifyCodeRequestStatus,
    (state) => ({
      ...state,
      validatePasswordResetVerifyCodeRequestError:
        AccountPasswordInitialState.validatePasswordResetVerifyCodeRequestError,
      validatePasswordResetVerifyCodeRequestStatus:
        AccountPasswordInitialState.validatePasswordResetVerifyCodeRequestStatus,
    })
  ),

  on(AccountActions.clearPasswordResetVerifyCodeRequest, (state) => ({
    ...state,
    validatePasswordResetVerifyCodeRequestError:
      AccountPasswordInitialState.validatePasswordResetVerifyCodeRequestError,
    validatePasswordResetVerifyCodeRequestStatus:
      AccountPasswordInitialState.validatePasswordResetVerifyCodeRequestStatus,
    getPasswordResetPayloadRequestStatus:
      AccountPasswordInitialState.getPasswordResetPayloadRequestStatus,
    sendPasswordResetVerifyCodeRequestStatus:
      AccountPasswordInitialState.sendPasswordResetVerifyCodeRequestStatus,
  })),

  on(AccountActions.setNewPasswordSuccess, (state) => ({
    ...state,
    setNewPasswordRequestStatus: RequestStatus.Success,
  })),

  on(AccountActions.setNewPasswordFailure, (state) => ({
    ...state,
    setNewPasswordRequestStatus: RequestStatus.Failure,
  })),

  on(AccountActions.clearAllResetPasswordState, (state) => ({
    ...state,
    passwordReset: {
      token: null,
      last4Mobile: null,
      email: null,
      method: 'email',
    },
    getPasswordResetPayloadRequestStatus: RequestStatus.NotSent,
    getPasswordReserPayloadRequestErrorResponse: null,
    sendPasswordResetVerifyCodeRequestStatus: RequestStatus.NotSent,
    validatePasswordResetVerifyCodeRequestStatus: RequestStatus.NotSent,
    validatePasswordResetVerifyCodeRequestError: {
      code: '',
      description: '',
    },
    setNewPasswordRequestStatus: RequestStatus.NotSent,
  }))
);
