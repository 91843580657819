import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

import { IRequestError } from '@patient-ui/shared/models';

import * as LinkedAccountsActions from './linked-accounts.actions';
import { LinkedAccountsState } from './linked-accounts.reducer';
import { linkedAccountsQuery } from './linked-accounts.selectors';
import { LinkedAccountsService } from './linked-accounts.service';
import { PatientState } from '../patient/patient.reducer';
import { patientQuery } from '../patient/patient.selectors';
import { PendoActions } from '../pendo/pendo.actions';

@Injectable()
export class LinkedAccountsEffects {
  loadLinkedAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountsActions.loadLinkedAccounts),
      exhaustMap(() =>
        this.linkedAccountsService.loadLinkedAccounts().pipe(
          switchMap((linkedAccountsList) => [
            LinkedAccountsActions.loadLinkedAccountsSuccess({
              accountList: linkedAccountsList,
            }),
            LinkedAccountsActions.updateLinkedPortalUserListWithPAH(),
            PendoActions.pendoSetLinkedAccounts({
              linkedAccounts: linkedAccountsList,
            }),
          ]),
          catchError((_error) =>
            of(LinkedAccountsActions.updateLinkedPortalUserListWithPAH())
          )
        )
      )
    )
  );

  updateLinkedPortalUserListWithPAH$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountsActions.updateLinkedPortalUserListWithPAH),
      concatLatestFrom(() => [
        this.patientStore.select(patientQuery.selectPrimaryAccountHolder),
        this.linkedAccountStore.select(
          linkedAccountsQuery.selectProcessedLinkedAccountsList
        ),
      ]),
      exhaustMap(([_action, primaryAccount, accountList]) =>
        this.linkedAccountsService
          .updateLinkedPortalUserListWithPAH(primaryAccount, accountList)
          .pipe(
            map((updatedUserList) =>
              LinkedAccountsActions.updateLinkedPortalUserListWithPAHSuccess({
                updatedLinkedAccountList: updatedUserList,
              })
            ),
            catchError(() => EMPTY)
          )
      )
    )
  );
  loadSharedAccesPendingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountsActions.loadSharedAccesPendingRequest),
      exhaustMap(() =>
        this.linkedAccountsService.loadSharedAccessPendingRequest().pipe(
          map((resp) =>
            LinkedAccountsActions.loadSharedAccesPendingRequestSuccess({
              pendingAccounttList: resp,
            })
          ),
          catchError((_error) =>
            of(LinkedAccountsActions.loadSharedAccesPendingRequestFailure())
          )
        )
      )
    )
  );

  addLinkedAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountsActions.addLinkedAccount),
      exhaustMap((action) =>
        this.linkedAccountsService
          .addLinkedAccount(action.addLinkedAccountRequest)
          .pipe(
            map((response) => {
              if (response.ok && response.status === 200) {
                return LinkedAccountsActions.addLinkedAccountSuccess();
              }
              return LinkedAccountsActions.addLinkedAccountFailure({
                errorResponse: response.body as IRequestError,
              });
            }),
            catchError((error: IRequestError) =>
              of(
                LinkedAccountsActions.addLinkedAccountFailure({
                  errorResponse: error,
                })
              )
            )
          )
      )
    )
  );

  linkRequestAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountsActions.linkRequestAction),
      exhaustMap((action) =>
        this.linkedAccountsService
          .linkAccountRequest(action.linkAccountRequestData)
          .pipe(
            map((_response) =>
              LinkedAccountsActions.linkRequestActionSuccess()
            ),
            catchError((error) =>
              of(
                LinkedAccountsActions.linkRequestActionFailure({
                  errorResponse: error as IRequestError,
                })
              )
            )
          )
      )
    )
  );

  removeLinkedAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountsActions.removeLinkedAccount),
      exhaustMap((action) =>
        this.linkedAccountsService
          .removeLinkedAccount(action.removeLinkedAccountRequest)
          .pipe(
            map((response) => {
              if (response.ok && response.status === 200) {
                return LinkedAccountsActions.removeLinkedAccountSuccess({
                  linkedIds: action.removeLinkedAccountRequest.linkedIds,
                });
              }
              return LinkedAccountsActions.removeLinkedAccountFailure({
                errorResponse: response.body as IRequestError,
              });
            }),
            catchError((error) =>
              of(
                LinkedAccountsActions.removeLinkedAccountFailure({
                  errorResponse: error,
                })
              )
            )
          )
      )
    )
  );

  sendMinorAccessLinkRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountsActions.sendMinorAccessLinkRequest),
      exhaustMap((action) =>
        this.linkedAccountsService
          .sendMinorAccessLinkRequest(action.sendMinorAccessLinkRequest)
          .pipe(
            map((response) => {
              if (response.ok && response.status === 200) {
                return LinkedAccountsActions.sendMinorAccessLinkRequestSuccess();
              }
              return LinkedAccountsActions.sendMinorAccessLinkRequestFailure({
                errorResponse: response.body as IRequestError,
              });
            }),
            catchError((error) =>
              of(
                LinkedAccountsActions.sendMinorAccessLinkRequestFailure({
                  errorResponse: error,
                })
              )
            )
          )
      )
    )
  );

  sendMinorAccessLinkRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountsActions.sendMinorAccessLinkRequests),
      exhaustMap((action) =>
        this.linkedAccountsService
          .sendMinorAccessLinkRequests(action.sendMinorAccessLinkRequest)
          .pipe(
            map((response) => {
              if (response.ok && response.status === 200) {
                return LinkedAccountsActions.sendMinorAccessLinkRequestsSuccess();
              }
              return LinkedAccountsActions.sendMinorAccessLinkRequestsFailure({
                errorResponse: response.body as IRequestError,
              });
            }),
            catchError((error) =>
              of(
                LinkedAccountsActions.sendMinorAccessLinkRequestFailure({
                  errorResponse: error,
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private linkedAccountsService: LinkedAccountsService,
    private patientStore: Store<PatientState>,
    private linkedAccountStore: Store<LinkedAccountsState>
  ) {}
}
