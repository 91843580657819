import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  isSignIn = false;
  constructor(
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {}

  async canActivate(): Promise<boolean> {
    if (this.isSignIn) {
      return true;
    } else {
      await this.oktaAuth.signInWithRedirect({
        originalUri: '/',
      });
      return false;
    }
  }
}
