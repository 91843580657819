<div ngbDropdown [ngClass]="addClass">
  <button class="btn btn-link btn-lg dropdown-toggle" [id]="id" ngbDropdownToggle>
    {{ selectedText }}
    <i class="material-icons open-icon">{{ iconOpen }}</i>
    <i class="material-icons close-icon">{{ iconClose }}</i>
  </button>
  <div ngbDropdownMenu class="dropdown-menu" [attr.aria-labelledby]="id">
    <button *ngFor="let item of listItems" (click)="onClick(item)" class="dropdown-item" ngbDropdownItem>
      {{ item.text }}
    </button>
    <ng-container *ngIf="showClearFilter">
      <div class="dropdown-divider"></div>
      <button ngbdropdownitem (click)="onClick({ text: 'Clear Filter', value: '-1' })" class="dropdown-item">
        Clear Filter
      </button>
    </ng-container>
  </div>
</div>
