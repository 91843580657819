import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleComponent {
  @Input() completed = false;

  @Input() bgColor: 'gray' | 'white' | 'transparent' = 'transparent';
  @Input() borderColor: 'dark-green' | 'gray' = 'gray';
}
