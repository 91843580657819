import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Subscription } from 'rxjs';

import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { LayoutService } from './../../shared/services/layout.service';

@Component({
  selector: 'patient-ui-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicHeaderComponent implements OnDestroy, OnInit {
  patientLitholinkUrl = '';
  showButton = true;
  withStripe = true;
  headerLinks = true;
  ondemandLinks = false;
  onDemandheader = false;
  showSignInButton = true;
  stripeSubscription!: Subscription;
  buttonSubscription!: Subscription;
  headerLinksSubscription!: Subscription;
  ondemandLinksSubscription!: Subscription;
  ondemandHeaderSubscription!: Subscription;
  paymentPlanHeaderSubscription!: Subscription;
  isMenuCollapsed = true;
  helpCenterUrl = '';
  preCheckUrl = '';
  onDemandUrl = '';
  portalHeader = false;
  portalLinksSubscription!: Subscription;
  patientHeaderState = false;
  portalLinks = false;

  constructor(
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private envService: EnvironmentService,
    private layoutService: LayoutService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.helpCenterUrl = `${this.envService.patientUrl}/help-center/home`;
    this.preCheckUrl = this.envService.preCheckUrl;
    this.patientLitholinkUrl = `${this.envService.patientUrl}/litholink`;
    this.onDemandUrl = this.envService.onDemandUrl;

    this.stripeSubscription = this.layoutService
      .getShowNavbarStripe()
      .subscribe((stripeState) => {
        this.withStripe = stripeState;
        this.ref.detectChanges();
      });
    this.buttonSubscription = this.layoutService
      .getShowNavbarButton()
      .subscribe((buttonState) => {
        this.showButton = buttonState;
        this.ref.detectChanges();
      });
    this.headerLinksSubscription = this.layoutService
      .getShowHeaderLinks()
      .subscribe((headerLinksState) => {
        this.headerLinks = headerLinksState;
        this.ref.detectChanges();
      });
    this.ondemandLinksSubscription = this.layoutService
      .getShowOndemandLinks()
      .subscribe((ondemandLinksState) => {
        this.ondemandLinks = ondemandLinksState;
        this.ref.detectChanges();
      });
    this.ondemandHeaderSubscription = this.layoutService
      .getShowOndemandHeader()
      .subscribe((ondemandHeaderState) => {
        this.onDemandheader = ondemandHeaderState;
        this.ref.detectChanges();
      });
    this.paymentPlanHeaderSubscription = this.layoutService
      .getShowSignInButton()
      .subscribe((signInButtonState) => {
        this.showSignInButton = signInButtonState;
        this.ref.detectChanges();
      });
    this.portalLinksSubscription = this.layoutService
      .getShowPortalHeaderLinks()
      .subscribe((patientHeaderState) => {
        this.portalLinks = patientHeaderState;
        this.ref.detectChanges();
      });

    window.addEventListener(
      'click',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (evt: any) => {
        if (!document.getElementById('public-header')?.contains(evt.target)) {
          this.collapseMenu();
        }
      },
      true
    );
    window.addEventListener(
      'click',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (evt: any) => {
        if (
          !document.querySelector('[id^="portal-header"]')?.contains(evt.target)
        ) {
          this.collapseMenu();
        }
      },
      true
    );
  }

  ngOnDestroy(): void {
    this.stripeSubscription.unsubscribe();
    this.buttonSubscription.unsubscribe();
    this.headerLinksSubscription.unsubscribe();
    this.ondemandLinksSubscription.unsubscribe();
    this.ondemandHeaderSubscription.unsubscribe();
    this.portalLinksSubscription.unsubscribe();
    this.paymentPlanHeaderSubscription.unsubscribe();
  }

  collapseMenu(): void {
    this.isMenuCollapsed = true;
    this.ref.detectChanges();
  }

  async login() {
    await this.oktaAuth.signInWithRedirect({ originalUri: '/' });
  }
}
