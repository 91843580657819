import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';

import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { PendoActions } from './pendo.actions';
import { PendoService } from './pendo.service';

@Injectable()
export class PendoEffects {
  pendoSetGuestUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PendoActions.pendoSetGuestUser),
      tap((action) => this.pendoService.setGuestUser(action.invoiceSearch)),
      concatMap(() => [
        PendoActions.pendoSetVersion({
          version: this.envService.appVersion,
        }),
        PendoActions.pendoInitializeSuccess({
          success: true,
        }),
      ]),
      catchError((_error) =>
        of(PendoActions.pendoInitializeSuccess({ success: false }))
      )
    )
  );

  pendoSetUnknownUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PendoActions.pendoSetUnknownUser),
      tap(() => this.pendoService.setUnknownUser()),
      concatMap(() => [
        PendoActions.pendoInitializeSuccess({ success: true }),
        PendoActions.pendoSetVersion({ version: this.envService.appVersion }),
      ]),
      catchError((_error) =>
        of(PendoActions.pendoInitializeSuccess({ success: false }))
      )
    )
  );

  pendoSetRegisteredUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PendoActions.pendoSetRegisteredUser),
      tap((action) => this.pendoService.setRegisteredUser(action.patient)),
      concatMap(() => [
        PendoActions.pendoSetVersion({ version: this.envService.appVersion }),
      ])
    )
  );

  constructor(
    private actions$: Actions,
    private pendoService: PendoService,
    private envService: EnvironmentService
  ) {}
}
