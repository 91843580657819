import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PhoneState, phoneFeatureKey } from './phone.reducer';

export const selectPhoneState = createFeatureSelector<PhoneState>(
  phoneFeatureKey
);

export const selectOptedIn = createSelector(
  selectPhoneState,
  (state) => state.optedIn
);

export const selectMessage = createSelector(
  selectPhoneState,
  (state) => state.message
);

export const selectOptInRequestStatus = createSelector(
  selectPhoneState,
  (state) => state.optInPhoneRequestStatus
);
