import {
  ILinkedAccount,
  LinkedAcctAccess,
  LinkedAcctRole,
  LinkedAcctStatus,
} from '@patient-ui/shared/models';

export const linkedAccounts: ILinkedAccount[] = [
  {
    linkedId: '71469',
    patientMasterId: '123456',
    firstName: 'Steve',
    middleName: '',
    lastName: 'krawczuk',
    dateOfBirth: '1977-02-02',
    email: 'swiss@yopmail.com',
    gender: 'MALE',
    isMinor: false,
    primaryAccount: null,
    secondaryAccountList: null,
    linkedAcctToCurrentUserStatus: LinkedAcctStatus.INITIATED,
    currentUserToLinkedAcctStatus: LinkedAcctStatus.ACCEPTED,
    linkedAcctAccess: LinkedAcctAccess.ALL,
    linkedAcctRole: LinkedAcctRole.READONLY,
    authQuizStatus: '',
  },
  {
    linkedId: '72613',
    patientMasterId: '123460',
    firstName: 'Rebecca',
    middleName: null,
    lastName: 'Krawczuk',
    dateOfBirth: '2000-02-02',
    email: 'swiss@yopmail.com',
    gender: 'FEMALE',
    isMinor: false,
    primaryAccount: null,
    secondaryAccountList: null,
    linkedAcctToCurrentUserStatus: LinkedAcctStatus.ACCEPTED,
    currentUserToLinkedAcctStatus: LinkedAcctStatus.INITIATED,
    linkedAcctAccess: LinkedAcctAccess.ALL,
    linkedAcctRole: LinkedAcctRole.READONLY,
    authQuizStatus: '',
  },
  {
    linkedId: '72614',
    patientMasterId: '123461',
    firstName: 'Jasmine',
    middleName: null,
    lastName: 'krawczuk',
    dateOfBirth: '2004-02-02',
    email: 'swiss@yopmail.com',
    gender: 'FEMALE',
    isMinor: false,
    primaryAccount: null,
    secondaryAccountList: null,
    linkedAcctToCurrentUserStatus: LinkedAcctStatus.ACCEPTED,
    currentUserToLinkedAcctStatus: LinkedAcctStatus.ACCEPTED,
    linkedAcctAccess: LinkedAcctAccess.ALL,
    linkedAcctRole: LinkedAcctRole.READONLY,
    authQuizStatus: 'PASSED',
  },
  {
    linkedId: '82614',
    patientMasterId: '123462',
    firstName: 'baby',
    middleName: null,
    lastName: 'krawczuk',
    dateOfBirth: '2019-02-02',
    email: 'swiss@yopmail.com',
    gender: 'FEMALE',
    isMinor: true,
    primaryAccount: {
      linkedId: '71469',
      patientMasterId: '123463',
      firstName: 'Steve',
      middleName: '',
      lastName: 'krawczuk',
      dateOfBirth: '1977-02-02',
      email: 'swiss@yopmail.com',
      gender: 'MALE',
      isMinor: false,
      primaryAccount: null,
      secondaryAccountList: null,
      linkedAcctToCurrentUserStatus: LinkedAcctStatus.INITIATED,
      currentUserToLinkedAcctStatus: LinkedAcctStatus.ACCEPTED,
      linkedAcctAccess: LinkedAcctAccess.ALL,
      linkedAcctRole: LinkedAcctRole.READONLY,
      authQuizStatus: 'PASSED',
    },
    secondaryAccountList: null,
    linkedAcctToCurrentUserStatus: LinkedAcctStatus.ACCEPTED,
    currentUserToLinkedAcctStatus: LinkedAcctStatus.NOT_INITIATED,
    linkedAcctAccess: LinkedAcctAccess.ALL,
    linkedAcctRole: LinkedAcctRole.READWRITE,
    authQuizStatus: 'PASSED',
  },
  {
    linkedId: '826155',
    patientMasterId: '123464',
    firstName: 'baby pending',
    middleName: null,
    lastName: 'krawczuk',
    dateOfBirth: '2019-02-02',
    email: 'swiss@yopmail.com',
    gender: 'FEMALE',
    isMinor: true,
    primaryAccount: {
      linkedId: '71469',
      patientMasterId: '123465',
      firstName: 'Steve',
      middleName: '',
      lastName: 'krawczuk',
      dateOfBirth: '1977-02-02',
      email: 'swiss@yopmail.com',
      gender: 'MALE',
      isMinor: false,
      primaryAccount: null,
      secondaryAccountList: null,
      linkedAcctToCurrentUserStatus: LinkedAcctStatus.INITIATED,
      currentUserToLinkedAcctStatus: LinkedAcctStatus.ACCEPTED,
      linkedAcctAccess: LinkedAcctAccess.ALL,
      linkedAcctRole: LinkedAcctRole.READONLY,
      authQuizStatus: 'PASSED',
    },
    secondaryAccountList: null,
    linkedAcctToCurrentUserStatus: LinkedAcctStatus.INITIATED,
    currentUserToLinkedAcctStatus: LinkedAcctStatus.NOT_INITIATED,
    linkedAcctAccess: LinkedAcctAccess.ALL,
    linkedAcctRole: LinkedAcctRole.READWRITE,
    authQuizStatus: 'PASSED',
  },
  {
    linkedId: '12614',
    patientMasterId: '123466',
    firstName: 'child',
    middleName: null,
    lastName: 'krawczuk',
    dateOfBirth: '2019-02-02',
    email: 'swiss@yopmail.com',
    gender: 'FEMALE',
    isMinor: true,
    primaryAccount: null,
    secondaryAccountList: [
      {
        linkedId: '72613',
        patientMasterId: '123467',
        firstName: 'Rebecca',
        middleName: null,
        lastName: 'Krawczuk',
        dateOfBirth: '2000-02-02',
        email: 'swiss@yopmail.com',
        gender: 'FEMALE',
        isMinor: false,
        primaryAccount: null,
        secondaryAccountList: null,
        linkedAcctToCurrentUserStatus: LinkedAcctStatus.ACCEPTED,
        currentUserToLinkedAcctStatus: LinkedAcctStatus.INITIATED,
        linkedAcctAccess: LinkedAcctAccess.ALL,
        linkedAcctRole: LinkedAcctRole.READONLY,
        authQuizStatus: 'PASSED',
      },
    ],
    linkedAcctToCurrentUserStatus: LinkedAcctStatus.NOT_INITIATED,
    currentUserToLinkedAcctStatus: LinkedAcctStatus.ACCEPTED,
    linkedAcctAccess: LinkedAcctAccess.ALL,
    linkedAcctRole: LinkedAcctRole.READWRITE,
    authQuizStatus: 'PASSED',
  },
  {
    linkedId: '12614',
    patientMasterId: '123468',
    firstName: 'toddler',
    middleName: null,
    lastName: 'krawczuk',
    dateOfBirth: '2019-02-02',
    email: 'swiss@yopmail.com',
    gender: 'MALE',
    isMinor: true,
    primaryAccount: null,
    secondaryAccountList: [
      {
        linkedId: '71469',
        patientMasterId: '123469',
        firstName: 'Steve',
        middleName: '',
        lastName: 'krawczuk',
        dateOfBirth: '1977-02-02',
        email: 'swiss@yopmail.com',
        gender: 'MALE',
        isMinor: false,
        primaryAccount: null,
        secondaryAccountList: null,
        linkedAcctToCurrentUserStatus: LinkedAcctStatus.INITIATED,
        currentUserToLinkedAcctStatus: LinkedAcctStatus.ACCEPTED,
        linkedAcctAccess: LinkedAcctAccess.ALL,
        linkedAcctRole: LinkedAcctRole.READONLY,
        authQuizStatus: 'PASSED',
      },
    ],
    linkedAcctToCurrentUserStatus: LinkedAcctStatus.NOT_INITIATED,
    currentUserToLinkedAcctStatus: LinkedAcctStatus.INITIATED,
    linkedAcctAccess: LinkedAcctAccess.ALL,
    linkedAcctRole: LinkedAcctRole.READWRITE,
    authQuizStatus: 'PASSED',
  },
  {
    linkedId: '19614',
    patientMasterId: '123471',
    firstName: 'yetanother',
    middleName: null,
    lastName: 'krawczuk',
    dateOfBirth: '2019-02-02',
    email: 'swiss@yopmail.com',
    gender: 'MALE',
    isMinor: true,
    primaryAccount: null,
    secondaryAccountList: [
      {
        linkedId: '71469',
        patientMasterId: '123472',
        firstName: 'Steve',
        middleName: '',
        lastName: 'krawczuk',
        dateOfBirth: '1977-02-02',
        email: 'swiss@yopmail.com',
        gender: 'MALE',
        isMinor: false,
        primaryAccount: null,
        secondaryAccountList: null,
        linkedAcctToCurrentUserStatus: LinkedAcctStatus.INITIATED,
        currentUserToLinkedAcctStatus: LinkedAcctStatus.ACCEPTED,
        linkedAcctAccess: LinkedAcctAccess.ALL,
        linkedAcctRole: LinkedAcctRole.READONLY,
        authQuizStatus: 'PASSED',
      },
      {
        linkedId: '72613',
        patientMasterId: '123473',
        firstName: 'Rebecca',
        middleName: null,
        lastName: 'Krawczuk',
        dateOfBirth: '2000-02-02',
        email: 'swiss@yopmail.com',
        gender: 'FEMALE',
        isMinor: false,
        primaryAccount: null,
        secondaryAccountList: null,
        linkedAcctToCurrentUserStatus: LinkedAcctStatus.ACCEPTED,
        currentUserToLinkedAcctStatus: LinkedAcctStatus.INITIATED,
        linkedAcctAccess: LinkedAcctAccess.ALL,
        linkedAcctRole: LinkedAcctRole.READONLY,
        authQuizStatus: 'PASSED',
      },
    ],
    linkedAcctToCurrentUserStatus: LinkedAcctStatus.NOT_INITIATED,
    currentUserToLinkedAcctStatus: LinkedAcctStatus.ACCEPTED,
    linkedAcctAccess: LinkedAcctAccess.ALL,
    linkedAcctRole: LinkedAcctRole.READWRITE,
    authQuizStatus: 'PASSED',
  },
  {
    linkedId: '19614',
    patientMasterId: '123474',
    firstName: 'lastchild',
    middleName: null,
    lastName: 'krawczuk',
    dateOfBirth: '2019-02-02',
    email: 'swiss@yopmail.com',
    gender: 'MALE',
    isMinor: true,
    primaryAccount: null,
    secondaryAccountList: [
      {
        linkedId: '71469',
        patientMasterId: '123475',
        firstName: 'Steve',
        middleName: '',
        lastName: 'krawczuk',
        dateOfBirth: '1977-02-02',
        email: 'swiss@yopmail.com',
        gender: 'MALE',
        isMinor: false,
        primaryAccount: null,
        secondaryAccountList: null,
        linkedAcctToCurrentUserStatus: LinkedAcctStatus.INITIATED,
        currentUserToLinkedAcctStatus: LinkedAcctStatus.ACCEPTED,
        linkedAcctAccess: LinkedAcctAccess.ALL,
        linkedAcctRole: LinkedAcctRole.READONLY,
        authQuizStatus: 'PASSED',
      },
      {
        linkedId: '72613',
        patientMasterId: '123476',
        firstName: 'Rebecca',
        middleName: null,
        lastName: 'Krawczuk',
        dateOfBirth: '2000-02-02',
        email: 'swiss@yopmail.com',
        gender: 'FEMALE',
        isMinor: false,
        primaryAccount: null,
        secondaryAccountList: null,
        linkedAcctToCurrentUserStatus: LinkedAcctStatus.ACCEPTED,
        currentUserToLinkedAcctStatus: LinkedAcctStatus.INITIATED,
        linkedAcctAccess: LinkedAcctAccess.ALL,
        linkedAcctRole: LinkedAcctRole.READONLY,
        authQuizStatus: 'PASSED',
      },
    ],
    linkedAcctToCurrentUserStatus: LinkedAcctStatus.NOT_INITIATED,
    currentUserToLinkedAcctStatus: LinkedAcctStatus.INITIATED,
    linkedAcctAccess: LinkedAcctAccess.ALL,
    linkedAcctRole: LinkedAcctRole.READWRITE,
    authQuizStatus: 'PASSED',
  },
];
