<div class="accordion mb-4">
  <div class="card {{ open ? 'active' : 'inactive' }}">
    <div class="card-header bg-white d-flex flex-row collapsed">
      <button
        class="btn p-0 m-0 flex-fill d-flex align-items-center text-left"
        [attr.aria-expanded]="open"
        id="collapse-header-{{ id }}"
        [attr.aria-controls]="'collapse-body-' + id"
        (click)="toggleOpen()"
      >
        <ui-circle *ngIf="type === 'add'" class="mr-5" [completed]="completed && !open"></ui-circle>

        <span class="h3 my-1 flex-fill">{{ header }}</span>

        <i *ngIf="type === 'simple'" class="material-icons" aria-hidden="true">{{ open ? 'expand_less' : 'expand_more' }}</i>

        <span *ngIf="type === 'add'" class="btn btn-secondary" [class.invisible]="open || completed">{{ headerButton }}</span>
      </button>
    </div>
    <div class="collapse" [class.show]="open" id="collapse-body-{{ id }}" role="region" [attr.aria-labelledby]="'collapse-header-' + id">
      <div class="card-body">
        <div *ngIf="!isProcessing">
          <ng-content></ng-content>
        </div>
        <div *ngIf="isProcessing" class="text-center align-self-center">
          <div class="spinner-border text-gray" role="status"></div>
          <p class="small text-gray">Loading...</p>
        </div>
        <div *ngIf="type === 'add'" class="d-flex justify-content-between mt-md-6">
          <button
            class="btn btn-outline-primary mb-3 mr-6"
            (click)="selectInsuranceNotListed()"
            [hidden]="_isInsuranceNotListedButtonHidden || isMobile"
          >
            {{ insuraceNotListedButton }}
          </button>
          <div class="d-flex justify-content-between align-right">
            <button class="btn btn-outline-secondary-dark mb-3 mr-6 mt-44 d-none d-sm-block" (click)="cancelAndClose()">
              Cancel
            </button>
            <button
              [id]="'saveBtn' + id"
              class="btn btn-primary mb-3 d-none d-sm-block"
              (click)="saveAndCloseCompleted()"
              [disabled]="saveDisabled"
            >
              {{ saveButton }}
            </button>
          </div>
        </div>
        <div *ngIf="type === 'add'" class="row d-block d-sm-none justify-content-between align-right">
          <div>
            <button
              class="btn btn-outline-primary mb-3 mr-6 w-100"
              (click)="selectInsuranceNotListed()"
              [hidden]="_isInsuranceNotListedButtonHidden"
            >
              {{ insuraceNotListedButton }}
            </button>
          </div>
          <div>
            <button [id]="'saveBtn' + id" class="btn btn-primary mb-3 w-100" (click)="saveAndCloseCompleted()" [disabled]="saveDisabled">
              {{ saveButton }}
            </button>
          </div>
          <div>
            <button class="btn btn-outline-secondary-dark my-2 mr-3 w-100" (click)="cancelAndClose()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="type === 'add'"
      class="card-body bg-white"
      [ngClass]="{
        'flex-column': alertTemplate || processing,
        'flex-row': !alertTemplate || !processing
      }"
      [class.d-none]="!completed || open"
      [class.d-flex]="completed && !open"
    >
      <ng-container *ngTemplateOutlet="alertTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="processing"></ng-container>
      <div *ngIf="alertTemplate && !processing" class="d-flex align-items-end flex-column w-100">
        <button [id]="'editBtn' + id" class="mb-sm-n5 btn btn-link p-0" style="z-index: 2000;" (click)="toggleOpen()">
          Edit
        </button>
      </div>
      <div [hidden]="processing" class="lc-dt ml-7 flex-fill">
        <ng-content select=".completed-body"> </ng-content>
      </div>
      <div *ngIf="!alertTemplate && !processing">
        <button [id]="'editBtn' + id" class="btn btn-link p-0" (click)="toggleOpen()">
          Edit
        </button>
      </div>
    </div>
  </div>
</div>
