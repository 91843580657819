<div
  ngbDropdown
  [placement]="placement"
  [autoClose]="autoClose"
  class="d-inline-block"
  [class.expand-sm]="expandSize === 'sm'"
  [class.expand-md]="expandSize === 'md'"
  [class.expand-lg]="expandSize === 'lg'"
  [class.expand-xl]="expandSize === 'xl'"
>
  <button
    [ngClass]="btnClass"
    [class.d-sm-none]="expandSize === 'sm'"
    [class.d-md-none]="expandSize === 'md'"
    [class.d-lg-none]="expandSize === 'lg'"
    [class.d-xl-none]="expandSize === 'xl'"
    ngbDropdownToggle
  >
    {{ btnText }}
    <i class="material-icons open-icon">{{ iconOpen }}</i>
    <i class="material-icons close-icon">{{ iconClose }}</i>
  </button>
  <div [ngClass]="divClass" ngbDropdownMenu>
    <ng-content></ng-content>
  </div>
</div>
