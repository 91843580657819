import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MFASharedService {
  private isPasswordConfirmationRequired: BehaviorSubject<boolean>;

  constructor() {
    this.isPasswordConfirmationRequired = new BehaviorSubject<boolean>(true);
  }

  getPasswordConfirmation(): Observable<boolean> {
    return this.isPasswordConfirmationRequired.asObservable();
  }

  setPasswordConfirmation(passwordConfirmationStatus: boolean): void {
    this.isPasswordConfirmationRequired.next(passwordConfirmationStatus);
  }

  resetDefaults() {
    this.setPasswordConfirmation(true);
  }
}
