import { Address } from './address.model';

export type PaymentAuthorizationRequest = {
  accountId: string;
  paymentAccountId: string;
  paymentMethod: PaymentType;
  paymentDate: string; // YYYY-MM-DD
  firstName: string;
  lastName: string;
  address?: Address;
  postalCode?: string;
  invoiceList: PaymentInvoice[];
  emailAddress?: string;
  isGuestUser: boolean;
  browser: string;
  mobile: boolean;
};

export type PaymentAuthorizationResponse = {
  successful: boolean;
  encryptedLpid: string | null;
  encryptedTransactionId: string;
  transactionId: string;
  reasonCode: string;
  optedIn: boolean;
  requestPaymentDue?: string;
};
export type PaymentAuthorizationReceipt = {
  isSuccessful: boolean;
  transactionId: string;
};

export enum PaymentType {
  CREDIT = 'card',
  ECHECK = 'echeck',
  PAYPAL = 'PAL',
  GOOGLE = 'GPY',
  APPLE = 'APY',
}

export type CardType = 'MC' | 'DI' | 'AX' | 'VI' | 'EC' | 'OTHER';

export type PaymentInvoice = {
  invoiceNumber: string;
  paymentAmount: number;
  paymentDue: number;
  specimenNumber?: string;
  dunningCode?: string;
  patientFirstName?: string;
  dateOfService?: string;
  patientDateOfBirth?: string;
  patientLastName?: string;
  invoiceBalance?: string;
  address?: {
    zipCode: string;
  };
  orderingPhyAccountNumber?: string;
};

export type PaymentReceiptResponse = {
  reasonCode: number;
  reasonDescription: string;
  successful: boolean;
};
