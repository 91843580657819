<div class="row py-2 no-gutters bg-white rounded align-items-center">
  <button
    class="col-2 col-sm-auto btn text-center"
    type="button"
    [class.invisible]="!appointments.length || !showPrev"
    [disabled]="!appointments.length || !showPrev"
    (click)="prev()"
  >
    <!-- TODO: Should be "arrow_back_ios" but requires Material Icons update -->
    <i class="material-icons">chevron_left</i>
  </button>
  <div class="appointments col d-flex flex-row flex-wrap justify-content-around" (scroll)="updateButtonVisibility()" #appointmentsArea>
    <ng-container *ngIf="appointments.length; then thenTemplate; else elseTemplate"></ng-container>
    <ng-template #thenTemplate>
      <a class="btn btn-light btn-sm m-1 px-0" *ngFor="let appt of appointments" (click)="appointmentSelected.emit(appt)">{{ appt }}</a>
    </ng-template>
    <ng-template #elseTemplate>
      <p class="small w-100 mt-2 text-left">{{ reasonForNoAppointments }}</p>
    </ng-template>

    <!-- Without this spacer, the bottom padding for the last row of appointments would be ignored -->
    <hr class="w-100 invisible" />
  </div>
  <button
    class="col-2 col-sm-auto btn text-center"
    type="button"
    [class.invisible]="!appointments.length || !showNext"
    [disabled]="!appointments.length || !showNext"
    (click)="next()"
  >
    <!-- TODO: Should be "arrow_forward_ios" but requires Material Icons update -->
    <i class="material-icons">chevron_right</i>
  </button>
</div>
