import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IPhoneValidationResponse } from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  deletePatientAccount(): Observable<void> {
    return this.http.delete<void>(
      `${this.envService.baseUrl}/protected/patients/current`
    );
  }

  validatePhone(phoneNumber: string): Observable<IPhoneValidationResponse> {
    return this.http.get<IPhoneValidationResponse>(
      `${this.envService.baseUrl}/guest/phoneNumber?phoneNumber=${phoneNumber}`
    );
  }
}
