import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { EnvironmentService } from '../environment';
import { AuthConfig } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AuthConfigService {
  private _authConfigSource = new BehaviorSubject<AuthConfig>({} as AuthConfig);
  public authConfig$ = this._authConfigSource.asObservable();

  constructor(private envService: EnvironmentService) {}

  setupAuthConfig() {
    return new Promise<void>((resolve) => {
      this._authConfigSource.next({
        issuer: this.envService.oktaIssuer,
        clientId: this.envService.oktaClientId,
      });
      resolve();
    });
  }
}
