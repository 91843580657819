export type Address = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  zipCode: string;
  primary?: boolean;
  valid?: boolean;
  id?: number;
  type?: string;
  isPrimary?: boolean;
  isValid?: boolean;
  lastUpdated?: string;
  usePrimaryAddressOfPrimaryPatient?: boolean;
};

export enum addressType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  NONE = 'none',
}
