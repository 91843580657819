import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  ActivePaymentsResponse,
  IAddress,
  IAppointment,
  IDependentRegistration,
  INotificationPreference,
  IPatient,
  IPatientDemographicsUpdate,
  IPhone,
  IRequestError,
  NotificationPreference,
  Patient,
  PaymentMethod,
  PortalUser,
} from '@patient-ui/shared/models';

import * as patientActions from './patient.actions';

export const patientFeatureKey = 'patient';

export interface CreateDependentState {
  requestStatus: RequestStatus;
  requestResponse?: HttpResponse<unknown> | HttpErrorResponse;
  formRegistrationData?: IDependentRegistration;
}

export interface PatientAppointmentsState {
  appointments: IAppointment[];
  requestStatus: RequestStatus;
  appointmentsLoading: boolean;
  encryptedLpid: string;
  selectedPatientDropdownUser: PortalUser;
  error: unknown;
}

export type PatientState = {
  primaryAccountHolder: Patient;
  dependentsList?: Patient[];
  addPaymentMethodFlag: boolean;
  addPaymentMethodStatus: RequestStatus;
  deletePaymentMethodStatus: RequestStatus;
  getPatientRequestStatus: RequestStatus;
  loadLoggedInPatientRequestStatus: RequestStatus;
  patientUserList: PortalUser[];
  loadPatientUserListRequestStatus: RequestStatus;
  patientWallet: PaymentMethod[];
  getPatientWalletRequestStatus: RequestStatus;
  openInvoiceSelectedUser?: PortalUser;
  paymentHistorySelectedUser?: PortalUser;
  updatePasswordRequestStatus: RequestStatus;
  updatePasswordRequestError: IRequestError;
  createOrUpdateAddress: IAddress | null;
  createOrUpdateAddressAction: string;
  createOrUpdateAddressOwner: IPatient | null;
  createOrUpdateAddressSwitchToPrimary: boolean;
  createOrUpdateAddressRequestStatus: RequestStatus;
  createOrUpdateAddressRequestResponse: string;
  updatePatientDemographicsOwner: IPatient | null;
  updatePatientDemographicsData: IPatientDemographicsUpdate | null;
  updatePatientDemographicsRequestStatus: RequestStatus;
  updatePatientDemographicsRequestError: IRequestError;
  updatePatientNotificationsOwner: IPatient | null;
  updatePatientNotificationsData: INotificationPreference[] | null;
  updatePatientNotificationsEmail: string | null;
  updatePatientNotificationsRequestResponse: NotificationPreference | null;
  updatePatientNotificationsRequestStatus: RequestStatus;
  updatePatientNotificationsRequestError: IRequestError | null;
  createOrUpdatePhone: IPhone | null;
  createOrUpdatePhoneAction: string;
  createOrUpdatePhoneOwner: IPatient | null;
  createOrUpdatePhoneSwitchToPrimary: boolean;
  createOrUpdatePhoneRequestStatus: RequestStatus;
  createOrUpdatePhoneRequestResponse: string;
  isMobile: boolean;
  deleteDependentRequestStatus?: RequestStatus;
  getActivePaymentStatus?: RequestStatus;
  deleteDependentIsLoading: boolean;
  createDependentState?: CreateDependentState;
  orderHistorySelectedUser?: PortalUser;
  patientAppointmentsState: PatientAppointmentsState;
  activePaymentsList?: ActivePaymentsResponse[];
};

export const initialState: PatientState = {
  primaryAccountHolder: {
    id: 0,
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    middleName: '',
    dependents: [],
    isDependent: false,
    authenticationQuizStatus: '',
    hasFailedMaxVerificationAttempts: false,
    addresses: [],
    emailAddresses: [],
    gender: '',
    hashId: '',
    phones: [],
    usePrimaryPhoneOfPrimaryPatient: false,
    usePrimaryAddressOfPrimaryPatient: false,
    clinicalTrialsPreferenceStatus: '',
    profileVerificationDate: '',
    usePrimaryEmailOfPrimaryPatient: false,
    relationshipType: '',
    genderDisplay: '',
    authenticationQuizPassed: false,
    primaryAddress: {
      line1: '',
      city: '',
      state: '',
      zipCode: '',
    },
    orderedAddresses: [],
    primaryEmailAddress: {
      address: '',
      isPrimary: true,
      isNotificationEnabled: false,
    },
    orderedEmailAddresses: [],
    primaryPhone: {
      number: '',
      type: '',
    },
    orderedPhones: [],
    avatarUrl: '',
    fullName: '',
    isDemographicsUpdateDue: false,
    formattedDob: '',
    isMinor: false,
    emailVerified: false,
  },
  dependentsList: [],
  addPaymentMethodFlag: false,
  addPaymentMethodStatus: RequestStatus.NotSent,
  deletePaymentMethodStatus: RequestStatus.NotSent,
  getPatientRequestStatus: RequestStatus.NotSent,
  loadLoggedInPatientRequestStatus: RequestStatus.NotSent,
  patientUserList: [],
  loadPatientUserListRequestStatus: RequestStatus.NotSent,
  patientWallet: [],
  getPatientWalletRequestStatus: RequestStatus.NotSent,
  updatePasswordRequestStatus: RequestStatus.NotSent,
  updatePasswordRequestError: {
    code: '',
    description: '',
  },
  createOrUpdateAddress: null,
  createOrUpdateAddressAction: 'none',
  createOrUpdateAddressOwner: null,
  createOrUpdateAddressSwitchToPrimary: false,
  createOrUpdateAddressRequestStatus: RequestStatus.NotSent,
  createOrUpdateAddressRequestResponse: '',
  updatePatientDemographicsOwner: null,
  updatePatientDemographicsData: null,
  updatePatientDemographicsRequestStatus: RequestStatus.NotSent,
  updatePatientDemographicsRequestError: {
    code: '',
    description: '',
  },
  updatePatientNotificationsOwner: null,
  updatePatientNotificationsData: null,
  updatePatientNotificationsEmail: null,
  updatePatientNotificationsRequestResponse: null,
  updatePatientNotificationsRequestStatus: RequestStatus.NotSent,
  updatePatientNotificationsRequestError: null,
  createOrUpdatePhone: null,
  createOrUpdatePhoneAction: 'none',
  createOrUpdatePhoneOwner: null,
  createOrUpdatePhoneSwitchToPrimary: false,
  createOrUpdatePhoneRequestStatus: RequestStatus.NotSent,
  createOrUpdatePhoneRequestResponse: '',
  isMobile: false,
  deleteDependentRequestStatus: RequestStatus.NotSent,
  deleteDependentIsLoading: false,
  getActivePaymentStatus: RequestStatus.NotSent,
  patientAppointmentsState: {
    appointments: [],
    requestStatus: RequestStatus.NotSent,
    appointmentsLoading: false,
    encryptedLpid: '',
    selectedPatientDropdownUser: {
      displayName: '',
      id: 0,
    },
    error: null,
  },
  activePaymentsList: [],
};

export const patientReducer = createReducer(
  initialState,
  on(patientActions.addPaymentMethod, (state) => ({
    ...state,
    addPaymentMethodStatus: RequestStatus.Pending,
  })),
  on(patientActions.addPaymentMethodFailure, (state) => ({
    ...state,
    addPaymentMethodStatus: RequestStatus.Failure,
  })),
  on(patientActions.addPaymentMethodFlag, (state, { payload }) => ({
    ...state,
    addPaymentMethodFlag: payload,
  })),
  on(patientActions.addPaymentMethodSuccess, (state) => ({
    ...state,
    addeletePaymentMethodStatus: RequestStatus.Success,
    addPaymentMethodFlag: false,
  })),
  on(patientActions.clearDeletePaymentMethodError, (state) => ({
    ...state,
    deletePaymentMethodStatus: RequestStatus.NotSent,
  })),
  on(patientActions.clearPatientWallet, (state) => ({
    ...state,
    patientWallet: [],
    getPatientWalletRequestStatus: RequestStatus.NotSent,
  })),
  on(patientActions.deletePaymentMethod, (state) => ({
    ...state,
    deletePaymentMethodStatus: RequestStatus.Pending,
  })),
  on(patientActions.deletePaymentMethodFailure, (state) => ({
    ...state,
    deletePaymentMethodStatus: RequestStatus.Failure,
  })),
  on(patientActions.deletePaymentMethodSuccess, (state, { payload }) => ({
    ...state,
    deletePaymentMethodStatus: RequestStatus.Success,
    patientWallet: state.patientWallet.filter(
      (method) => method.cardId !== payload.cardId
    ),
  })),
  on(patientActions.getPatientWallet, (state) => ({
    ...state,
    getPatientWalletRequestStatus: RequestStatus.Pending,
  })),
  on(patientActions.getPatientWalletFailure, (state) => ({
    ...state,
    getPatientWalletRequestStatus: RequestStatus.Failure,
  })),
  on(patientActions.getPatientWalletSuccess, (state, { payload }) => ({
    ...state,
    getPatientWalletRequestStatus: RequestStatus.Success,
    patientWallet: payload,
  })),
  on(patientActions.loadLoggedInPatient, (state, { payload }) => ({
    ...state,
    primaryAccountHolder: payload.primaryPatient,
    dependentsList: payload.dependentsList,
    patientUserList: payload.patientUserList,
    loadLoggedInPatientRequestStatus: RequestStatus.Success,
  })),
  on(patientActions.loadLoggedInPatientSuccess, (state, { payload }) => ({
    ...state,
    primaryAccountHolder: payload.primaryPatient,
    dependentsList: payload.dependentsList,
    patientUserList: payload.patientUserList,
    loadLoggedInPatientRequestStatus: RequestStatus.Success,
  })),
  on(patientActions.loadLoggedInPatientFailure, (state) => ({
    ...state,
    loadLoggedInPatientRequestStatus: RequestStatus.Failure,
  })),
  on(patientActions.loadPatientUserList, (state, { userList }) => ({
    ...state,
    patientUserList: userList,
    loadPatientUserListRequestStatus: RequestStatus.Success,
  })),
  on(patientActions.loadPatientUserListFailure, (state) => ({
    ...state,
    loadPatientUserListRequestStatus: RequestStatus.Failure,
  })),
  on(patientActions.clearLoggedInPatient, (state) => ({
    ...state,
    loadLoggedInPatientRequestStatus:
      initialState.loadLoggedInPatientRequestStatus,
    patientUserList: initialState.patientUserList,
    loadPatientUserListRequestStatus:
      initialState.loadPatientUserListRequestStatus,
  })),
  on(patientActions.loadPatientAppointments, (state) => ({
    ...state,
    patientAppointmentsState: {
      ...state.patientAppointmentsState,
      requestStatus: RequestStatus.Pending,
      appointmentsLoading: true,
    },
  })),
  on(
    patientActions.loadPatientAppointmentsSuccess,
    (state, { appointments }) => ({
      ...state,
      patientAppointmentsState: {
        ...state.patientAppointmentsState,
        appointments,
        requestStatus: RequestStatus.Success,
        appointmentsLoading: false,
      },
    })
  ),
  on(patientActions.loadPatientAppointmentsFailure, (state, { error }) => ({
    ...state,
    patientAppointmentsState: {
      ...state.patientAppointmentsState,
      error,
      requestStatus: RequestStatus.Failure,
      appointmentsLoading: false,
    },
  })),
  on(patientActions.getEncryptedLpid, (state) => ({
    ...state,
    patientAppointmentsState: {
      ...state.patientAppointmentsState,
      requestStatus: RequestStatus.Pending,
    },
  })),
  on(patientActions.getEncryptedLpidSuccess, (state, { encryptedLpid }) => ({
    ...state,
    patientAppointmentsState: {
      ...state.patientAppointmentsState,
      encryptedLpid,
      requestStatus: RequestStatus.Success,
    },
  })),
  on(patientActions.getEncryptedLpidFailure, (state, { error }) => ({
    ...state,
    patientAppointmentsState: {
      ...state.patientAppointmentsState,
      error,
      requestStatus: RequestStatus.Failure,
    },
  })),
  on(
    patientActions.selectPatientAppointmentsDropdownUser,
    (state, { user }) => ({
      ...state,
      patientAppointmentsState: {
        ...state.patientAppointmentsState,
        selectedPatientDropdownUser: user,
      },
    })
  ),
  on(patientActions.resetPatientAppointmentsState, (state) => ({
    ...state,
    patientAppointmentsState: initialState.patientAppointmentsState,
  })),
  on(patientActions.updatePassword, (state) => ({
    ...state,
    updatePasswordRequestStatus: RequestStatus.Pending,
    updatePasswordRequestError: {
      code: '',
      description: '',
    },
  })),
  on(patientActions.selectOpenInvoiceUser, (state, { payload }) => ({
    ...state,
    openInvoiceSelectedUser: payload,
  })),
  on(patientActions.selectPaymentHistoryUser, (state, { payload }) => ({
    ...state,
    paymentHistorySelectedUser: payload,
  })),
  on(patientActions.selectSelfOrdersUser, (state, { payload }) => ({
    ...state,
    orderHistorySelectedUser: payload,
  })),
  on(patientActions.updatePasswordSuccess, (state) => ({
    ...state,
    updatePasswordRequestStatus: RequestStatus.Success,
    updatePasswordRequestError: {
      code: '',
      description: '',
    },
  })),
  on(patientActions.updatePasswordFailure, (state, { errorResponse }) => ({
    ...state,
    updatePasswordRequestStatus: RequestStatus.Failure,
    updatePasswordRequestError: {
      code: 'password.error',
      description: errorResponse.error.description,
    },
  })),
  on(patientActions.clearUpdatePassword, (state) => ({
    ...state,
    updatePasswordRequestStatus: RequestStatus.NotSent,
    updatePasswordRequestError: {
      code: '',
      description: '',
    },
  })),
  on(patientActions.createDependentSucess, (state, { dependent }) => {
    const dependentsList = state.dependentsList
      ? [...state.dependentsList, dependent]
      : state.dependentsList;
    const newDependentUser: PortalUser = {
      id: dependent.id ? dependent.id : 999,
      displayName: dependent.fullName,
      isPrimary: 'dependent',
      quizStatus: dependent.authenticationQuizStatus,
    };
    const newPatientUserList = [...state.patientUserList];
    if (newPatientUserList.length === 1) {
      newPatientUserList.push(newDependentUser);
    } else {
      // add new dependent to second to last index of newPatientUserList
      newPatientUserList.splice(
        newPatientUserList.length - 1,
        0,
        newDependentUser
      );
    }
    if (
      newPatientUserList.length > 1 &&
      !newPatientUserList
        .map((patient) => patient.displayName)
        .includes('All Patients')
    ) {
      newPatientUserList.push({ displayName: 'All Patients', id: 0 });
    }
    return {
      ...state,
      dependentsList,
      patientUserList: newPatientUserList,
      createDependentState: {
        requestStatus: RequestStatus.Success,
      },
    };
  }),
  on(
    patientActions.createDependentFailure,
    (state, { errorResponse, dependentRegistration }) => ({
      ...state,
      createDependentState: {
        requestStatus: RequestStatus.Failure,
        requestResponse: errorResponse,
        formRegistrationData: dependentRegistration,
      },
    })
  ),
  on(patientActions.clearCreateDependentState, (state) => ({
    ...state,
    createDependentState: undefined,
  })),
  on(patientActions.deleteDependent, (state) => ({
    ...state,
    deleteDependentIsLoading: true,
  })),
  on(patientActions.deleteDependentSuccess, (state, { depId }) => {
    let newPatientUserList = state.patientUserList?.filter(
      (e) => e.id !== Number(depId)
    );
    if (
      newPatientUserList.length === 2 &&
      newPatientUserList
        .map((patient) => patient.displayName)
        .includes('All Patients')
    ) {
      newPatientUserList = newPatientUserList.filter(
        (patient) => patient.displayName !== 'All Patients'
      );
    }
    return {
      ...state,
      deleteDependentRequestStatus: RequestStatus.Success,
      deleteDependentIsLoading: false,
      dependentsList: state.dependentsList?.filter(
        (e) => e.id !== Number(depId)
      ),
      patientUserList: newPatientUserList,
    };
  }),
  on(patientActions.deleteDependentFailure, (state) => ({
    ...state,
    deleteDependentRequestStatus: RequestStatus.Failure,
    deleteDependentIsLoading: false,
  })),
  on(
    patientActions.updatePatientAfterProfileChange,
    (state, { updatedPatient }) => {
      let updatedDependents: Patient[] = [];
      if (state?.primaryAccountHolder?.id !== updatedPatient.id) {
        const index = state.dependentsList?.findIndex(
          (dep) => dep.id === updatedPatient.id
        );
        if (index !== undefined) {
          const currentDependents = state.dependentsList
            ? [...state.dependentsList]
            : [];
          updatedDependents = [
            ...currentDependents.slice(0, index),
            updatedPatient,
            ...currentDependents.slice(index + 1),
          ];
        }
      } else {
        updatedDependents = state.dependentsList as IPatient[];
      }
      return {
        ...state,
        primaryAccountHolder:
          state?.primaryAccountHolder?.id === updatedPatient.id
            ? updatedPatient
            : state.primaryAccountHolder,
        dependentsList: updatedDependents,
        createOrUpdateAddress: null,
        createOrUpdateAddressAction: 'none',
        createOrUpdateAddressRequestStatus: RequestStatus.NotSent,
        createOrUpdateAddressOwner: null,
        createOrUpdateAddressSwitchToPrimary: false,
        updatePatientDemographicsOwner: null,
        updatePatientDemographicsData: null,
        updatePatientDemographicsRequestStatus: RequestStatus.NotSent,
        updatePatientNotificationsOwner: null,
        updatePatientNotificationsData: null,
        updatePatientNotificationsEmail: null,
        updatePatientNotificationsRequestResponse: null,
        updatePatientNotificationsRequestStatus: RequestStatus.NotSent,
        updatePatientNotificationsRequestError: null,
        createOrUpdatePhone: null,
        createOrUpdatePhoneAction: 'none',
        createOrUpdatePhoneRequestStatus: RequestStatus.NotSent,
        createOrUpdatePhoneOwner: null,
        createOrUpdatePhoneSwitchToPrimary: false,
      };
    }
  ),
  on(patientActions.updateAuthQuizStatus, (state, { updatedPatient }) => {
    let updatedDependents: Patient[] = [];
    if (state?.primaryAccountHolder?.id !== updatedPatient.id) {
      const index = state.dependentsList?.findIndex(
        (dep) => dep.id === updatedPatient.id
      );
      if (index !== undefined) {
        const currentDependents = state.dependentsList
          ? [...state.dependentsList]
          : [];
        updatedDependents = [
          ...currentDependents.slice(0, index),
          updatedPatient,
          ...currentDependents.slice(index + 1),
        ];
      }
    } else {
      if (state.dependentsList && state.dependentsList.length > 0) {
        let updatedDependent = new Patient();
        state.dependentsList.forEach((dependent) => {
          if (dependent.isMinor) {
            updatedDependent = Object.assign(updatedDependent, dependent, {
              authenticationQuizStatus: updatedPatient.authenticationQuizStatus,
            });
            updatedDependents.push(updatedDependent);
          } else {
            updatedDependents.push(dependent);
          }
        });
      }
    }
    return {
      ...state,
      primaryAccountHolder:
        state?.primaryAccountHolder?.id === updatedPatient.id
          ? updatedPatient
          : state.primaryAccountHolder,
      dependentsList: updatedDependents,
    };
  }),
  on(
    patientActions.createOrUpdateAddress,
    (
      state,
      { addressOwner, addressToUpdate, addressAction, switchToPrimary }
    ) => ({
      ...state,
      createOrUpdateAddress: addressToUpdate,
      createOrUpdateAddressAction: addressAction,
      createOrUpdateAddressRequestStatus: RequestStatus.Pending,
      createOrUpdateAddressOwner: addressOwner,
      createOrUpdateAddressSwitchToPrimary: switchToPrimary || false,
    })
  ),
  on(
    patientActions.createOrUpdateOrDeleteAddressSuccess,
    (state, { response }) => ({
      ...state,
      createOrUpdateAddressRequestStatus: RequestStatus.Success,
      createOrUpdateAddressRequestResponse: response?.body,
    })
  ),
  on(patientActions.createOrUpdateOrDeleteAddressFailure, (state) => ({
    ...state,
    createOrUpdateAddress: null,
    createOrUpdateAddressAction: 'none',
    createOrUpdateAddressRequestStatus: RequestStatus.Failure,
    createOrUpdateAddressOwner: null,
    createOrUpdateAddressSwitchToPrimary: false,
  })),
  on(
    patientActions.deleteAddress,
    (state, { addressOwner, addressToDelete, addressAction }) => ({
      ...state,
      createOrUpdateAddress: addressToDelete,
      createOrUpdateAddressAction: addressAction,
      createOrUpdateAddressRequestStatus: RequestStatus.Pending,
      createOrUpdateAddressOwner: addressOwner,
    })
  ),
  on(patientActions.resetAddressChanges, (state) => ({
    ...state,
    createOrUpdateAddress: null,
    createOrUpdateAddressAction: 'none',
    createOrUpdateAddressRequestStatus: RequestStatus.NotSent,
    createOrUpdateAddressOwner: null,
    createOrUpdateAddressSwitchToPrimary: false,
  })),
  on(
    patientActions.updatePatientDemographics,
    (state, { patientDemographicsOwner, patientDemographics }) => ({
      ...state,
      updatePatientDemographicsOwner: patientDemographicsOwner,
      updatePatientDemographicsData: patientDemographics,
      updatePatientDemographicsRequestStatus: RequestStatus.Pending,
    })
  ),
  on(patientActions.updatePatientDemographicsSuccess, (state) => ({
    ...state,
    updatePatientDemographicsRequestStatus: RequestStatus.Success,
  })),
  on(patientActions.updatePatientDemographicsFailure, (state) => ({
    ...state,
    updatePatientDemographicsOwner: null,
    updatePatientDemographicsData: null,
    updatePatientDemographicsRequestStatus: RequestStatus.Failure,
  })),
  on(
    patientActions.updatePatientNotifications,
    (state, { patientNotificationsOwner, patientNotifications }) => ({
      ...state,
      updatePatientNotificationsOwner: patientNotificationsOwner,
      updatePatientNotificationsData:
        patientNotifications.notificationPreferences,
      updatePatientNotificationsEmail: patientNotifications.primaryEmail,
      updatePatientNotificationsRequestStatus: RequestStatus.Pending,
      updatePatientNotificationsRequestResponse: null,
      updatePatientNotificationsRequestError: null,
    })
  ),
  on(
    patientActions.updatePatientNotificationsSuccess,
    (state, { response }) => ({
      ...state,
      updatePatientNotificationsRequestStatus: RequestStatus.Success,
      updatePatientNotificationsRequestResponse: response,
    })
  ),
  on(
    patientActions.updatePatientNotificationsFailure,
    (state, { errorResponse }) => ({
      ...state,
      updatePatientNotificationsOwner: null,
      updatePatientNotificationsData: null,
      updatePatientNotificationsEmail: null,
      updatePatientNotificationsRequestResponse: null,
      updatePatientNotificationsRequestStatus: RequestStatus.Failure,
      updatePatientNotificationsRequestError: errorResponse,
    })
  ),
  on(
    patientActions.setClinicalTrialsPreferenceSuccess,
    (state, { payload }) => ({
      ...state,
      primaryAccountHolder: {
        ...state.primaryAccountHolder,
        id: state?.primaryAccountHolder?.id,
        dateOfBirth: state?.primaryAccountHolder?.dateOfBirth,
        firstName: state?.primaryAccountHolder?.firstName,
        lastName: state?.primaryAccountHolder?.lastName,
        middleName: state.primaryAccountHolder.middleName,
        dependents: state.primaryAccountHolder.dependents,
        isDependent: state.primaryAccountHolder.isDependent,
        authenticationQuizStatus:
          state.primaryAccountHolder.authenticationQuizStatus,
        hasFailedMaxVerificationAttempts:
          state.primaryAccountHolder.hasFailedMaxVerificationAttempts,
        addresses: state.primaryAccountHolder.addresses,
        emailAddresses: state.primaryAccountHolder.emailAddresses,
        gender: state.primaryAccountHolder.gender,
        hashId: state.primaryAccountHolder.hashId,
        phones: state.primaryAccountHolder.phones,
        usePrimaryPhoneOfPrimaryPatient:
          state.primaryAccountHolder.usePrimaryPhoneOfPrimaryPatient,
        usePrimaryAddressOfPrimaryPatient:
          state.primaryAccountHolder.usePrimaryAddressOfPrimaryPatient,
        profileVerificationDate:
          state.primaryAccountHolder.profileVerificationDate,
        usePrimaryEmailOfPrimaryPatient:
          state.primaryAccountHolder.usePrimaryEmailOfPrimaryPatient,
        relationshipType: state.primaryAccountHolder.relationshipType,
        genderDisplay: state.primaryAccountHolder.genderDisplay,
        authenticationQuizPassed:
          state.primaryAccountHolder.authenticationQuizPassed,
        primaryAddress: state.primaryAccountHolder.primaryAddress,
        orderedAddresses: state.primaryAccountHolder.orderedAddresses,
        primaryEmailAddress: state.primaryAccountHolder.primaryEmailAddress,
        orderedEmailAddresses: state.primaryAccountHolder.orderedEmailAddresses,
        primaryPhone: state.primaryAccountHolder.primaryPhone,
        orderedPhones: state.primaryAccountHolder.orderedPhones,
        avatarUrl: state.primaryAccountHolder.avatarUrl,
        fullName: state.primaryAccountHolder.fullName,
        isDemographicsUpdateDue:
          state.primaryAccountHolder.isDemographicsUpdateDue,
        formattedDob: state.primaryAccountHolder.formattedDob,
        isMinor: state.primaryAccountHolder.isMinor,
        clinicalTrialsPreferenceStatus: payload,
        emailVerified: state.primaryAccountHolder.emailVerified,
      },
    })
  ),
  on(
    patientActions.createOrUpdatePhone,
    (state, { phoneOwner, phoneToUpdate, phoneAction, switchToPrimary }) => ({
      ...state,
      createOrUpdatePhone: phoneToUpdate,
      createOrUpdatePhoneAction: phoneAction,
      createOrUpdatePhoneRequestStatus: RequestStatus.Pending,
      createOrUpdatePhoneOwner: phoneOwner,
      createOrUpdatePhoneSwitchToPrimary: switchToPrimary || false,
    })
  ),
  on(
    patientActions.createOrUpdateOrDeletePhoneSuccess,
    (state, { response }) => ({
      ...state,
      createOrUpdatePhoneRequestStatus: RequestStatus.Success,
      createOrUpdatePhoneRequestResponse: response?.body,
    })
  ),
  on(patientActions.createOrUpdateOrDeletePhoneFailure, (state) => ({
    ...state,
    createOrUpdatePhone: null,
    createOrUpdatePhoneAction: 'none',
    createOrUpdatePhoneRequestStatus: RequestStatus.Failure,
    createOrUpdatePhoneOwner: null,
    createOrUpdatePhoneSwitchToPrimary: false,
  })),
  on(patientActions.resetPhoneChanges, (state) => ({
    ...state,
    createOrUpdatePhone: null,
    createOrUpdatePhoneAction: 'none',
    createOrUpdatePhoneRequestStatus: RequestStatus.NotSent,
    createOrUpdatePhoneOwner: null,
    createOrUpdatePhoneSwitchToPrimary: false,
  })),
  on(
    patientActions.deletePhone,
    (state, { phoneOwner, phoneToDelete, phoneAction }) => ({
      ...state,
      createOrUpdatePhone: phoneToDelete,
      createOrUpdatePhoneAction: phoneAction,
      createOrUpdatePhoneRequestStatus: RequestStatus.Pending,
      createOrUpdatePhoneOwner: phoneOwner,
    })
  ),
  on(patientActions.updatePatientUserList, (state, { payload }) => ({
    ...state,
    patientUserList: payload,
  })),
  on(patientActions.setPatientPAHEmailVerified, (state) =>
    Object.assign({}, state, {
      primaryAccountHolder: Object.assign({}, state.primaryAccountHolder, {
        emailVerified: true,
      }),
    })
  ),
  on(patientActions.resetPatientState, (_state) => ({
    ...initialState,
  })),
  on(patientActions.populateMobile, (state, { payload }) => ({
    ...state,
    isMobile: payload,
  })),
  on(patientActions.retrieveActivePayments, (state) => ({
    ...state,
  })),
  on(
    patientActions.retrieveActivePaymentsSuccess,
    (state, { activePayments }) => ({
      ...state,
      activePaymentsList: activePayments,
      getActivePaymentStatus: RequestStatus.Success,
    })
  ),
  on(patientActions.retrieveActivePaymentsFailure, (state) => ({
    ...state,
    getActivePaymentStatus: RequestStatus.Failure,
  }))
);
