import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  template: `
    <div class="d-flex h-100 justify-content-center">
      <div class="text-center align-self-center">
        <div class="spinner-border text-gray" role="status"></div>
        <p class="small text-gray">Loading...</p>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingComponent {
  constructor() {}
}
