import { createReducer, on } from '@ngrx/store';

import { IPatient } from '@patient-ui/shared/models';

import { PendoActions } from './pendo.actions';

export const pendoFeatureKey = 'pendo';

export type PendoState = {
  initializeSuccess: boolean;
  registeredPatient: IPatient | undefined;
  version: string;
};

export const pendoInitialState: PendoState = {
  initializeSuccess: false,
  registeredPatient: undefined,
  version: ``,
};

export const pendoReducer = createReducer(
  pendoInitialState,
  on(PendoActions.pendoInitializeSuccess, (state, { success }) => ({
    ...state,
    initializeSuccess: success,
  })),
  on(PendoActions.pendoSetRegisteredUser, (state, { patient }) => ({
    ...state,
    registeredPatient: patient,
  })),
  on(PendoActions.pendoSetLinkedAccounts, (state, { linkedAccounts }) => {
    if (state.registeredPatient) {
      return {
        ...state,
        registeredPatient: {
          ...state.registeredPatient,
          linkedAccounts: linkedAccounts,
        },
      };
    } else {
      return state;
    }
  }),
  on(PendoActions.pendoSetVersion, (state, { version }) => ({
    ...state,
    version: version,
  }))
);
