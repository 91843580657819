import { FormArray, FormControl, FormGroup } from '@angular/forms';

export function hasAtLeastOneFieldFilled(
  formGroup: FormGroup,
  includeCheckboxes = true
): boolean {
  for (const control of Object.values(formGroup.controls)) {
    const atLeastOneFieldFilled =
      control.dirty && control.value !== null && control.value !== '';
    if (
      (atLeastOneFieldFilled && includeCheckboxes) ||
      (atLeastOneFieldFilled &&
        !includeCheckboxes &&
        typeof control.value !== 'boolean')
    ) {
      return true;
    }
  }
  return false;
}

export function markGroupDirty(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key);
    if (control instanceof FormGroup) {
      markGroupDirty(control as FormGroup);
    } else if (control instanceof FormArray) {
      markArrayDirty(control as FormArray);
    } else if (control instanceof FormControl) {
      markControlDirty(control as FormControl);
    }
  });
}

export function markArrayDirty(formArray: FormArray) {
  Object.keys(formArray.controls).forEach((key) => {
    const control = formArray.get(key);
    if (control instanceof FormGroup) {
      markGroupDirty(control as FormGroup);
    } else if (control instanceof FormArray) {
      markArrayDirty(control as FormArray);
    } else if (control instanceof FormControl) {
      markControlDirty(control as FormControl);
    }
  });
}

export function markControlDirty(formControl: FormControl) {
  formControl.markAsDirty();
}
