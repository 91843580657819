import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthState, authFeatureKey } from './auth.reducer';

export const authStateSelector = createFeatureSelector<AuthState>(
  authFeatureKey
);

export const selectAuthState = createSelector(
  authStateSelector,
  (state) => state
);

export const selectAuthenticationStatus = createSelector(
  authStateSelector,
  (state) => state.authenticateRequestStatus
);
