import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(
      navigator.userAgent
    );
    // Clone the request to add the new headers
    const clonedRequest = req.clone({
      setHeaders: {
        'X-Client-Type': isMobile ? 'Mobile-Web' : 'Web',
        'X-Browser-Type': detectBrowser(),
        'X-Referrer': detectReferrer(),
      },
    });

    // Pass the cloned request instead of the original request to the next handle
    // but only if it's a request to patient portal backend
    if (req.url.indexOf('portal-api') !== -1) {
      return next.handle(clonedRequest);
    } else {
      return next.handle(req);
    }
  }
}

function detectBrowser() {
  if (
    (navigator.userAgent.indexOf('Opera') ||
      navigator.userAgent.indexOf('OPR')) !== -1
  ) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'Edge';
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
    return 'IE';
  } else {
    return 'Other';
  }
}
function detectReferrer() {
  if (/[^a-zA-Z]Direct|[^a-zA-Z]Friendly/i.test(window.location.href)) {
    window.sessionStorage.setItem('BadDebt', 'true');
  } else if (window.location.href.indexOf('E2P') !== -1) {
    window.sessionStorage.setItem('Email2Pay', 'true');
  }

  if (window.sessionStorage.getItem('BadDebt')) {
    return 'BadDebt';
  } else if (window.sessionStorage.getItem('Email2Pay')) {
    return 'Email2Pay';
  } else {
    return 'Labcorp';
  }
}
