<header *ngIf="isMobile" class="shadow" id="portal-header-mob">
  <nav class="navbar navbar-expand-lg navbar-dark navbar-link-underline bg-primary">
    <div class="container-xl justify-content-center">
      <img alt="Labcorp | Patient" class="branding" height="30" src="assets/images/logo-patient-white.svg" />
    </div>
  </nav>
</header>
<header *ngIf="!isMobile" class="shadow" id="portal-header-reg">
  <div class="bg-white shadow d-none d-lg-block">
    <div class="container-xl d-flex justify-content-end">
      <div class="btn-group" role="group" aria-label="Basic example">
        <a href="https://www.labcorp.com/trials" class="p-1 mx-3" target="_blank">Learn About Clinical Trials</a>
        <a class="p-1 mx-3" href="{{ onDemandUrl + '/sales/order/history/' }}" target="_blank">View OnDemand Orders</a>
        <a routerLink="ondemand" class="p-1 mx-3">
          <span>Purchase Your Own Lab Test</span>
        </a>
        <a [href]="helpCenterUrl" class="p-1 mx-3" target="_blank">Help Center</a>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-dark navbar-link-underline bg-primary">
    <div class="container-xl justify-content-start">
      <button
        class="navbar-toggler"
        type="button"
        (click)="isMenuCollapsed = !isMenuCollapsed"
        aria-controls="lcGradientNavbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="material-icons icon-lg">menu</i>
      </button>
      <a class="navbar-brand img" (click)="isMenuCollapsed = true" routerLink="/portal/dashboard"
        ><img alt="Labcorp | Patient" class="branding" height="30" src="assets/images/logo-patient-white.svg"
      /></a>
      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" id="lcGradientNavbarContent">
        <ul class="navbar-nav m-auto">
          <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a
              class="nav-link"
              data-label="Dashboard"
              routerLink="{{ navigatedFrom ? 'portal/dashboard' : 'dashboard' }}"
              (click)="isMenuCollapsed = true"
              ><span class="nav-link-label">Dashboard</span></a
            >
          </li>
          <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a
              class="nav-link"
              data-label="Appointments"
              routerLink="{{ navigatedFrom ? 'portal/appointments' : 'appointments' }}"
              (click)="isMenuCollapsed = true"
              ><span class="nav-link-label">Appointments</span></a
            >
          </li>
          <li class="nav-item desktop-nav" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a
              class="nav-link"
              data-label="Orders"
              routerLink="{{ navigatedFrom ? 'portal/orders' : 'orders' }}"
              (click)="isMenuCollapsed = true"
              ><span class="nav-link-label">Orders</span></a
            >
          </li>
          <li class="nav-item mobile-nav">
            <a class="nav-link" data-label="Orders" routerLink="orders" (click)="isMenuCollapsed = true"
              ><span class="nav-link-label">Orders</span></a
            >
          </li>
          <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a
              class="nav-link"
              data-label="Results"
              routerLink="{{ navigatedFrom ? 'portal/results/list' : 'results/list' }}"
              (click)="isMenuCollapsed = true"
              ><span class="nav-link-label">Results</span></a
            >
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a
              class="nav-link"
              data-label="Billing"
              routerLink="{{ navigatedFrom ? 'portal/invoices' : 'invoices' }}"
              (click)="isMenuCollapsed = true"
              ><span class="nav-link-label">Billing</span></a
            >
          </li>
        </ul>
        <ul class="navbar-nav d-block d-lg-none">
          <li class="nav-item desktop-nav">
            <a
              class="nav-link"
              data-label="Learn About Clinical Trials"
              href="https://www.labcorp.com/trials"
              (click)="isMenuCollapsed = true"
              target="_blank"
              ><span class="nav-link-label">Learn About Clinical Trials</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-label="View OnDemand Orders"
              href="{{ onDemandUrl + '/sales/order/history/' }}"
              (click)="isMenuCollapsed = true"
              target="_blank"
              ><span class="nav-link-label">View OnDemand Orders</span></a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-label="Purchase Your Own Lab Test" routerLink="ondemand" (click)="isMenuCollapsed = true"
              ><span class="nav-link-label">Purchase Your Own Lab Test</span></a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-label="Help Center" [href]="helpCenterUrl" (click)="isMenuCollapsed = true" target="_blank"
              ><span class="nav-link-label">Help Center</span></a
            >
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item mr-0 custom-dropdown" ngbDropdown placement="bottom-right">
            <a
              class="nav-link"
              tabindex="0"
              style="outline: none !important;"
              ngbDropdownToggle
              id="userGradientNavbarDropdown"
              role="button"
            >
              <span class="badge badge-username">{{ pahAbbreviation | uppercase }}</span>
              <span class="label-username" [attr.data-label]="pahName | uppercase">
                <span class="nav-link-label text-center">{{ pahName }}</span>
              </span>
              <span class="material-icons ml-0">expand_more</span>
            </a>
            <div ngbDropdownMenu aria-labelledby="userGradientNavbarDropdown" class="dropdown-menu dropdown-menu-right">
              <a
                ngbDropdownItem
                routerLink="{{ navigatedFrom ? 'portal/account/profile' : 'account/profile' }}"
                (click)="isMenuCollapsed = true"
                >Profile</a
              >
              <a
                ngbDropdownItem
                routerLink="{{ navigatedFrom ? 'portal/account/linked-accounts' : 'account/linked-accounts' }}"
                (click)="isMenuCollapsed = true"
                >Linked Accounts</a
              >
              <a ngbDropdownItem (click)="signOut($event)" style="cursor: pointer;">Sign Out</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
