import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromResults from './results.reducer';

export const resultStateSelector = createFeatureSelector<
  fromResults.ResultsState
>(fromResults.resultsFeatureKey);

export const selectResultsPayloadStatus = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.loadResultsPayloadRequestStatus
);

export const selectResultSummary = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.results
);
export const selectResultHeaders = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.resultHeaders
);
export const selectResultHeadersRequestStatus = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.loadResultHeadersStatus
);
export const selectResultDetail = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.resultDetail
);
export const selectResultDetailStatus = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.getResultDetailStatus
);
export const selectResultPdfRequestStatus = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.getResultPdfRequestStatus
);
export const selectResultPdf = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.resultPdf
);
export const selectResultHistorySelectedUser = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.resultHistorySelectedUser
);
export const getError = createSelector(
  resultStateSelector,
  (state: fromResults.ResultsState) => state.error
);
export const dashboardResultsQuery = {
  resultStateSelector,
  selectResultsPayloadStatus,
  selectResultSummary,
  selectResultHeaders,
  selectResultHeadersRequestStatus,
  selectResultDetail,
  selectResultDetailStatus,
  selectResultPdfRequestStatus,
  selectResultPdf,
  getError,
};
