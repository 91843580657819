import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OndemandService {
  private showRedirectURL: BehaviorSubject<string>;
  private showContextValue: BehaviorSubject<string>;

  constructor() {
    this.showRedirectURL = new BehaviorSubject<string>('');
    this.showContextValue = new BehaviorSubject<string>('');
  }

  getRedirectURL(): Observable<string> {
    return this.showRedirectURL.asObservable();
  }

  setRedirectURL(redirectURL: string): void {
    this.showRedirectURL.next(redirectURL);
  }

  getContextValue(): Observable<string> {
    return this.showContextValue.asObservable();
  }

  setContextvalue(context: string): void {
    this.showContextValue.next(context);
  }

  resetDefaults() {
    this.setRedirectURL('');
    this.setContextvalue('');
  }
}
