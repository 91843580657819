import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  BillingNotificationsOptIn,
  IPhoneValidationResponse,
} from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { PhoneOptInResponse } from './phone.actions';

@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  billingNotificationsOptIn(
    optInData: BillingNotificationsOptIn
  ): Observable<PhoneOptInResponse> {
    /*
     * The backend expects mobile consent to be a Y/N string instead of a boolean. Making that switch
     * here so we can keep the convenience of using a boolean for boolean data while still supporting
     * what the backend expects.
     * Keeping
     */
    const optInBody = {
      ...optInData,
      mobileConsent: optInData.mobileConsent ? `Y` : `N`,
    };
    return this.http
      .post<PhoneOptInResponse | null>(
        `${this.envService.baseUrl}/guest/billing/optin`,
        optInBody
      )
      .pipe(
        map((res: PhoneOptInResponse | null) => {
          if (res) {
            return res;
          } else {
            const defaultResponse: PhoneOptInResponse = {
              optedIn: false,
              message: 'successfully ',
            };
            return defaultResponse;
          }
        })
      );
  }

  validatePhone(phoneNumber: string): Observable<IPhoneValidationResponse> {
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
    const validatePhoneUrl = `${this.envService.baseUrl}/guest/phoneNumber?phoneNumber=${formattedPhoneNumber}`;

    return this.http.get<IPhoneValidationResponse>(validatePhoneUrl);
  }

  getPhoneType(type: string): string {
    let phoneType: string;

    switch (type) {
      case 'undetermined':
      case 'voip': {
        phoneType = 'unknown';
        break;
      }
      case 'landline': {
        phoneType = 'home';
        break;
      }
      case 'freephone':
      case 'other': {
        phoneType = 'work';
        break;
      }
      default: {
        phoneType = type;
      }
    }
    return phoneType;
  }

  getPhoneDisplayText(value: string): string {
    let displayText: string;

    switch (value.toLowerCase()) {
      case 'mobile': {
        displayText = 'Mobile';
        break;
      }
      case 'home': {
        displayText = 'LandLine';
        break;
      }
      case 'unknown': {
        displayText = 'Undetermined';
        break;
      }
      case 'work': {
        displayText = 'Other';
        break;
      }
      default: {
        displayText = 'Other';
      }
    }
    return displayText;
  }
}
